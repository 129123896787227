import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const selectUserEmail = (state: RootState) =>
  state.rootReducer.fietsgestolenReducer.userEmail;

export const selectUserEmailLoading = (state: RootState) =>
  state.rootReducer.fietsgestolenReducer.emailLoading;

export const selectVerificationCodeFietsgestolen = (state: RootState) =>
  state.rootReducer.fietsgestolenReducer.verificationCodeFietsgestolen;

export const selectVerificationCodeLoadingFietsgestolen = (state: RootState) =>
  state.rootReducer.fietsgestolenReducer.verificationCodeLoadingFietsgestolen;

export const selectObjectList = (state: RootState) =>
  state.rootReducer.fietsgestolenReducer.objectList;

export const selectRegisterTheftLoading = (state: RootState) =>
  state.rootReducer.fietsgestolenReducer.registerTheftLoading;

export const selectBarcodeLoading = (state: RootState) =>
  state.rootReducer.fietsgestolenReducer.barcodeLoading;

export const selectBarcode = (state: RootState) =>
  state.rootReducer.fietsgestolenReducer.barcode;

export const selectRegisterFietsLoading = (state: RootState) =>
  state.rootReducer.fietsgestolenReducer.registerFietsLoading;

export const selectUserDataState = createSelector(
  selectUserEmail,
  selectVerificationCodeFietsgestolen,
  selectObjectList,
  selectBarcode,
  selectUserEmailLoading,
  selectVerificationCodeLoadingFietsgestolen,
  selectRegisterTheftLoading,
  selectBarcodeLoading,
  selectRegisterFietsLoading,
  (
    userEmail,
    verificationCodeFietsgestolen,
    objectList,
    barcode,
    emailLoading,
    verificationCodeLoadingFietsgestolen,
    registerTheftLoading,
    barcodeLoading,
    registerFietsLoading
  ) => ({
    userEmail,
    verificationCodeFietsgestolen,
    objectList,
    barcode,
    emailLoading,
    verificationCodeLoadingFietsgestolen,
    registerTheftLoading,
    barcodeLoading,
    registerFietsLoading,
  })
);
