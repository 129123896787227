import activationAxiosInstance from "app/activationAxiosInstance";

class ActivationPortal {
  static getInstallation = async (activationCode: string) => {
    return await activationAxiosInstance.get(`/${activationCode}`);
  };

  static getSubscriptions = async (activationCode: string) => {
    return await activationAxiosInstance.get(
      `/${activationCode}/available-subscriptions`
    );
  };

  static getInformationSecurityQuestions = async () => {
    return await activationAxiosInstance.get("security-questions");
  };

   static getMyAccounts = async () => {
    return await activationAxiosInstance.get("my-accounts");
  };

  static activateSubscription = async (data: any) => {
    return await activationAxiosInstance.post("/subscribe", data);
  };
}

export default ActivationPortal;
