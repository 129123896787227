import { FC, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import {
  Table,
  types,
  useDispatchOnParams,
  Dropdown,
  utils,
} from "@vilocnv/allsetra-core";
import BulkRidesUpdateDriverForm from "components/forms/objects/BulkRidesUpdateDriverForm/BulkRidesUpdateDriverForm";
import BulkRidesUpdateOrderForm from "components/forms/objects/BulkRidesUpdateOrderForm/BulkRidesUpdateOrderForm";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import { OBJECT_RIDES_TABLE_COLUMNS } from "app/data/constants";
import {
  selectObjectRidesState,
  selectDrawerSelectedAccountId,
} from "app/data/selectors";
import {
  getObjectRidesByQueryThunk,
  setObjectSpecificRide,
} from "app/features";
import { useTranslation } from "react-i18next";

interface Props {
  specificObject: types.IObject | null;
}

const ObjectRidesSection: FC<Props> = ({ specificObject }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Global State
  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) ?? "";

  const { objectRides, objectRidesRowCount, ridesLoading } = useAppSelector(
    selectObjectRidesState
  );

  // Local State
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [selectableRows, setSelectableRows] = useState<boolean>(false);
  const [selectedRowsIds, setSelectedRowsIds] = useState<Array<string>>([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);

  // Local State Forms
  const [openBulkModeForm, setOpenBulkModeForm] = useState(false);
  const [openBulkDriverForm, setOpenBulkDriverForm] = useState(false);

  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const tableColumns = useMemo(() => OBJECT_RIDES_TABLE_COLUMNS(t), [t]);

  useDispatchOnParams(getObjectRidesByQueryThunk, {
    args: {
      accountId: drawerSelectedAccountId,
      objectId: specificObject?.uniqueId || "",
    },
    additionalParams: {
      includeRidesWithoutDriver: true,
    },
    orderBy: [{ field: "startDate", ascending: true }],
  });

  const hanldeViewOnMap = (ride: types.IObjectRide) => {
    dispatch(setObjectSpecificRide(ride));
    navigate(
      `/dashboard/objects/${specificObject?.uniqueId}/rides/map/${ride?.uniqueId}`
    );
  };

  const handleDropdownAnchorClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setDropdownAnchorEl(event.currentTarget);
  };

  const onSelectedRowsChange = ({ selectedRows }: any) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    const ids = selectedRows?.map((item: any) => item.uniqueId) ?? [];
    setSelectedRowsIds(ids);
  };

  // Toggle the state so React Data Table changes to clearSelectedRows are triggered
  const handleClearRows = () => {
    setSelectedRowsIds([]);
    setToggleClearRows(!toggledClearRows);
  };

  const fetchDataAndResetSelectableRows = () => {
    dispatch(
      getObjectRidesByQueryThunk({
        accountId: drawerSelectedAccountId ?? "",
        objectId: specificObject?.uniqueId ?? "",
        params: utils.getCommonParamsForApi(),
      })
    );
    setSelectableRows(false);
    setSelectedRowsIds([]);
  };

  return (
    <Box mt={4}>
      <Table
        columns={tableColumns}
        data={objectRides ?? []}
        progressPending={ridesLoading}
        paginationTotalRows={objectRidesRowCount}
        showDateRangeFilter={true}
        cellActions={[
          {
            name: t("buttonsText.viewOnmap"),
            onClick: hanldeViewOnMap,
          },
        ]}
        secondaryButton={{
          variant: "contained",
          text: selectableRows ? "Actions" : t("buttonsText.select"),
          size: "small",
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
            if (selectableRows) {
              handleDropdownAnchorClick(event);
            } else {
              setSelectableRows(true);
            }
          },
          theme,
        }}
        {...(selectableRows
          ? {
              primaryButton: {
                variant: "outlined",
                text: t("buttonsText.cancel"),
                size: "small",
                onClick: () => {
                  setSelectableRows(false);
                  handleClearRows();
                },
                theme,
              },
            }
          : null)}
        selectableRows={selectableRows}
        onSelectedRowsChange={onSelectedRowsChange}
        clearSelectedRows={toggledClearRows}
      />
      <Dropdown
        anchorEl={dropdownAnchorEl}
        setAnchorEl={setDropdownAnchorEl}
        items={[
          {
            name: t("common.changeRideType"),
            onClick: () => setOpenBulkModeForm(true),
          },
          {
            name: t("common.changeDriver"),
            onClick: () => setOpenBulkDriverForm(true),
          },
        ]}
      />
      {/* Forms */}
      <BulkRidesUpdateOrderForm
        open={openBulkModeForm}
        onClose={() => setOpenBulkModeForm(false)}
        rides={selectedRowsIds}
        afterSubmitActionHandler={fetchDataAndResetSelectableRows}
      />
      <BulkRidesUpdateDriverForm
        open={openBulkDriverForm}
        onClose={() => setOpenBulkDriverForm(false)}
        rides={selectedRowsIds}
        afterSubmitActionHandler={fetchDataAndResetSelectableRows}
      />
    </Box>
  );
};

export default ObjectRidesSection;
