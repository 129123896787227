import { types } from "@vilocnv/allsetra-core";
import { isEmpty, sortBy } from "lodash";

export enum ObjectFieldsEnum {
  isHide = 1,
  isTag = 4,
  isDynamicField = 2,
  isBoth = 6,
}

export const sanitizeObjectsMetadataForTags = (object: types.IObject) => {
  const metadata = object.metadata;

  if (isEmpty(metadata)) return [];

  return sortBy(
    metadata.filter(
      (item) => item.field.customerPortal === ObjectFieldsEnum.isTag
    ),
    [(o) => o.field.label]
  );
};

export const sanitizeObjectsMetadataForDynamicFields = (
  object: types.IObject
) => {
  const metadata = object.metadata;

  if (isEmpty(metadata)) return [];

  return sortBy(
    metadata.filter(
      (item) =>
        item.field.customerPortal === ObjectFieldsEnum.isDynamicField ||
        item.field.customerPortal === ObjectFieldsEnum.isBoth
    ),
    [(o) => o.field.label]
  );
};

export const transformObjectMetadataValue = (value: string) => {
  if (value === "True") {
    return "ON";
  } else if (value === "False") {
    return "OFF";
  } else {
    return value;
  }
};
