import { types } from "@vilocnv/allsetra-core";
import axiosInstance from "app/axiosInstance";
import {
  IRideBulkUpdateKeys,
  IRideBulkUpdateMode,
} from "./../types/objectRideTypes";

class ObjectsService {
  static getAllObjects = async (accountId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/objects`);
  };

  static getAllBasicInfoObjects = async (accountId: string) => {
    return await axiosInstance.get(`/accounts/${accountId}/objects/basic-info`);
  };

  static getObjectsByQuery = async (
    accountId: string,
    data: types.IRecordsAggregationBody
  ) => {
    return await axiosInstance.post(
      `/accounts/${accountId}/objects/search`,
      data
    );
  };

  static getObjectsLocations = async (
    accountId: string,
    data: types.IMapFilter
  ) => {
    return await axiosInstance.post(
      `/accounts/${accountId}/objects/locations`,
      data
    );
  };

  static getSpecificObjectById = async (
    accountId: string,
    objectId: string
  ) => {
    return await axiosInstance.get(
      `/accounts/${accountId}/objects/${objectId}`
    );
  };

  static getObjectAlarmConfig = async (accountId: string, objectId: string) => {
    return await axiosInstance.get(
      `/accounts/${accountId}/objects/${objectId}/alarm-settings`
    );
  };

  static postUpdateObject = async (accountId: string, data: any) => {
    return await axiosInstance.patch(
      `/accounts/${accountId}/objects/${data.uniqueId}`,
      data
    );
  };

  static patchObjectMilageCorrection = async ({
    accountId,
    objectId,
    data,
  }: {
    accountId: string;
    objectId: string;
    data: {
      newMileage: number;
      correctionDate: string;
    };
  }) => {
    return await axiosInstance.patch(
      `/accounts/${accountId}/objects/${objectId}/mileage`,
      data
    );
  };

  //
  // Object Rides
  //
  static getObjectRidesByQuery = async (
    { accountId, objectId }: { accountId: string; objectId: string },
    data: types.IRecordsAggregationBody
  ) => {
    return await axiosInstance.post(
      `/accounts/${accountId}/objects/${objectId}/rides/search`,
      data
    );
  };

  static getObjectSpecificRideInfo = async ({
    accountId,
    objectId,
    rideId,
  }: {
    accountId: string;
    objectId: string;
    rideId: string;
  }) => {
    return await axiosInstance.get(
      `/accounts/${accountId}/objects/${objectId}/rides/${rideId}`
    );
  };

  static updateObjectRide = async (
    {
      accountId,
      objectId,
      rideId,
    }: { accountId: string; objectId: string; rideId: string },
    data: any
  ) => {
    return await axiosInstance.patch(
      `/accounts/${accountId}/objects/${objectId}/rides/${rideId}`,
      data
    );
  };

  static getObjectRideLocations = async ({
    accountId,
    objectId,
    rideId,
  }: {
    accountId: string;
    objectId: string;
    rideId: string;
  }) => {
    return await axiosInstance.get(
      `/accounts/${accountId}/objects/${objectId}/rides/${rideId}/locations`
    );
  };

  static updateRideBulkUploadKeys = async (
    accountId: string,
    data: IRideBulkUpdateKeys
  ) => {
    return await axiosInstance.patch(
      `/accounts/${accountId}/rides/bulk/key`,
      data
    );
  };

  static updateRideBulkUploadMode = async (
    accountId: string,
    data: IRideBulkUpdateMode
  ) => {
    return await axiosInstance.patch(
      `/accounts/${accountId}/rides/bulk/mode`,
      data
    );
  };

  // Patch Object Milaege
  static updateAccountMileage = async ({
    accountId,
    objectId,
    data,
  }: {
    accountId: string;
    objectId: string;
    data: any;
  }) => {
    return await axiosInstance.patch(
      `/accounts/${accountId}/objects/${objectId}/mileage`,
      data
    );
  };

  // Object Features
static getObjectFeatures = async (accountId: string, objectId: string) => {
    return await axiosInstance.get(
      `/accounts/${accountId}/objects/${objectId}/features`
    );
  };
}

export default ObjectsService;
