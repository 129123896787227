import { styled, Box, Typography, Stack } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export const AssignedUsersCardContainer = styled(Box)({
  padding: "16px",
  borderRadius: "8px",
  border: "1px solid #EFF4FF",
  background: "#fff",
});

export const CardText = styled(Typography)<{
  color?: string;
  fontWeight?: string;
  fontSize?: string;
}>(({ color, fontWeight, fontSize }) => ({
  color: color || "#646D7B",
  fontSize,
  fontWeight,
  lineHeight: "116%",
}));

export const StyledAccessTimeIcon = styled(AccessTimeIcon)({
  width: "16px",
  height: "16px",
  color: "#646D7B",
});
