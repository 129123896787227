import { FC } from "react";
import {
  StepButtonContainer,
  StepHeadingContainer,
  StepScreenButton,
  StepScreenContainer,
  StepSubtitle,
  StepTitle,
} from "./StepScreenLayout.styled";
import { useTheme } from "@mui/material";
import { Button } from "@vilocnv/allsetra-core";

export interface StepScreenLayoutProps {
  children: JSX.Element | JSX.Element[];
  title: string;
  subtitle: string;
  disabled?: boolean;
  buttonText?: string;
  loading?: boolean;

  isFietsgestolen?: boolean;
  onButtonSubmit: () => void;
}

const StepScreenLayout: FC<StepScreenLayoutProps> = ({
  children,
  title,
  subtitle,
  onButtonSubmit,
  disabled,
  buttonText,
  isFietsgestolen = false,
  loading,
}) => {
  const theme = useTheme();

  return (
    <StepScreenContainer isFietsgestolen={isFietsgestolen}>
      <StepHeadingContainer>
        <StepTitle>{title}</StepTitle>
        <StepSubtitle color="var(--gray-600, #646D7B)">{subtitle}</StepSubtitle>
      </StepHeadingContainer>
      {children}
      <StepButtonContainer isFietsgestolen={isFietsgestolen}>
        {isFietsgestolen ? (
          <Button
            disabled={disabled}
            onClick={onButtonSubmit}
            theme={theme}
            loading={loading}
            size="large"
          >
            {buttonText}
          </Button>
        ) : (
          <StepScreenButton
            disabled={disabled}
            onClick={onButtonSubmit}
            theme={theme}
            loading={loading}
            isFietsgestolen={true}
          >
            {buttonText || "Next"}
          </StepScreenButton>
        )}
      </StepButtonContainer>
    </StepScreenContainer>
  );
};

export default StepScreenLayout;
