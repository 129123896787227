import { FC, useEffect, useMemo } from "react";
import {
  ContentSectionLayout,
  FormikTimeFrameField,
  FormikToggleField,
} from "@vilocnv/allsetra-core";
import { ChildFormBox } from "../ObjectSettingsForm.styled";
import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
import { isEmpty } from "lodash";
import { workingHoursFormatterForSettingsForm } from "app/data/helpers";
import { useTranslation } from "react-i18next";

interface Props {
  activeObject: any;
}

const ObjectWorkingHoursSetting: FC<Props> = ({ activeObject }) => {
  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  const { values: objectValues, setFieldValue } = useFormikContext<any>();

  const workingHoursUpdated = useMemo(
    () =>
      !isEmpty(activeObject)
        ? workingHoursFormatterForSettingsForm(objectValues, activeObject)
        : {},
    [objectValues.isWorkingHoursOverriden]
  );

  useEffect(() => {
    setFieldValue(
      "workingHours.workingHoursSchedule",
      workingHoursUpdated.workingHours.workingHoursSchedule
    );

    setFieldValue("workingHoursType", workingHoursUpdated.workingHoursType);
  }, [objectValues.isWorkingHoursOverriden]);

  return (
    <ContentSectionLayout
      title={t("titles.workingHours")}
      subTitle={t("descriptions.workingHours")}
    >
      <Stack spacing={4}>
        <FormikToggleField
          label="Custom working hours configuration"
          name="isWorkingHoursOverriden"
        />
        <ChildFormBox>
          {objectValues.isWorkingHoursOverriden && (
            <FormikTimeFrameField
              label={t("workingHours.label", { ns: "formFieldsTranslation" })}
              typeName="workingHoursType"
              name="workingHours.workingHoursSchedule"
              translator={t}
            />
          )}
        </ChildFormBox>
      </Stack>
    </ContentSectionLayout>
  );
};

export default ObjectWorkingHoursSetting;
