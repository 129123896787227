import { FC } from "react";
import { useTheme } from "@mui/material";

const ActiveReportsIcon: FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6665 3.83333V12.1667H4.49984V0.5H10.3332M13.6665 3.83333L10.3332 0.5M13.6665 3.83333H10.3332V0.5M11.9998 12.1667V13.8333H2.83317V2.16667H4.49984M10.3332 13.8333V15.5H1.1665V3.83333H2.83317"
        stroke={theme.palette.primary.main}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ActiveReportsIcon;
