import React, { FC, useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import { GoogleMap } from "@react-google-maps/api";
import {
  FilterButton,
  PageLoader,
  SelectInputField,
} from "@vilocnv/allsetra-core";
import {
  IconBox,
  IconTooltip,
  IconsWrapper,
  MapContainer,
  SearchFieldWrapper,
  TopLeftSection,
} from "./Map.styled";
import Geozone from "../common/Geozone";
import MarkerClusterer from "../common/MarkerClusterer";
import SatelliteAltIcon from "@mui/icons-material/SatelliteAlt";

// Data
import { isEmpty } from "lodash";
import {
  useAppDispatch,
  useAppSelector,
  useMap,
  useObjectLiveLocation,
} from "hooks";
import { FilterIcon, ShowObjectIcon, ShowObjectIconBlack } from "assets/icons";
import { DEFAULT_COORDINATES, googleMapStyles } from "app/data/constants";
import { getSpecificObjectByIdThunk } from "app/features";
import {
  selectDrawerSelectedAccountId,
  selectObjectMapState,
  selectSpecificObjectState,
} from "app/data/selectors";

interface MapProps {
  center?: {
    lat: number;
    lng: number;
  };
  zoom?: number;
  radius?: number; // Specify the radius around the center for random placement
  objects: Array<any>;
  geozones?: Array<any>;
  height?: string;
  showSearch?: boolean;
  showFilter?: boolean;
  onFilterClick?: () => void;
  objectsMarker?: boolean;
  skipCurrentLocation?: boolean;
  selectedObjectId?: string;
}

const Map: FC<MapProps> = ({
  center,
  zoom = 8,
  radius = 50,
  objects,
  geozones,
  height,
  showSearch = false,
  showFilter = false,
  onFilterClick,
  objectsMarker = false,
  selectedObjectId,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global State
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const { markers } = useAppSelector(selectObjectMapState);
  const { activeObjectId } = useAppSelector(selectSpecificObjectState);

  // Local State
  const [showObjectNames, setShowObjectNames] = useState<boolean>(false);
  const [showSatelliteMode, setShowSatelliteMode] = useState<boolean>(false);
  const [shouldRenderGeozone, setShouldRenderGeozone] =
    useState<boolean>(false);
  const [selectedMarkerLoading, setSelectedMarkerLoading] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  // Map Hook
  const { isLoaded, loadError, mapRef, centerCoords, onMapLoad } = useMap({
    center: center || DEFAULT_COORDINATES,
    disableNavigator: true,
  });

  const { t } = useTranslation();

  useObjectLiveLocation(isSelectOpen);

  useEffect(() => {
    window.localStorage.setItem("selectedObjectId", selectedObjectId ?? "");
  }, [selectedObjectId]);

  useEffect(() => {
    if (geozones && geozones.length > 0) {
      setShouldRenderGeozone(true);
    } else {
      setShouldRenderGeozone(false);
    }
  }, [geozones]);

  const handleMarkerClick = useCallback(
    async (marker: any) => {
      if (isEmpty(marker)) return;

      setSelectedMarkerLoading(true);

      window.localStorage.setItem("selectedObjectId", marker);

      await dispatch(
        getSpecificObjectByIdThunk({
          accountId: drawerSelectedAccountId || "",
          objectId: marker,
          shouldFetchAlarmConfig: false,
        })
      );

      setSelectedMarkerLoading(false);
    },
    [dispatch, drawerSelectedAccountId]
  );

  const onObjectChange = (value: any) => {
    if (isEmpty(value)) return;

    const objectIndex = objects.findIndex((obj: any) => obj.uniqueId === value);

    if (objectIndex < 0) return;
    //@ts-ignore
    handleMarkerClick(objects[objectIndex].uniqueId);

    mapRef?.setZoom(22);
    mapRef?.panTo({
      lat: objects[objectIndex].location.latitude,
      lng: objects[objectIndex].location.longitude,
    });
  };

  useEffect(() => {
    handleMarkerClick(activeObjectId);
  }, [activeObjectId]);

  if (!isLoaded) {
    return <PageLoader />;
  }

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return (
    <MapContainer height={height || "100vh"}>
      <IconsWrapper>
        {!objectsMarker && (
          <IconTooltip title={t("common.showObjectsNames")} arrow>
            <IconBox
              id="object-names"
              active={showObjectNames}
              onClick={() => setShowObjectNames((prevState) => !prevState)}
            >
              {showObjectNames ? <ShowObjectIconBlack /> : <ShowObjectIcon />}
            </IconBox>
          </IconTooltip>
        )}
        <IconTooltip title={t("common.showSatelliteMode")} arrow>
          <IconBox
            id="settelite-view"
            topMargin={objectsMarker}
            active={showSatelliteMode}
            onClick={() => setShowSatelliteMode((prevState) => !prevState)}
          >
            {!showSatelliteMode ? (
              <SatelliteAltIcon color="secondary" fontSize="small" />
            ) : (
              <SatelliteAltIcon fontSize="small" />
            )}
          </IconBox>
        </IconTooltip>
      </IconsWrapper>
      <TopLeftSection>
        {showSearch && (
          <SearchFieldWrapper>
            <SelectInputField
              // @ts-ignore
              id="searchbar-map"
              name="object"
              placeholder={t("common.search")}
              options={markers ?? []}
              optionLabelKey={"name"}
              optionValueKey={"uniqueId"}
              onChange={onObjectChange}
              searchable
              //@ts-ignore
              onOpen={() => setIsSelectOpen(true)}
              onClose={() => setIsSelectOpen(false)}
            />
          </SearchFieldWrapper>
        )}
        {showFilter && (
          <FilterButton
            id="filter-button"
            theme={theme}
            onClick={onFilterClick}
            icon={<FilterIcon color={theme.palette.primary.main} />}
          />
        )}
      </TopLeftSection>
      <GoogleMap
        mapTypeId={
          showSatelliteMode
            ? google.maps.MapTypeId.HYBRID
            : google.maps.MapTypeId.ROADMAP
        }
        center={centerCoords}
        zoom={zoom}
        onLoad={onMapLoad}
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={{ styles: googleMapStyles }}
      >
        {shouldRenderGeozone && geozones && (
          <Geozone
            geozone={geozones}
            radius={radius}
            objectsMarker={objectsMarker}
          />
        )}
        {markers.length && (
          <MarkerClusterer
            map={mapRef}
            markers={markers}
            selectedMarkerLoading={selectedMarkerLoading}
            showObjectNames={showObjectNames}
          />
        )}
      </GoogleMap>
    </MapContainer>
  );
};

export default React.memo(Map);
