import rootRtkQuery from "../rootRTKQuery";

const keysManagerQueries = rootRtkQuery.injectEndpoints({
  endpoints: (builder) => ({
    getAllKeys: builder.query<any, string | null>({
      query: (accountId) => ({
        url: `/accounts/${accountId}/keys`,
        headers: {
          "X-Subscription": process.env.REACT_APP_API_HEADER_SUBSCRIPTION,
          "X-TenantId": accountId ?? "",
        },
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),
    getAvailableKeysForAccount: builder.query<any, string | null>({
      query: (accountId) => ({
        url: `/accounts/${accountId}/keys/available`,
        headers: {
          "X-Subscription": process.env.REACT_APP_API_HEADER_SUBSCRIPTION,
          "X-TenantId": accountId ?? "",
        },
      }),
      transformResponse: (response: any) => {
        return response;
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllKeysQuery, useGetAvailableKeysForAccountQuery } =
  keysManagerQueries;
