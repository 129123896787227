import { FC } from "react";
import { Box, useTheme } from "@mui/material";
import { Button } from "@vilocnv/allsetra-core";
import { useTranslation } from "react-i18next";
import { Field } from "formik";
import { DAYS, daysMapping } from "app/data/helpers";

const ScheduleDaysFormats: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Field name="daysOfWeek">
      {({ field, form }: { field: any; form: any }) => (
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(7, auto)" }}>
          {DAYS?.map((day) => (
            <Button
              key={day}
              text={day}
              size="small"
              variant={
                //@ts-ignore
                field?.value?.includes(daysMapping[day])
                  ? "contained"
                  : "outlined"
              }
              theme={theme}
              onClick={() => {
                //@ts-ignore
                const newDays = field?.value?.includes(daysMapping[day])
                  ? //@ts-ignore
                    field?.value?.filter((d: string) => d !== daysMapping[day])
                  : //@ts-ignore
                    [...field?.value, daysMapping[day]];
                form.setFieldValue("daysOfWeek", newDays);
              }}
              sx={{
                borderRadius: 0,
                ...(day === "Mon" && {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }),
                ...(day === "Sun" && {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  mr: 2,
                }),
              }}
            />
          ))}
        </Box>
      )}
    </Field>
  );
};

export default ScheduleDaysFormats;
