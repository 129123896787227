import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { InfoWindowF, MarkerF, MarkerProps } from "@react-google-maps/api";
import { MapObjectCard, ObjectMetadataTags } from "@vilocnv/allsetra-core";

// Data
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks";
import { setActiveObjectId } from "app/features";
import { selectSpecificObjectState } from "app/data/selectors";

function createKey(location: any) {
  return location.lat + location.lng;
}

interface Props {
  marker: any;
  selectedMarkerLoading: boolean;
  clusterer: any;
}

const ObjectMarker: FC<Props> = ({
  marker,
  selectedMarkerLoading,
  clusterer,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Global State
  const { specificObject, activeObjectId, specificObjectLoading } =
    useAppSelector(selectSpecificObjectState);

  return (
    <MarkerF
      key={createKey(marker)}
      position={{ lat: marker.lat || 0, lng: marker.lng || 0 }}
      clusterer={clusterer}
      onClick={() => dispatch(setActiveObjectId(marker.uniqueId ?? ""))}
      icon={
        marker.iconUrl
          ? {
              url: marker.iconUrl,
              scaledSize: new window.google.maps.Size(28, 28),
            }
          : undefined
      }
    >
      {activeObjectId === marker.uniqueId && (
        <InfoWindowF
          position={{ lat: marker.lat || 0, lng: marker.lng || 0 }}
          onCloseClick={() => dispatch(setActiveObjectId(null))}
        >
          <MapObjectCard
            label={marker.name || "N/A"}
            id={marker.aNumber || "N/A"}
            loading={selectedMarkerLoading}
            //@ts-ignore
            address={
              !isEmpty(specificObject) &&
              specificObject?.location?.resolvedAddress
            }
            accuracy={marker.accuracy || "N/A"}
            time={marker.formattedDate}
            handleObjectNameClick={() =>
              navigate({
                pathname: `/dashboard/objects/details/${marker.uniqueId}`,
              })
            }
          >
            {!isEmpty(specificObject) && !specificObjectLoading && (
              <ObjectMetadataTags object={specificObject} direction={"row"} />
            )}
          </MapObjectCard>
        </InfoWindowF>
      )}
    </MarkerF>
  );
};

export default ObjectMarker;
