import { FC } from "react";
import { useTheme } from "@mui/material";

const ActiveKeysIcon: FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.636 4.87868C14.245 5.26971 14.245 5.90186 14.636 6.29289C15.0271 6.68392 15.6592 6.68392 16.0503 6.29289C16.4413 5.90186 16.4413 5.26971 16.0503 4.87868C15.6592 4.48765 15.0271 4.48765 14.636 4.87868Z"
        stroke={theme.palette.primary.main}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.4645 3.46447C19.4175 5.4175 19.4175 8.5825 17.4645 10.5355C16.0156 11.9844 13.8999 12.3535 12.106 11.6513L9.68629 14.0711H8.27208V15.4853H6.85786V16.8995H4.02944V14.0711L9.27758 8.82292C8.57543 7.02899 8.94454 4.91333 10.3934 3.46447C12.3464 1.51144 15.5114 1.51144 17.4645 3.46447Z"
        stroke={theme.palette.primary.main}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ActiveKeysIcon;
