import { FC } from "react";
import { Stack, Typography, Box } from "@mui/material";
import { types, ObjectMetadataTags } from "@vilocnv/allsetra-core";
import { CardContainer, SmallText } from "./ObjectDetailsCard.styled";
import { LocationIcon, WifiIcon } from "assets/icons";
import { objectDetailsCardDateFormmater } from "app/data/helpers";
import { useTranslation } from "react-i18next";

export interface ObjectDetailsCardProps {
  object: types.IObject;
}

const ObjectDetailsCard: FC<ObjectDetailsCardProps> = ({ object }) => {
  const { t } = useTranslation();

  return (
    <CardContainer>
      <Stack direction={"column"}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <LocationIcon />
            <Typography>
              {objectDetailsCardDateFormmater(object.lastUpdated)}
            </Typography>
          </Stack>
          <Typography>ID: {object.aNumber}</Typography>
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={2}
          mt={2}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <WifiIcon />
            <SmallText>
              {t("typography.locationAccuracy")}{" "}
              {object.location?.accuracy ?? "-"} meters
            </SmallText>
          </Stack>
        </Stack>
        <Box mt={2}>
          <ObjectMetadataTags object={object} direction={"row"} />
        </Box>
      </Stack>
    </CardContainer>
  );
};

export default ObjectDetailsCard;
