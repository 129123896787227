import { styled, Box } from "@mui/material";

export type DrawerLayoutSize = "medium" | "large" | "extraLarge";

export enum DrawerLayoutSizeEnum {
  "medium" = "185px",
  "large" = "240px",
  "extraLarge" = "320px",
}

export const ActiveStepBody = styled(Box)<{
  isDrawerCollapsed: boolean;
  size: DrawerLayoutSize;
}>(({ theme, isDrawerCollapsed, size }) => ({
  position: "relative",
  marginLeft: isDrawerCollapsed ? "65px" : DrawerLayoutSizeEnum[size],
  width: `calc(100% - ${
    isDrawerCollapsed ? "65px" : DrawerLayoutSizeEnum[size]
  })`,
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  overflowX: "hidden",

  [theme.breakpoints.down("sm")]: {
    marginLeft: 0,
    width: "100%",
  },
}));

//
// STEPPER MENU
//
export const StepsMenu = styled(Box)({
  marginTop: "40px",
  display: "flex",
  flexDirection: "column",
});

export const StepsMenuItem = styled(Box)<{
  isActive: boolean;
  isCompleted: boolean;
}>(({ theme, isActive, isCompleted }) => ({
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  color: isActive || isCompleted ? theme.palette.primary.main : "#EFF4FF",
  fontSize: "16px",
  fontWeight: 800,
  lineHeight: "105%",
  padding: "0px 24px",

  [":not(:last-child)"]: {
    marginBottom: "14px",
  },
}));

export const ActiveIconBox = styled(Box)({
  width: "14px",
  height: "16px",
  marginRight: "8px",
});
