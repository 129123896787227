import { styled, Box } from "@mui/material";

export const PageContainer = styled(Box)({
  width: "100vw",
  height: "100vh",
  display: "flex",
});

export const LeftContainer = styled(Box)(({ theme }) => ({
  width: "50vw",
  height: "100vh",
  backgroundColor: "#f9fbff",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    backgroundColor: "transparent",
  },
}));

export const RightContainer = styled(Box)<{
  whiteLabelBgImage?: string;
}>(({ theme, whiteLabelBgImage }) => ({
  width: "50vw",
  backgroundImage: whiteLabelBgImage
    ? `url(${whiteLabelBgImage})`
    : `url(${require("../../../assets/images/backgroundImage.png")})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",

  [theme.breakpoints.down("md")]: {
    width: "100vw",
    height: "100vh",
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: "-999",
    backgroundImage: whiteLabelBgImage
      ? `url(${whiteLabelBgImage})`
      : `url(${require("../../../assets/images/backgroundImageTablet.png")})`,
  },

  [theme.breakpoints.down("sm")]: {
    backgroundImage: whiteLabelBgImage
      ? `url(${whiteLabelBgImage})`
      : `url(${require("../../../assets/images/backgroundImagePhone.png")})`,
  },
}));

export const FormContainer = styled(Box)({
  padding: "0 2vw",
  marginTop: "40px",
});

export const Logo = styled(Box)(({ theme }) => ({
  width: "270px",
  height: "56px",
  backgroundImage: `url(${require("../../../assets/images/logo.png")})`,

  [theme.breakpoints.down("md")]: {
    backgroundImage: `url(${require("../../../assets/images/logo_tablet.png")})`,
  },

  [theme.breakpoints.down("sm")]: {
    backgroundImage: `url(${require("../../../assets/images/logo_phone.png")})`,
    height: "35px",
    width: "154.49325561523438px",
  },
}));

export const LogoWrapper = styled(Box)(({ theme }) => ({
  width: "270px",
  height: "56px",

  [theme.breakpoints.down("sm")]: {
    height: "35px",
    width: "154.49325561523438px",
  },

  ["> *"]: {
    width: "100%",
    height: "100%",
  },
}));

export const Title = styled("p")(({ theme }) => ({
  fontSize: "28px",
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "115%",
  color: "var(--gray-900, #1D1F2B)",
  textAlign: "center",
  marginTop: "70px",

  [theme.breakpoints.down("md")]: {
    color: "#f9fbff",
    fontSize: "24px",
  },
}));

export const SubTitle = styled("p")(({ theme }) => ({
  width: "80%",
  color: "var(--gray-900, #1D1F2B)",
  textAlign: "center",
  fontSize: "18px",
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "136%",
  marginTop: "30px",

  [theme.breakpoints.down("md")]: {
    color: "#f9fbff",
  },
}));

export const Circle164Container = styled(Box)(({ theme }) => ({
  width: "164px",
  height: "164px",
  opacity: "0.6399999856948853",
  position: "absolute",
  bottom: "0px",
  left: "25px",
  zIndex: "1000",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const Circle215LogoContainer = styled(Box)(({ theme }) => ({
  width: "215px",
  height: "215px",
  position: "absolute",
  bottom: "120px",
  // right: "450px",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export const Circle577LogoContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "0px",
  right: "0px",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));
