import { isEmpty } from "lodash";
import {
  InteractionRequiredAuthError,
  SilentRequest,
} from "@azure/msal-browser";
import { msalInstance } from "index";
import { toast } from "@vilocnv/allsetra-core";
import * as Sentry from "@sentry/react";

class AzureMsal {
  static acquireToken = async () => {
    const account = msalInstance.getAllAccounts()[0];
    const redirectUri = window.location.origin;

    const accessTokenRequest: SilentRequest = {
      scopes: [
        "openid",
        "profile",
        "offline_access",
        "https://graph.microsoft.com/.default",
      ],
      account: account,
      redirectUri: redirectUri,
    };

    try {
      const accessTokenResponse = await msalInstance.acquireTokenSilent(
        accessTokenRequest
      );
      return accessTokenResponse;
    } catch (error) {
      console.log(error);

      if (error instanceof InteractionRequiredAuthError) {
        return msalInstance.acquireTokenRedirect(accessTokenRequest);
      }
    }
  };

  static logoutOnTokenExp = () => {
    const account = msalInstance.getAllAccounts()[0];

    if (isEmpty(account)) {
      msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" });
      Sentry.setUser(null);
      window.localStorage.clear();
    } else {
      const exp: number | undefined = account.idTokenClaims?.exp;

      if (exp && exp < Date.now() / 1000) {
        toast.info("User session is expired.");
        msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" });
        Sentry.setUser(null);
        window.localStorage.clear();
      }
    }
  };
}

export default AzureMsal;
