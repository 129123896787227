import { Dispatch, FC, Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  SupportingDrawerLayout,
  types,
  Badge,
  KeyValueTable,
} from "@vilocnv/allsetra-core";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EditIcon from "@mui/icons-material/Edit";
import UpdateObjectRideForm from "components/forms/objects/UpdateObjectRideForm/UpdateObjectRideForm";
import LocationTimeline from "components/ui/LocationTimeline/LocationTimeline";
import {
  SpeedIcon,
  Clock,
  RideStartIcon,
  RideStopIcon,
  MileageIcon,
} from "assets/icons";
import {
  IconBox,
  RecordKey,
  RecordValue,
  RecordsWrapper,
} from "./ObjectRideDetailsDrawer.styled";

// Data
import moment from "moment";
import { round } from "lodash";
import {
  transformObjectRideLocationsForTimeline,
  OBJECT_RIDE_MODES,
  OBJECT_RIDE_MODES_COLOR,
  getObjectRideTotalTimeInMinutes,
  getObjectRideKeyValueData,
  getAddressFromLatLng,
} from "app/data/helpers";
import { useTranslation } from "react-i18next";
import { selectGeozonesState } from "app/data/selectors";
import { useAppSelector } from "hooks";
import { DateTime } from "luxon";

interface Props {
  showDrawer?: boolean;
  objectRide: types.IObjectRide | null;
  objectRideLocations: Array<types.IObjectRideLocation>;
  onLocationItemClick: (data: types.IObjectRideLocation) => void;
}

const ObjectRideDetailsDrawer: FC<Props> = ({
  showDrawer = true,
  objectRide,
  objectRideLocations,
  onLocationItemClick,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation([
    "translation",
    "tableHeadingsTranslation",
    "formFieldsTranslation",
  ]);

  const [openUpdateRide, setOpenUpdateRide] = useState<boolean>(false);

  const timeLineItems = useMemo(() => {
    const items = transformObjectRideLocationsForTimeline(
      objectRideLocations,
      objectRide
    );
    return items.length > 2 ? [items[0], items[items.length - 1]] : items;
  }, [objectRideLocations, objectRide]);

  const getParsedSpeed = (speed: any) => `${parseFloat(speed.toFixed(2))} km/h`;

  return (
    <SupportingDrawerLayout open={showDrawer}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIosIcon />
          </IconButton>
          <Typography variant={"h6"}>
            {objectRide
              ? // @ts-ignore
                `${t("common.ride")} № ${objectRide?.id}`
              : "Ride not found"}
          </Typography>
        </Stack>
        {objectRide && (
          <IconButton onClick={() => setOpenUpdateRide(true)}>
            <EditIcon />
          </IconButton>
        )}
      </Stack>
      <hr />
      {objectRide && (
        <Box mx={1}>
          <Stack mt={2} direction={"row"} justifyContent={"space-between"}>
            <Stack spacing={2}>
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <IconBox>
                  <RideStartIcon />
                </IconBox>
                <Typography variant={"subtitle1"}>
                  {moment(objectRide?.startDate).format("MMM Do YYYY, HH:mm")}
                </Typography>
              </Stack>
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                {objectRide.status === 1 ? (
                  <Badge colorScheme={"purple"}>Ride in progress</Badge>
                ) : (
                  <Fragment>
                    <IconBox>
                      <RideStopIcon />
                    </IconBox>
                    <Typography variant={"subtitle1"}>
                      {moment(objectRide?.endDate).format("MMM Do YYYY, HH:mm")}
                    </Typography>
                  </Fragment>
                )}
              </Stack>
            </Stack>
            <Stack>
              {objectRide && (
                <Badge
                  colorScheme={
                    OBJECT_RIDE_MODES_COLOR[objectRide?.rideMode] || "error"
                  }
                >
                  <div>{OBJECT_RIDE_MODES(t)[objectRide?.rideMode]}</div>
                </Badge>
              )}
            </Stack>
          </Stack>
          <Stack mt={4}>
            <LocationTimeline timeLineItems={timeLineItems} />
          </Stack>
          <Stack mt={2} spacing={2}>
            <Stack
              spacing={2}
              direction={"row"}
              justifyContent={"space-between"}
            >
              {objectRide.status !== 1 && (
                <Stack
                  flex={1}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography variant={"subtitle2"}>
                    {t("typography.max")}:
                  </Typography>
                  <Badge
                    colorScheme={"info"}
                    icon={<SpeedIcon />}
                    textVariant={"small"}
                  >
                    {round(objectRide?.maxSpeed || 0, 1)} {"km/h"}
                  </Badge>
                </Stack>
              )}
              <Stack
                flex={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography variant={"subtitle2"}>
                  {t("typography.duration")}:
                </Typography>
                <Badge
                  colorScheme={"info"}
                  icon={<Clock />}
                  textVariant={"small"}
                >
                  {objectRide
                    ? getObjectRideTotalTimeInMinutes(objectRide)
                    : "0"}{" "}
                  {"min"}
                </Badge>
              </Stack>
            </Stack>
            <Stack
              spacing={2}
              direction={"row"}
              justifyContent={"space-between"}
            >
              {objectRide.status !== 1 && (
                <Stack
                  flex={1}
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography variant={"subtitle2"}>
                    {t("typography.average")}:
                  </Typography>
                  <Badge
                    colorScheme={"info"}
                    icon={<SpeedIcon />}
                    textVariant={"small"}
                  >
                    {round(objectRide?.averageSpeed || 0, 1)} {"km/h"}
                  </Badge>
                </Stack>
              )}
              <Stack
                flex={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography variant={"subtitle2"}>
                  {t("typography.distance")}:
                </Typography>
                <Badge
                  colorScheme={"info"}
                  icon={<MileageIcon />}
                  textVariant={"small"}
                >
                  {round(objectRide?.mileage || 0, 1)} {"km"}
                </Badge>
              </Stack>
            </Stack>
          </Stack>
          <Box mt={5} mr={2}>
            <KeyValueTable title={t("typography.details")} records={{}} />
            {/* mapping data bcz of same dates override issue in object */}
            {objectRideLocations?.map((item, index) => (
              <RecordsWrapper
                onClick={() => onLocationItemClick(item)}
                key={`${index}`}
              >
                <RecordKey>
                  {DateTime.fromISO(item?.date).toFormat("dd MMM yyyy, HH:mm")}
                </RecordKey>
                {/* @ts-ignore */}
                <RecordValue>{getParsedSpeed(item?.speed)}</RecordValue>
              </RecordsWrapper>
            ))}
          </Box>
        </Box>
      )}
      {objectRide && (
        <UpdateObjectRideForm
          open={openUpdateRide}
          onClose={() => setOpenUpdateRide(false)}
          objectRide={objectRide}
        />
      )}
    </SupportingDrawerLayout>
  );
};

export default ObjectRideDetailsDrawer;
