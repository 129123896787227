import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { Button, toast } from "@vilocnv/allsetra-core";
import BasicPageLayout from "components/layouts/BasicPageLayout";
import VerificationCodeInput from "components/ui/inputs/VerificationCodeInput/VerificationCodeInput";
import { ActivationCodeContainer } from "../activationPortal.styled";

//Data
import { useAppDispatch, useAppSelector } from "hooks";
import { getInstallationThunk, setVerificationCode } from "app/features";
import { selectActivationPortalState } from "app/data/selectors/activationPortalSelectors";
import { selectWhiteLabelsState } from "app/data/selectors";

const ActivationVerifyCode: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //Global States
  const { verificationCode, loading } = useAppSelector(
    selectActivationPortalState
  );
  const { whiteLabel } = useAppSelector(selectWhiteLabelsState);

  // Local State
  const [value, setValue] = useState("");

  const handleSubmitCode = async () => {
    const { type } = await dispatch(
      getInstallationThunk({ activationCode: verificationCode })
    );

    if (type === "activationPortal/getInstallationThunk/fulfilled") {
      navigate("/activation-portal/form");
    } else {
      toast.error("The activation code is incorrect");
    }
  };

  const handleOnComplete = (code: string) => {
    dispatch(setVerificationCode(code));
  };

  const handleOnChange = (val: string) => {
    setValue(val);
  };

  return (
    <BasicPageLayout
      title="Activatie code"
      subtitle="Voer alstublieft de activatie code in die u ontvangen heeft van uw dealer."
      whiteLabelBgImage={whiteLabel?.backgroundImageUrl}
    >
      <ActivationCodeContainer>
        <VerificationCodeInput
          onComplete={handleOnComplete}
          value={value}
          onChange={handleOnChange}
        />
        <div>
          <Button
            theme={theme}
            disabled={value.length !== 8}
            onClick={handleSubmitCode}
            loading={loading}
          >
            Submit
          </Button>
        </div>
      </ActivationCodeContainer>
    </BasicPageLayout>
  );
};

export default ActivationVerifyCode;
