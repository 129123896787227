import { FC, useEffect, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import { FormikHelpers } from "formik";
import { Table, Topbar, useDispatchOnParams } from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector, useReportCommonStates } from "hooks";
import { generateReportThunk, resetReports } from "app/features";
import {
  convertToNormalTime,
  reportTransformTimeForAPI,
} from "app/data/helpers";
import { EXPORTED_REPORT_HISTORY_TABLE_COLUMNS } from "app/data/constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { searchExportedReportThunk } from "app/features/exportedReportsHistory/exportedReportsHistoryActions";
import {
  selectDrawerSelectedAccountId,
  selectExportedReportsHistoryState,
} from "app/data/selectors";
import ExportedReportsExpendableRowCard from "components/cards/ReportCards/ExportedReportsHistoryRowCard/ExportedReportsHistoryRowCard";
import { organizeReportHistoryData } from "app/data/helpers/ReportHelpers/exportedReportsHistoryHelpers";

const DEFAULT_FILTER_VALUES = {
  group: [],
  object: [],
  objectType: [],
};

const ExportedReportsHistory: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";

  const { allExportedReportsHistory, loading, totalRecords } = useAppSelector(
    selectExportedReportsHistoryState
  );

  const {
    setFilterOpen,
    dateFormat,
    dateValues,
    setDateValues,
    localReport,
    setLocalReport,
    selectedLocalObjects,
  } = useReportCommonStates();

  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const tableColumns = useMemo(
    () => EXPORTED_REPORT_HISTORY_TABLE_COLUMNS(t),
    [t]
  );

  const formattedData = organizeReportHistoryData(localReport, dateFormat);

  const exportReportFiltersSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);

    const startDate = new Date(dateValues.startDate);
    const endDate = new Date(dateValues.endDate);

    const sameDate = startDate.toDateString() === endDate.toDateString();

    if (sameDate) {
      startDate.setHours(0, 0, 0);
      endDate.setHours(23, 59, 59);
      endDate.setDate(endDate.getDate() - 1);
    }

    const formattedStartDate = convertToNormalTime(startDate);
    const formattedEndDate = convertToNormalTime(endDate, true);

    const payload = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      ...values,
      reportType: 1,
      daysOfWeek: values.daysOfWeek.map(Number),
      startTime: reportTransformTimeForAPI(values.startTime + ":00", startDate),
      endTime: reportTransformTimeForAPI(values.endTime + ":00", endDate),
    };

    await dispatch(generateReportThunk(payload));

    formikHelpers.setSubmitting(false);
    setFilterOpen(false);
  };

  useDispatchOnParams(searchExportedReportThunk, {
    args: {
      accountId: drawerSelectedAccountId,
    },
    orderBy: [{ field: "id", ascending: true }],
    searchByField: "name",
  });

  useEffect(() => {
    dispatch(resetReports());
  }, []);

  useEffect(() => {
    setLocalReport(allExportedReportsHistory || []);
  }, [allExportedReportsHistory]);

  return (
    <Box>
      <Topbar
        breadcrumbTitle={"Reports"}
        breadcrumbRedirectTo={() => navigate('/dashboard/reports')}
        theme={theme}
        title={"Reports History"}
      />
      <Box mx={4}>
        <Table
          columns={tableColumns}
          data={formattedData}
          progressPending={loading}
          expandableRows
          expandOnRowClicked
          expan
          expandableRowsComponent={ExportedReportsExpendableRowCard}
          // pagination={false}
          paginationTotalRows={totalRecords}
          cellActions={[]}
          headerPadding={"0"}
          expandableRowExpanded={() => true}
        />
      </Box>
    </Box>
  );
};

export default ExportedReportsHistory;
