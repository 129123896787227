import { FC, Fragment, useState, useEffect, useRef, Dispatch } from "react";
import {
  MapObjectCard,
  ObjectMetadataTags,
  PageLoader,
  types,
} from "@vilocnv/allsetra-core";
import { GeozonesMapContainer } from "../GeozonesMap/GeozonesMap.styled";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  PolylineF,
} from "@react-google-maps/api";

// Data
import { useAppSelector, useMap } from "hooks";
import { isEmpty } from "lodash";
import { DateTime } from "luxon";
import {
  getBoundsZoomLevel,
  getCenterCords,
  getElementSize,
  getParsedMetaDataForSpeed,
} from "app/data/helpers";
import { selectGeozonesState } from "app/data/selectors";
import { googleMapStyles } from "app/data/constants";

export interface ObjectRideGoogleMapProps {
  objectRideLocations: Array<types.IObjectRideLocation>;
  objectRide: types.IObjectRide | null;
  selectedLocation: types.IObjectRideLocation | null;
  setSelectedLocation: Dispatch<any>;
  onLocationItemClick: (data: types.IObjectRideLocation) => void;
}

const ObjectRideGoogleMap: FC<ObjectRideGoogleMapProps> = ({
  objectRideLocations,
  objectRide,
  selectedLocation,
  setSelectedLocation,
  onLocationItemClick,
}) => {
  const alarmContainerRef = useRef<HTMLDivElement>();
  const [isLoaded, setMapLoaded] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState<number>(0);
  const { addressFromLatLng } = useAppSelector(selectGeozonesState);

  const { mapRef, loadError, onMapLoad, centerCoords } = useMap({
    disableNavigator: true,
  });

  //for first time only
  useEffect(() => {
    if (objectRideLocations.length > 0) {
      const zoomLevel = getBoundsZoomLevel(
        objectRideLocations,
        getElementSize(alarmContainerRef.current)
      );
      setZoomLevel(zoomLevel || 9);
    }
    return () => {
      setSelectedLocation(null);
    };
  }, [objectRideLocations, mapRef]);

  useEffect(() => {
    if (zoomLevel) setMapLoaded(true);
  }, [zoomLevel]);

  const icons = {
    start: require("../../../assets/icons/common/RideStartIcon.svg").default,
    stop: require("../../../assets/icons/common/RideStopIcon.svg").default,
    circle: require("../../../assets/icons/common/CircleIcon.svg").default,
  };

  const getIcon = (i: number, array: any[]) => {
    if (i === 0) {
      return icons.start;
    } else if (i + 1 === array.length) {
      return icons.stop;
    } else {
      return icons.circle;
    }
  };

  const getIconSize = (i: number, array: any[]) =>
    i === 0 || i + 1 === array.length ? 28 : 18;

  const getFormattedDate = (date: string) =>
    date ? DateTime.fromISO(date).toFormat("MMM dd, HH:mm") : "N/A";

  const renderMarkers = () => {
    if (isEmpty(objectRideLocations)) return null;
    else {
      return (
        <Fragment>
          {objectRideLocations.map((item: any, i: number, array: any[]) => (
            <MarkerF
              onClick={() => onLocationItemClick(item)}
              key={`${i}`}
              position={{
                lat: item?.latitude ?? 0,
                lng: item?.longitude ?? 0,
              }}
              icon={{
                url: getIcon(i, array),
                scaledSize: new window.google.maps.Size(
                  getIconSize(i, array),
                  getIconSize(i, array)
                ),
              }}
            >
              {selectedLocation?.uniqueId === item?.uniqueId && (
                <InfoWindowF
                  position={{
                    lat: selectedLocation?.latitude || 0,
                    lng: selectedLocation?.longitude || 0,
                  }}
                  onCloseClick={() => setSelectedLocation(null)}
                >
                  <MapObjectCard
                    label={objectRide?.object?.name || "N/A"}
                    id={objectRide?.object?.aNumber || "N/A"}
                    //@ts-ignore
                    address={addressFromLatLng || "Loading ..."}
                    time={getFormattedDate(item?.date)}
                  >
                    <ObjectMetadataTags
                      object={getParsedMetaDataForSpeed(item.speed)}
                      direction={"row"}
                    />
                  </MapObjectCard>
                </InfoWindowF>
              )}
            </MarkerF>
          ))}
        </Fragment>
      );
    }
  };

  const renderPolylineF = () => {
    if (isEmpty(objectRideLocations)) return null;

    const path = objectRideLocations.map((item) => ({
      lat: item.latitude ?? 0,
      lng: item.longitude ?? 0,
    }));

    return (
      <PolylineF
        path={path}
        options={{ strokeColor: "#488EEB", strokeWeight: 6 }}
      />
    );
  };

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return isLoaded ? (
    <GeozonesMapContainer ref={alarmContainerRef}>
      <GoogleMap
        center={getCenterCords(objectRideLocations, centerCoords)}
        zoom={zoomLevel}
        onLoad={onMapLoad}
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={{ styles: googleMapStyles }}
      >
        {renderMarkers()}
        {renderPolylineF()}
      </GoogleMap>
    </GeozonesMapContainer>
  ) : (
    <PageLoader />
  );
};

export default ObjectRideGoogleMap;
