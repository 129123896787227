import { Box, Stack, Typography } from "@mui/material";
import { TableColumn, types, Badge } from "@vilocnv/allsetra-core";

//
// TABLE HEADERS
//
export const ALL_USERS_TABLE_COLUMNS = (roles: any[], translator: any) => {
  const tableColumns: TableColumn<types.IUser>[] = [
    {
      name: translator("tableHeading.name", { ns: "tableHeadingsTranslation" }),
      selector: (row: types.IUser) => (
        <Stack gap={0.5}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "500",
              color: "#1D1F2B",
            }}
          >
            {row.firstName || ""} {row.lastName || ""}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "#646D7B",
            }}
          >
            {row?.email || ""}
          </Typography>
        </Stack>
      ),
      sortable: true,
    },
    {
      name: translator("tableHeading.role", { ns: "tableHeadingsTranslation" }),
      selector: (row: types.IUser) => {
        const selectedRole: any = roles.find((role) => role.id == row.role);

        return (
          <Badge colorScheme={roleColorScheme[selectedRole?.name] ?? "error"}>
            {selectedRole?.name || "None"}
          </Badge>
        );
      },
      sortable: true,
    },
    {
      name: translator("tableHeading.phone", {
        ns: "tableHeadingsTranslation",
      }),
      selector: (row: types.IUser) => row.phone,
    },
    {
      name: translator("tableHeading.linkedObjects", {
        ns: "tableHeadingsTranslation",
      }),
      cell: (row: types.IUser) =>
        row.objectsCount.length > 0
          ? row.objectsCount.map((item, index) => {
              return (
                <>
                  <Box sx={{ marginLeft: "3px" }}>{item.name}</Box>
                  {index !== row.objectsCount.length - 1 && ", "}
                </>
              );
            })
          : "-",
      sortable: true,
    },
    {
      name: translator("tableHeading.priority", {
        ns: "tableHeadingsTranslation",
      }),
      selector: (row: any) => row.priority,
      sortable: true,
    },
    {
      name: translator("tableHeading.alarmContact", {
        ns: "tableHeadingsTranslation",
      }),
      selector: (row: any) => (
        <Badge colorScheme={row.isAlarmContact ? "success" : "error"}>
          {row.isAlarmContact ? "Yes" : "No"}
        </Badge>
      ),
      sortable: true,
    },
  ];

  return tableColumns;
};

const roleColorScheme: any = {
  User: "success",
  Driver: "error",
  Manager: "success",
  Admin: "info",
  "View Only": "info",
};

export const callAlarmPriorities: any[] = Array.from(
  { length: 9 },
  (_, ind) => ({
    id: ind + 1,
    name: ind + 1,
  })
);
