import { createSlice } from "@reduxjs/toolkit";
import { generateReportThunk } from "./reportsActions";

export interface IReportsState {
  loading: boolean;
  report: Array<any>;
}

const initialState: IReportsState = {
  loading: false,
  report: [],
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    resetReports: (state) => {
      state.report = [];
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    // Get Reports Action Cases
    builder.addCase(generateReportThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(generateReportThunk.fulfilled, (state, action: any) => {
      state.report = action.payload.data;
      state.loading = false;
    });
    builder.addCase(generateReportThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export * from "./reportsActions";
export const { resetReports } = reportsSlice.actions;
export default reportsSlice.reducer;
