import { styled, Box } from "@mui/material";

export const IconBox = styled(Box)({
  width: "24px",
  height: "24px",

  ["> svg"]: { width: "24px", height: "24px" },
});

export const RecordsWrapper = styled(Box)({
  display: "flex",
  padding: "20px 0",
  borderBottom: "1px solid #EFF4FF",
  justifyContent: "space-between",
  "&:hover": {
    cursor: "pointer",
  },
});

export const RecordKey = styled(Box)({
  width: "232px",
  fontWeight: "500",
  fontSize: 14,
  lineHeight: "112%",
  color: "#76828F",
  marginRight: 24,
  textTransform: "capitalize",
});

export const RecordValue = styled(Box)({
  fontWeight: "300",
  fontSize: 14,
  lineHeight: "116%",
  color: "#1D1F2B",
  textAlign: "initial",
});
