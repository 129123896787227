import { FC } from "react";
import { IPointOfInterest } from "app/data/types";
import { CardContainer, TitleTypo } from "./PointOfInterestCard.styled";
import { Box, IconButton, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

export interface PointOfInterestCardProps {
  data: IPointOfInterest;
  onEditHandler: (data: IPointOfInterest) => void;
  onDeleteHandler: (data: IPointOfInterest) => void;
}

const PointOfInterestCard: FC<PointOfInterestCardProps> = ({
  data,
  onEditHandler,
  onDeleteHandler,
}) => {
  return (
    <CardContainer>
      <Stack
        direction={"row"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        sx={{ width: "100%", height: "100%" }}
      >
        <Box sx={{ flex: 1 }}>
          <TitleTypo>{data.name}</TitleTypo>
          <TitleTypo sx={{ color: "#76848F", paddingTop: "8px" }}>
            {data.address}
          </TitleTypo>
        </Box>
        <Box>
          <IconButton aria-label="edit" onClick={() => onEditHandler(data)}>
            <EditIcon sx={{ width: "16px", height: "16px" }} />
          </IconButton>
          <IconButton aria-label="delete" onClick={() => onDeleteHandler(data)}>
            <DeleteIcon sx={{ width: "16px", height: "16px" }} />
          </IconButton>
        </Box>
      </Stack>
    </CardContainer>
  );
};

export default PointOfInterestCard;
