import { FC, useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import {
  Button,
  ContentSectionLayout,
  DeleteConfirmationModal,
  toast,
} from "@vilocnv/allsetra-core";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import AddPointOfInterestForm from "components/forms/AddPointOfInterestForm/AddPointOfInterestForm";
import PointOfInterestCardListing from "components/cards/PointOfInterestCard/PointOfInterestCardListing";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import {
  deletePointOfInterestThunk,
  getAllPointsOfInterestThunk,
  resetGooglePredictatedPlaces,
} from "app/features";
import {
  selectDrawerSelectedAccountId,
  selectPointsOfInterestState,
} from "app/data/selectors";
import { IPointOfInterest } from "app/data/types";

const WorkAddressLabelsForm: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global State
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const { pointsOfInterest } = useAppSelector(selectPointsOfInterestState);

  // Local States
  const [open, setOpen] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editFormData, setEditFormData] = useState<IPointOfInterest | null>(
    null
  );

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  useEffect(() => {
    dispatch(getAllPointsOfInterestThunk(drawerSelectedAccountId || ""));
  }, [drawerSelectedAccountId]);

  const onAddressAddHandler = () => {
    dispatch(resetGooglePredictatedPlaces());
    setEditFormData(null);
    setOpen(true);
  };

  const onAddressEditHandler = (data: IPointOfInterest) => {
    dispatch(resetGooglePredictatedPlaces());
    setEditFormData(data);
    setOpen(true);
  };

  const onAddressDeleteHandler = (data: IPointOfInterest) => {
    setEditFormData(data);
    setOpenDeleteModal(true);
  };

  const confirmDeleteAddress = async () => {
    setIsSubmitting(true);

    const { type } = await dispatch(
      deletePointOfInterestThunk({
        accountId: drawerSelectedAccountId || "",
        itemId: editFormData?.uniqueId || "",
      })
    );

    if (type === "pointsOfInterest/deletePointOfInterestThunk/fulfilled") {
      setOpenDeleteModal(false);
      dispatch(getAllPointsOfInterestThunk(drawerSelectedAccountId || ""));
    } else {
      toast.error("Server side error occured");
    }

    setIsSubmitting(false);
  };

  return (
    <ContentSectionLayout
      title={t("titles.workAddressLabels")}
      subTitle={t("descriptions.workAddressLabels")}
    >
      <Box
        sx={{
          marginTop: {
            xs: "32px",
            md: "0px",
          },
        }}
      >
        <PointOfInterestCardListing
          records={pointsOfInterest ?? []}
          onEditHandler={onAddressEditHandler}
          onDeleteHandler={onAddressDeleteHandler}
        />
        <Box mt={3}>
          <Button
            type="button"
            color="primary"
            variant="text"
            startIcon={<AddIcon />}
            theme={theme}
            onClick={onAddressAddHandler}
          >
            Add Address
          </Button>
        </Box>
      </Box>
      <AddPointOfInterestForm
        open={open}
        onClose={() => setOpen(false)}
        editFormData={editFormData}
      />
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title="You are about to delete a work address"
        subTitle="Do you really want to delete this work address? This process cannot be undone."
        primaryBtnProps={{
          onClick: confirmDeleteAddress,
          loading: isSubmitting,
        }}
      />
    </ContentSectionLayout>
  );
};

export default WorkAddressLabelsForm;
