import { Table } from "@vilocnv/allsetra-core";
import { FC } from "react";
import MilageRegFinalData from "../MilageRegFinalData/MilageRegFinalData";
import { MILAGE_REGISTRATION_DATE_RENDERING } from "app/data/constants";

interface MilageRegExpendableRowCardProps {
  data: any;
}

const MilageRegExpendableRowCard: FC<MilageRegExpendableRowCardProps> = ({
  data,
}) => {
  return (
    <Table
      columns={MILAGE_REGISTRATION_DATE_RENDERING}
      data={data.records}
      expandableRows
      expandOnRowClicked
      expandableRowsComponent={MilageRegFinalData}
      pagination={false}
      noTableHead={true}
    />
  );
};

export default MilageRegExpendableRowCard;
