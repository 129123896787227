import { FC, Fragment, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { Button } from "@vilocnv/allsetra-core";
import BasicPageLayout from "components/layouts/BasicPageLayout";
import { CtaText } from "../activationPortal.styled";

// MSAL
import { UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { setFromDashboard } from "app/features";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectWhiteLabelsState } from "app/data/selectors";

const StartActivation: FC = () => {
  const theme = useTheme();
  const params = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  const { whiteLabel, loading } = useAppSelector(selectWhiteLabelsState);

  useEffect(() => {
    if (isAuthenticated || params.search.includes("fromDashboard")) {
      dispatch(setFromDashboard(true));
    } else {
      dispatch(setFromDashboard(false));
    }
  }, [params, isAuthenticated]);

  return (
    <BasicPageLayout
      title="Activation portal"
      subtitle="Activate your subscription after the device installation"
      whiteLabelBgImage={whiteLabel?.backgroundImageUrl}
      loading={loading}
    >
      <Fragment>
        <Button
          theme={theme}
          disabled={false}
          onClick={() => navigate("/activation-portal/verify-code")}
        >
          Start activation
        </Button>
        <UnauthenticatedTemplate>
          <CtaText>
            Already have an account?{" "}
            <span onClick={() => navigate("/")}>Log In</span>
          </CtaText>
        </UnauthenticatedTemplate>
      </Fragment>
    </BasicPageLayout>
  );
};

export default StartActivation;
