import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { types } from "@vilocnv/allsetra-core";
import { HubConnectionState } from "@microsoft/signalr";
import {
  getAllRolesThunk,
  getAllCurrenciesThunk,
  getAllIconsThunk,
  getAllCountriesThunk,
  getAllPaymentMethodsThunk,
  getGooglePlacesThunk,
  getIconsByCategoryThunk,
} from "./dashboardActions";

export interface IDashboardState {
  loading: boolean;

  isDrawerCollapsed: boolean;
  drawerSelectedAccountId: string | null;
  drawerSelectedAccountName: string | null;

  dashboardUserEmail: string | null;

  hubConnectionState: HubConnectionState | null;

  roles: Array<any>;
  currencies: Array<any>;
  countries: Array<any>;
  paymentMethods: Array<any>;
  icons: Array<any>;
  iconsByCategory: Array<any>;

  googlePredictatedPlacesLoading: boolean;
  googlePredictatedPlaces: Array<any>;

  mapFilters: types.IMapFilter;
}

const initialState: IDashboardState = {
  loading: false,

  isDrawerCollapsed: false,
  drawerSelectedAccountId: null,
  drawerSelectedAccountName: null,

  dashboardUserEmail: null,

  hubConnectionState: null,

  roles: [],
  currencies: [],
  countries: [],
  paymentMethods: [],
  icons: [],
  iconsByCategory: [],

  googlePredictatedPlacesLoading: false,
  googlePredictatedPlaces: [],

  mapFilters: {
    groups: [],
    objectTypes: [],
    keyId: [],
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDrawerCollapseState: (state, action: PayloadAction<boolean>) => {
      state.isDrawerCollapsed = action.payload;
    },
    setDrawerSelectedAccountId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.mapFilters = {
        groups: [],
        objectTypes: [],
        keyId: [],
      };
      state.drawerSelectedAccountId = action.payload;
    },
    setDashboardUserEmail: (state, action: PayloadAction<string | null>) => {
      state.dashboardUserEmail = action.payload;
    },
    setDrawerSelectedAccount: (
      state,
      action: PayloadAction<types.ISelectFieldValue>
    ) => {
      state.mapFilters = {
        groups: [],
        objectTypes: [],
        keyId: [],
      };
      // @ts-ignore
      state.drawerSelectedAccountId = action.payload.id;
      state.drawerSelectedAccountName = action.payload.name;
    },
    setHubConnectionState: (
      state,
      action: PayloadAction<HubConnectionState | null>
    ) => {
      state.hubConnectionState = action.payload;
    },
    setFilters: (state, action: PayloadAction<types.IMapFilter>) => {
      state.mapFilters = action.payload;
    },
    resetFilters: (state) => {
      state.mapFilters = initialState.mapFilters;
    },
    resetGooglePredictatedPlaces: (state) => {
      state.googlePredictatedPlacesLoading = false;
      state.googlePredictatedPlaces = [];
    },
  },
  extraReducers: (builder) => {
    // Get All Roles Action Cases
    builder.addCase(getAllRolesThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllRolesThunk.fulfilled, (state, action) => {
      state.roles = action.payload;
      state.loading = false;
    });

    builder.addCase(getAllRolesThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get All Currencies Action Cases
    builder.addCase(getAllCurrenciesThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllCurrenciesThunk.fulfilled, (state, action) => {
      state.currencies = action.payload;
      state.loading = false;
    });

    builder.addCase(getAllCurrenciesThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get All Icons Action Cases
    builder.addCase(getAllIconsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllIconsThunk.fulfilled, (state, action) => {
      state.icons = action.payload;
      state.loading = false;
    });

    builder.addCase(getAllIconsThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Icons By Category Action Cases
    builder.addCase(getIconsByCategoryThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getIconsByCategoryThunk.fulfilled, (state, action) => {
      state.iconsByCategory = action.payload;
      state.loading = false;
    });

    builder.addCase(getIconsByCategoryThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get All Countries Action Cases
    builder.addCase(getAllCountriesThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllCountriesThunk.fulfilled, (state, action) => {
      state.countries = action.payload;
      state.loading = false;
    });

    builder.addCase(getAllCountriesThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get All Payment Methods Action Cases
    builder.addCase(getAllPaymentMethodsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllPaymentMethodsThunk.fulfilled, (state, action) => {
      state.paymentMethods = action.payload;
      state.loading = false;
    });

    builder.addCase(getAllPaymentMethodsThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get All Payment Methods Action Cases
    builder.addCase(getGooglePlacesThunk.pending, (state) => {
      state.googlePredictatedPlacesLoading = true;
    });

    builder.addCase(getGooglePlacesThunk.fulfilled, (state, action) => {
      state.googlePredictatedPlaces = action.payload;
      state.googlePredictatedPlacesLoading = false;
    });

    builder.addCase(getGooglePlacesThunk.rejected, (state) => {
      state.googlePredictatedPlacesLoading = false;
    });
  },
});

export * from "./dashboardActions";
export const {
  setDrawerCollapseState,
  setDrawerSelectedAccountId,
  setDashboardUserEmail,
  setDrawerSelectedAccount,
  setHubConnectionState,
  setFilters,
  resetFilters,
  resetGooglePredictatedPlaces,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
