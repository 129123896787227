import { Table } from "@vilocnv/allsetra-core";
import { MILAGE_REG_INNER_TABLE_COLUMNS } from "app/data/constants";

import { FC } from "react";

interface MilageRegFinalDataProps {
  data: any;
}

const MilageRegFinalData: FC<MilageRegFinalDataProps> = ({ data }) => {
  const customStyles = {
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  return (
    <Table
      columns={MILAGE_REG_INNER_TABLE_COLUMNS}
      data={data.data}
      noTableHead={true}
      pagination={false}
      customStyles={customStyles}
    />
  );
};

export default MilageRegFinalData;
