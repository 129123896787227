import { FC } from "react";
import { useTheme } from "@mui/material";

const ActiveGroupsIcon: FC = (props) => {
  const theme = useTheme();

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.50017 7.91667H17.5002M7.40167 3.75H3.33333C2.87333 3.75 2.5 4.12333 2.5 4.58333V15.4167C2.5 15.8767 2.87333 16.25 3.33333 16.25H16.6667C17.1267 16.25 17.5 15.8767 17.5 15.4167V6.25C17.5 5.79 17.1267 5.41667 16.6667 5.41667H10.0983C9.5275 5.41667 9.00583 5.09417 8.75 4.58333C8.495 4.0725 7.97333 3.75 7.40167 3.75Z"
        stroke={theme.palette.primary.main}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ActiveGroupsIcon;
