import { FC, useMemo, useState } from "react";
import { toast, types } from "@vilocnv/allsetra-core";
import { Formik, FormikHelpers } from "formik";
import { isEmpty } from "lodash";

// CHILDREN
import InnerForm from "./children/InnerForm";

// DATA
import { useAppDispatch, useAppSelector } from "hooks";
import {
  objectDetailsFormatterForSettingsForm,
  objectDetailsValidationSchema,
  signalRGenerateSuccessToastMessage,
} from "app/data/helpers";
import {
  getSpecificObjectByIdThunk,
  postUpdateObjectThunk,
} from "app/features";
import { selectDrawerSelectedAccountId } from "app/data/selectors";
import { useParams } from "react-router-dom";
import { SignalRService } from "app/data/services";

interface Props {
  activeObject: types.IObject | null;
}

const ObjectSettingsForm: FC<Props> = ({ activeObject }) => {
  const dispatch = useAppDispatch();
  const params = useParams();

  const [objectsFormSubmitting, setObjectsFormSubmitting] = useState(false);

  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";

  const initialValues = useMemo(
    () =>
      !isEmpty(activeObject)
        ? objectDetailsFormatterForSettingsForm(activeObject)
        : {},
    [activeObject]
  );

  const saveChangesHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);
    setObjectsFormSubmitting(true);

    const { type } = await dispatch(
      postUpdateObjectThunk({
        accountId: drawerSelectedAccountId,
        data: {
          ...values,
          users: values.users.map((userId: string) => ({
            accountId: drawerSelectedAccountId,
            userId: userId,
          })),
        },
      })
    );

    if (type === "objects/postUpdateObjectThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (event.eventName === types.BackendEventsEnum.ObjectUpdatedEvent) {
          formikHelpers.setSubmitting(false);
          setObjectsFormSubmitting(false);
          toast.success(
            signalRGenerateSuccessToastMessage(
              event.eventName,
              "Object Settings",
              "updated"
            )
          );
          dispatch(
            getSpecificObjectByIdThunk({
              accountId: drawerSelectedAccountId,
              objectId: params.id ?? "",
            })
          );
        }
      });
    } else {
      formikHelpers.setSubmitting(false);
      setObjectsFormSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={objectDetailsValidationSchema}
      onSubmit={saveChangesHandler}
      enableReinitialize
      validateOnMount
    >
      <InnerForm
        activeObject={activeObject}
        objectsFormSubmitting={objectsFormSubmitting}
      />
    </Formik>
  );
};

export default ObjectSettingsForm;
