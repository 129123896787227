import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";

export function restructureData(data: any[]) {
  const tempData: any = {};

  if (!data?.length) return [];

  data?.forEach(
    (item: { details: { date: string; data: any[] }[]; groupedInfo: any }) => {
      const { groupedInfo, details } = item;
      const { serialNumber } = groupedInfo;

      details?.forEach((detail) => {
        const date = detail.date;
        const detailedInfo = detail.data;

        if (!tempData[serialNumber]) {
          tempData[serialNumber] = {
            serialNumber: serialNumber,
            name: groupedInfo.name,
            dataByDate: {},
            details,
            groupedInfo,
          };
        }

        if (!tempData[serialNumber].dataByDate[date]) {
          tempData[serialNumber].dataByDate[date] = {
            date: date,
            rides: 0,
            hourDurations: Array.from({ length: 24 }, (_, index) => ({
              hour: index,
              duration: 0,
            })),
          };
        }
        detailedInfo?.forEach((ride: { start: string; end: string }) => {
          const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const startHour = parseInt(
            new Date(ride.start).toLocaleString("en-US", {
              hour: "2-digit",
              hour12: false,
              timeZone: userTimeZone,
            })
          );
          const endHour = parseInt(
            new Date(ride.end).toLocaleString("en-US", {
              hour: "2-digit",
              hour12: false,
              timeZone: userTimeZone,
            })
          );

          const durationInMinutes =
            (new Date(ride.end).getTime() - new Date(ride.start).getTime()) /
            (1000 * 60);

          if (!isNaN(durationInMinutes)) {
            if (startHour === endHour) {
              tempData[serialNumber].dataByDate[date].hourDurations[
                startHour
              ].duration += Math.round(durationInMinutes);
            } else {
              const startMinutes = new Date(ride.start).getMinutes();
              const endMinutes = new Date(ride.end).getMinutes();
              const startDuration = 60 - startMinutes;
              const endDuration = endMinutes;
              const totalHoursCovered = durationInMinutes / 60;
              const startHourDuration =
                ((startDuration / 60) * durationInMinutes) / totalHoursCovered;
              const endHourDuration =
                ((endDuration / 60) * durationInMinutes) / totalHoursCovered;

              tempData[serialNumber].dataByDate[date].hourDurations[
                startHour
              ].duration += Math.round(startHourDuration);
              tempData[serialNumber].dataByDate[date].hourDurations[
                endHour
              ].duration += Math.round(endHourDuration);

              for (let hour = startHour + 1; hour < endHour; hour++) {
                tempData[serialNumber].dataByDate[date].hourDurations[
                  hour % 24
                ].duration += Math.round(
                  ((60 / 60) * durationInMinutes) / totalHoursCovered
                );
              }
            }
          }
        });

        tempData[serialNumber].dataByDate[date].rides += detailedInfo?.length;
      });
    }
  );

  return Object.keys(tempData).map((serialNumber) => ({
    serialNumber: tempData[serialNumber].serialNumber,
    name: tempData[serialNumber].name,
    dataByDate: Object.keys(tempData[serialNumber].dataByDate).map((date) => ({
      date: tempData[serialNumber].dataByDate[date].date,
      rides: tempData[serialNumber].dataByDate[date].rides,
      hourDurations: tempData[serialNumber].dataByDate[date].hourDurations,
    })),
    details: tempData[serialNumber].details,
    groupedInfo: tempData[serialNumber].groupedInfo,
  }));
}

export const localeDateOptions: {
  weekday: string;
  year: string;
  month: string;
  day: string;
} = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
export const generateFormattedGraphicalRidesPdf = async (
  doc: jsPDF,
  report: any[]
) => {
  for (let index = 0; index < report.length; index++) {
    const groupedInfo = report[index].groupedInfo;
    const { name, serialNumber, contactHours, operatingHours, ptoHours } =
      groupedInfo;

    if (index > 0) {
      doc.addPage();
    }

    for (let j = 0; j < report[index].dataByDate.length; j++) {
      const currentReport = report[index].dataByDate[j];
      const { date, rides } = currentReport;

      if (j > 0) {
        doc.addPage();
      }

      doc.setFontSize(10);
      doc.text(`Name: ${name}`, 10, 20);
      doc.text(`Serial Number: ${serialNumber}`, 10, 25);
      doc.text(`Total Rides: ${rides}`, 10, 30);
      doc.text(`Ignition Hours: ${contactHours?.toFixed(1)}`, 10, 35);
      doc.text(`Running Hours: ${operatingHours?.toFixed(1)}`, 10, 40);
      doc.text(`PTO Hours: ${ptoHours?.toFixed(1)}`, 10, 45);
      doc.text(`Date: ${date}`, 10, 50);

      const canvas = document.getElementById(`g-report-${index}-${j}`);
      if (canvas) {
        try {
          canvas.style.backgroundColor = "#fff";
          const dataUrl = await htmlToImage.toJpeg(canvas);

          doc.addImage(dataUrl, "JPEG", 7, 100, 280, 33);
        } catch (error) {
          console.error("Error converting chart to image:", error);
        }
      } else {
        console.error(`Canvas element with id g-report-${index} not found`);
      }
    }
  }
};

function initializeSerialData(serialNumber: string, groupedInfo: any) {
  return {
    serialNumber,
    name: groupedInfo.name,
    dataByDate: {},
    details: [],
    groupedInfo,
  };
}

function initializeDateData(date: string) {
  return {
    date,
    rides: 0,
    hourDurations: Array.from({ length: 24 }, (_, index) => ({
      hour: index,
      workingHours: 0,
      ignitionHours: 0,
      ptoHours: 0,
    })),
  };
}

function calculateHour(rideTime: string, userTimeZone: string) {
  return parseInt(
    new Date(rideTime).toLocaleString("en-US", {
      hour: "2-digit",
      hour12: false,
      timeZone: userTimeZone,
    })
  );
}

function updateRideDurations(
  tempData: any,
  serialNumber: string,
  date: string,
  ride: { start: string; end: string; sessionType: number },
  startHour: number,
  endHour: number,
  durationInMinutes: number
) {
  const totalHoursCovered = durationInMinutes / 60;
  const startMinutes = new Date(ride.start).getMinutes();
  const endMinutes = new Date(ride.end).getMinutes();
  const startDuration = 60 - startMinutes;
  const endDuration = endMinutes;
  const startHourDuration =
    ((startDuration / 60) * durationInMinutes) / totalHoursCovered;
  const endHourDuration =
    ((endDuration / 60) * durationInMinutes) / totalHoursCovered;

  tempData[serialNumber].dataByDate[date].hourDurations[startHour] =
    updateWorkingHoursDuration(
      tempData[serialNumber].dataByDate[date].hourDurations[startHour],
      ride.sessionType,
      startHourDuration
    );

  tempData[serialNumber].dataByDate[date].hourDurations[endHour] =
    updateWorkingHoursDuration(
      tempData[serialNumber].dataByDate[date].hourDurations[endHour],
      ride.sessionType,
      endHourDuration
    );

  for (let hour = startHour + 1; hour < endHour; hour++) {
    tempData[serialNumber].dataByDate[date].hourDurations[hour % 24] =
      updateWorkingHoursDuration(
        tempData[serialNumber].dataByDate[date].hourDurations[hour % 24],
        ride.sessionType,
        ((60 / 60) * durationInMinutes) / totalHoursCovered
      );
  }
}

function processRide(
  tempData: any,
  serialNumber: string,
  date: string,
  ride: { start: string; end: string; sessionType: number },
  userTimeZone: string
) {
  const startHour = calculateHour(ride.start, userTimeZone);
  const endHour = calculateHour(ride.end, userTimeZone);
  const durationInMinutes =
    (new Date(ride.end).getTime() - new Date(ride.start).getTime()) /
    (1000 * 60);

  if (!isNaN(durationInMinutes)) {
    if (startHour === endHour) {
      tempData[serialNumber].dataByDate[date].hourDurations[startHour] =
        updateWorkingHoursDuration(
          tempData[serialNumber].dataByDate[date].hourDurations[startHour],
          ride.sessionType,
          durationInMinutes
        );
    } else {
      updateRideDurations(
        tempData,
        serialNumber,
        date,
        ride,
        startHour,
        endHour,
        durationInMinutes
      );
    }
  }
}

function processRideDetail(
  tempData: any,
  serialNumber: string,
  detail: { date: string; data: any[] }
) {
  const date = detail.date;
  if (!tempData[serialNumber].dataByDate[date]) {
    tempData[serialNumber].dataByDate[date] = initializeDateData(date);
  }

  detail.data.forEach(
    (ride: { start: string; end: string; sessionType: number }) => {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      processRide(tempData, serialNumber, date, ride, userTimeZone);
    }
  );

  tempData[serialNumber].dataByDate[date].rides += detail.data.length;
}

export function restructureWorkingHoursData(data: any[]) {
  const tempData: any = {};

  if (!data?.length) return [];

  data.forEach(
    (item: { details: { date: string; data: any[] }[]; groupedInfo: any }) => {
      const { groupedInfo, details } = item;
      const { serialNumber } = groupedInfo;

      if (!tempData[serialNumber]) {
        tempData[serialNumber] = initializeSerialData(
          serialNumber,
          groupedInfo
        );
      }

      details.forEach((detail) =>
        processRideDetail(tempData, serialNumber, detail)
      );
    }
  );

  return Object.keys(tempData).map((serialNumber) => ({
    serialNumber: tempData[serialNumber].serialNumber,
    name: tempData[serialNumber].name,
    dataByDate: Object.keys(tempData[serialNumber].dataByDate).map((date) => ({
      date: tempData[serialNumber].dataByDate[date].date,
      rides: tempData[serialNumber].dataByDate[date].rides,
      hourDurations: tempData[serialNumber].dataByDate[date].hourDurations,
    })),
    details: tempData[serialNumber].details,
    groupedInfo: tempData[serialNumber].groupedInfo,
  }));
}

function updateWorkingHoursDuration(
  hourDuration: any,
  sessionType: number,
  duration: number
) {
  switch (sessionType) {
    case 1:
      hourDuration.workingHours += Math.round(duration);
      break;
    case 2:
      hourDuration.ignitionHours += Math.round(duration);
      break;
    case 3:
      hourDuration.ptoHours += Math.round(duration);
      break;
    default:
      break;
  }
  return hourDuration;
}

export const generateFormattedGraphicalWorkingHoursPdf = async (
  doc: jsPDF,
  report: any[]
) => {
  for (let index = 0; index < report.length; index++) {
    const groupedInfo = report[index].groupedInfo;
    const { name, serialNumber, contactHours, operatingHours, ptoHours } =
      groupedInfo;

    if (index > 0) {
      doc.addPage();
    }

    for (let j = 0; j < report[index].dataByDate.length; j++) {
      const currentReport = report[index].dataByDate[j];
      const { date, rides } = currentReport;

      if (j > 0) {
        doc.addPage();
      }

      doc.setFontSize(10);
      doc.text(`Name: ${name}`, 10, 20);
      doc.text(`Serial Number: ${serialNumber}`, 10, 25);
      doc.text(`Total Rides: ${rides}`, 10, 30);
      doc.text(`Ignition Hours: ${contactHours?.toFixed(1)}`, 10, 35);
      doc.text(`Running Hours: ${operatingHours?.toFixed(1)}`, 10, 40);
      doc.text(`PTO Hours: ${ptoHours?.toFixed(1)}`, 10, 45);
      doc.text(`Date: ${date}`, 10, 50);

      const canvas = document.getElementById(`g-wh-report-${index}-${j}`);
      if (canvas) {
        try {
          canvas.style.backgroundColor = "#fff";
          const dataUrl = await htmlToImage.toJpeg(canvas);

          doc.addImage(dataUrl, "JPEG", 3, 100, 290, 45);
        } catch (error) {
          console.error("Error converting chart to image:", error);
        }
      } else {
        console.error(`Canvas element with id g-wh-report-${index} not found`);
      }
    }
  }
};
