import { useState, useEffect, useCallback } from "react";
import { useGoogleMapsLoader } from "app/data/helpers";
import { DEFAULT_COORDINATES } from "app/data/constants";

interface Props {
  center?: {
    lat: number;
    lng: number;
  };
  disableNavigator?: boolean;
}

const useMap = ({ center, disableNavigator = false }: Props) => {
  const { isLoaded, loadError } = useGoogleMapsLoader();

  const [mapRef, setMapRef] = useState<google.maps.Map>();
  const [centerCoords, setCenterCoords] = useState(
    center || DEFAULT_COORDINATES
  );

  const panToCurrentPosition = useCallback(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const coords = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      setCenterCoords(coords);
      mapRef?.panTo(coords);
    });
  }, []);

  useEffect(() => {
    if (!disableNavigator) {
      panToCurrentPosition();
    }
  }, []);

  const onMapLoad = (map: any) => {
    setMapRef(map);

    if (!disableNavigator) {
      panToCurrentPosition();
    }
  };

  return {
    mapRef,
    isLoaded,
    loadError,
    centerCoords,
    onMapLoad,
    setCenterCoords,
  };
};

export default useMap;
