import { FC } from "react";
import { styled } from "@mui/material";

const ErrorBoundary: FC = () => {
  const HeadingErrorBoundary = styled("h1")({
    textAlign: "center",
    fontFamily: "Gilroy",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "116%",
    marginTop: "16px",
    animation: "fadeIn 3s linear infinite",

    "@keyframes fadeIn": {
      "0%": {
        opacity: 0,
      },
      "50%": {
        opacity: 1,
      },
      "100%": {
        opacity: 0,
      },
    },
  });

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "30px",
      }}
    >
      <img src={`${require("../assets/images/logo.png")}`} />
      <HeadingErrorBoundary>
        I believe something went wrong...
      </HeadingErrorBoundary>
      <a href="/">Go Back</a>
    </div>
  );
};

export default ErrorBoundary;
