import { FC, useEffect, useMemo, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { FormikHelpers } from "formik";
import { Table } from "@vilocnv/allsetra-core";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import TagFilterForm from "components/forms/Reports/TagFilterForm";
import TagPositionExpendableRowCard from "components/cards/ReportCards/TagPositionExpendableRowCard/TagPositionExpendableRowCard";
import ReportsTopbar from "components/common/reports/ReportsTopbar/ReportsTopbar";

// Data
import { useAppDispatch, useReportCommonStates } from "hooks";
import { generateReportThunk, resetReports } from "app/features";
import {
  convertToNormalTime,
  formatTimeWithDay,
  organizeData,
  reportTransformTimeForAPI,
} from "app/data/helpers";
import {
  TAG_POSITION_HEADERS,
  TAG_POSITION_TABLE_COLUMNS,
} from "app/data/constants";
import { useTranslation } from "react-i18next";
import ReportsFilterBar from "components/common/reports/ReportFiltersBar/ReportsFilterBar";

const DEFAULT_FILTER_VALUES = {
  group: [],
  object: [],
  objectType: [],
};

const TagPosition: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    objectTypes,
    objectTypesLoading,
    objectsLoading,
    accountGroups,
    report,
    reportsLoading,
    filterOpen,
    setFilterOpen,
    dateFormat,
    setDateFormat,
    dateValues,
    setDateValues,
    localReport,
    setLocalReport,
    handleObjectsSearch,
    selectedLocalObjects,
    handleOnChangeReportFilterForm,
  } = useReportCommonStates();

  const [filterValues, setFilterValues] = useState<any>(DEFAULT_FILTER_VALUES);

  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const tableColumns = useMemo(() => TAG_POSITION_TABLE_COLUMNS(t), [t]);

  useEffect(() => {
    dispatch(resetReports());
  }, []);

  useEffect(() => {
    setLocalReport(report);
  }, [report]);

  const formattedData = organizeData(localReport, dateFormat);

  const tagPositionFiltersSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);

    const startDate = new Date(dateValues.startDate);
    const endDate = new Date(dateValues.endDate);

    const sameDate = startDate.toDateString() === endDate.toDateString();

    if (sameDate) {
      startDate.setHours(0, 0, 0);
      endDate.setHours(23, 59, 59);
      endDate.setDate(endDate.getDate() - 1);
    }

    const formattedStartDate = convertToNormalTime(startDate);
    const formattedEndDate = convertToNormalTime(endDate, true);
    setFilterValues(values);

    const payload = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      ...values,
      reportType: 1,
      daysOfWeek: values.daysOfWeek.map(Number),
      startTime: reportTransformTimeForAPI(values.startTime + ":00", startDate),
      endTime: reportTransformTimeForAPI(values.endTime + ":00", endDate),
    };

    await dispatch(generateReportThunk(payload));

    formikHelpers.setSubmitting(false);
    setFilterOpen(false);
  };

  const tagPositionCSVData = () =>
    formattedData.flatMap((dayData) => {
      return dayData.records.map((record) => ({
        Time: formatTimeWithDay(record.date),
        Location: record.resolvedLocation ?? "-",
        Accuracy: `${record.accuracy}m`,
        Accurate: record.isAccurate.toString(),
        LatLong: `${record.latitude} ${record.longitude}`,
      }));
    });

  const generateTagPositionPDF = () => {
    const columns = ["Time", "Location", "Accuracy", "Accurate", "Lat Long"];
    const rows = formattedData.flatMap((dayData: any) =>
      dayData.records.map((record: any) => [
        formatTimeWithDay(record.date),
        record.resolvedLocation ?? "-",
        `${record.accuracy}m`,
        record.isAccurate.toString(),
        `${record.latitude} ${record.longitude}`,
      ])
    );
    const doc = new jsPDF();
    doc.text("Tag Position Report", 10, 10);
    autoTable(doc, {
      head: [columns],
      body: rows,
    });
    doc.save("Tag Position Report.pdf");
  };

  const handleDateChange = (value: any) => {
    setDateValues(value);
  };

  const handleDateRangeSubmit = () => {
    const startDate = convertToNormalTime(dateValues.startDate);
    const endDate = convertToNormalTime(dateValues.endDate, true);

    let updatedFilterValues = { ...filterValues };
    if (filterValues.daysOfWeek) {
      updatedFilterValues = {
        ...filterValues,
        daysOfWeek: filterValues.daysOfWeek.map(Number),
        startTime: reportTransformTimeForAPI(
          filterValues.startTime + ":00",
          dateValues.startDate
        ),
        endTime: reportTransformTimeForAPI(
          filterValues.endTime + ":00",
          dateValues.endDate
        ),
      };
    }

    const payload = {
      startDate,
      endDate,
      ...updatedFilterValues,
      reportType: 1,
    };

    dispatch(generateReportThunk(payload));
  };

  let formObjects = [...selectedLocalObjects];

  return (
    <Box>
      <ReportsTopbar dropdownTitle={t("reports.tagPosition")} />
      <Box mx={4}>
        <ReportsFilterBar
          setFilterOpen={setFilterOpen}
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
          handleDateChange={handleDateChange}
          dateValues={dateValues}
          handleDateRangeSubmit={handleDateRangeSubmit}
          dateRangeLoading={reportsLoading}
          exportHeaders={TAG_POSITION_HEADERS}
          generateCSVData={tagPositionCSVData}
          formattedData={formattedData}
          generatePDF={generateTagPositionPDF}
          hideScheduleButton={true}
        />
        <TagFilterForm
          fetchOnDebounce={handleObjectsSearch}
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          onSubmit={tagPositionFiltersSubmitHandler}
          groups={accountGroups}
          objectTypes={objectTypes}
          objects={formObjects}
          dataLoading={objectsLoading || objectTypesLoading}
          theme={theme}
          //@ts-ignore
          onChange={handleOnChangeReportFilterForm}
        />
        <Table
          columns={tableColumns}
          data={formattedData}
          progressPending={reportsLoading}
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={TagPositionExpendableRowCard}
          pagination={false}
        />
      </Box>
    </Box>
  );
};

export default TagPosition;
