import { createAsyncThunk } from "@reduxjs/toolkit";
import { ReportsService } from "app/data/services";

export const generateReportThunk = createAsyncThunk(
  "reports/generateReportThunk",
  async (data: any) => {
    try {
      const response = await ReportsService.generateReport(data);

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
