import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { toast } from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectDrawerSelectedAccountId,
  selectQueriedObjectsState,
} from "app/data/selectors";
import { getSpecificObjectByIdThunk } from "app/features";

const useActiveObjectById = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  // Global State
  const { specificObject, loading } = useAppSelector(selectQueriedObjectsState);

  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";

  const getSpecificObjectById = async () => {
    const { type } = await dispatch(
      getSpecificObjectByIdThunk({
        accountId: drawerSelectedAccountId,
        objectId: params.id ?? "",
      })
    );

    if (type === "objects/getSpecificObjectByIdThunk/rejected") {
      navigate(-1);
      toast.error("Object could not be found");
    }
  };

  useEffect(() => {
    if (isEmpty(params.id)) {
      navigate(-1);
      toast.error("Object could not be found");
    } else {
      getSpecificObjectById();
    }
  }, [params.id]);

  return { specificObject, loading };
};

export default useActiveObjectById;
