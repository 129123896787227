import { FC } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import StepScreenLayout from "components/layouts/StepScreenLayout/StepScreenLayout";
import InnerForm from "./children/InnerForm";
import {
  PersonalInfoInitialValues,
  PersonalInfoValidationSchema,
} from "app/data/helpers/fietsgestolenHelpers";
import {
  LogoWrapper,
  TrianglesWrapper,
  VectorSVGs,
} from "../FietsgestolenForms.styled";
import { AllsetraTriangles, PlusTwo } from "assets/icons";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectUserDataState } from "app/data/selectors";
import { IAddFietsPersonalInfo } from "app/data/types";
import { registerFietsThunk } from "app/features";

interface Props {
  gotoNextStep: () => void;
}

const PersonalInfoForm: FC<Props> = ({ gotoNextStep }) => {
  const dispatch = useAppDispatch();

  const { verificationCodeFietsgestolen, barcode, registerFietsLoading } =
    useAppSelector(selectUserDataState);

  const onSubmitHandler = async (
    value: IAddFietsPersonalInfo,
    formikHelpers: FormikHelpers<IAddFietsPersonalInfo>
  ) => {
    formikHelpers.setSubmitting(true);

    const formattedData = {
      verificationCode: verificationCodeFietsgestolen,
      barcode: barcode,
      ...value,
    };

    const { type } = await dispatch(registerFietsThunk(formattedData));

    if (type === "fietsgestolen/registerFietsThunk/fulfilled") {
      formikHelpers.resetForm();
      gotoNextStep();
    }
    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={PersonalInfoInitialValues}
      onSubmit={onSubmitHandler}
      validationSchema={PersonalInfoValidationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isValid }) => (
        <Form>
          <VectorSVGs>
            <PlusTwo style={{ position: "absolute", right: 0, top: "25vh" }} />
          </VectorSVGs>

          <LogoWrapper>
            <TrianglesWrapper>
              <AllsetraTriangles />
            </TrianglesWrapper>
          </LogoWrapper>

          <StepScreenLayout
            title="Activeren diefstalbeveiliging"
            subtitle="Voer de volgende gegevens in voor het afronden van de activatie."
            disabled={!isValid}
            onButtonSubmit={handleSubmit}
            buttonText="Activeren"
            isFietsgestolen={true}
            loading={registerFietsLoading}
          >
            <InnerForm />
          </StepScreenLayout>
        </Form>
      )}
    </Formik>
  );
};

export default PersonalInfoForm;
