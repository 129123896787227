import { FC } from "react";
import { Table } from "@vilocnv/allsetra-core";
import { WORKING_HOURS_DATA_LAYER } from "app/data/constants";

interface WorkingHoursExpendableRowCardProps {
  data: any;
}

const WorkingHoursExpendableRowCard: FC<WorkingHoursExpendableRowCardProps> = ({
  data,
}) => {
  const customStyles = {
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };
  return (
    <Table
      data={data.data}
      pagination={false}
      noTableHead={true}
      columns={WORKING_HOURS_DATA_LAYER}
      customStyles={customStyles}
    />
  );
};

export default WorkingHoursExpendableRowCard;
