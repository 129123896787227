import { FC, useEffect, useMemo } from "react";
import { types } from "@vilocnv/allsetra-core";
import Map from "components/maps/Map/Map";
import { ObjectMapContainer } from "../ObjectDetailsSection.styled";
import { useAppDispatch } from "hooks";
import { setActiveObjectAsMarker } from "app/features";

interface Props {
  activeObject: types.IObject | null;
}

const ObjectDetailsMap: FC<Props> = ({ activeObject }) => {
  const dispatch = useAppDispatch();

  const objectLocation = useMemo(
    () => ({
      lat: activeObject?.location?.latitude || 0,
      lng: activeObject?.location?.longitude || 0,
    }),
    [activeObject]
  );

  const objectRadius = useMemo(
    () =>
      activeObject?.location?.accuracy
        ? activeObject.location.accuracy > 15
          ? activeObject.location.accuracy
          : 0
        : 0,
    [activeObject]
  );

  useEffect(() => {
    if (activeObject) {
      dispatch(setActiveObjectAsMarker(activeObject));
    }
  }, []);

  return (
    <ObjectMapContainer>
      <Map
        center={objectLocation}
        zoom={10}
        radius={objectRadius}
        objects={[activeObject]}
        geozones={[objectLocation]}
        height="75vh"
        objectsMarker
        selectedObjectId={activeObject?.uniqueId}
      />
    </ObjectMapContainer>
  );
};

export default ObjectDetailsMap;
