export const WORKING_HOURS = [
  {
    dayOfWeek: 0,
    startTime: "00:00",
    endTime: "00:00",
  },
  {
    dayOfWeek: 1,
    startTime: "00:00",
    endTime: "00:00",
  },
  {
    dayOfWeek: 2,
    startTime: "00:00",
    endTime: "00:00",
  },
  {
    dayOfWeek: 3,
    startTime: "00:00",
    endTime: "00:00",
  },
  {
    dayOfWeek: 4,
    startTime: "00:00",
    endTime: "00:00",
  },
  {
    dayOfWeek: 5,
    startTime: "00:00",
    endTime: "00:00",
  },
  {
    dayOfWeek: 6,
    startTime: "00:00",
    endTime: "00:00",
  },
];
