import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, useTheme } from "@mui/material";
import {
  AllsetraTriangles,
  Circles,
  FietsgestolenLogo,
  Plus,
  SignedPDF,
} from "assets/icons";
import {
  AllsetraShieldSection,
  CircleWrapper,
  FietsgeMainWrapper,
  FietsgestolenContainer,
  FirstSection,
  FirstSectionDescription,
  FirstSectionTitle,
  InnerWrapper,
  LogoInnerWrapper,
  LogoWrapper,
  MainGridWrapper,
  PlusWrapper,
  ShieldSectionDescription,
  ShieldSectionTitle,
  TrianglesWrapper,
} from "../fietsgestolen.styled";
import { Button } from "@vilocnv/allsetra-core";
import { fietsgestolenPortalDynamicRoutes } from "app/data/helpers/fietsgestolenHelpers";
import { Helmet } from "react-helmet";

const StartReportTheft: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { pathnames } = fietsgestolenPortalDynamicRoutes();

  return (
    <React.Fragment>
      <Helmet>
        <title>FietsGestolen</title>
        <meta name="description" content="STOLEN BIKE? NOT FOR LONG" />
      </Helmet>
      <div>
        <PlusWrapper>
          <Plus />
        </PlusWrapper>
        <CircleWrapper>
          <Circles />
        </CircleWrapper>
      </div>
      <LogoWrapper>
        <TrianglesWrapper>
          <AllsetraTriangles />
        </TrianglesWrapper>
        <LogoInnerWrapper>
          <FietsgestolenLogo />
        </LogoInnerWrapper>
      </LogoWrapper>
      <FietsgestolenContainer>
        <FietsgeMainWrapper>
          <InnerWrapper>
            <MainGridWrapper container columnSpacing={{ xs: 6, md: 25 }}>
              <Grid item xs={12} sm={6} md={6}>
                <FirstSection>
                  <FirstSectionTitle>
                    STOLEN BIKE? <br></br>NOT FOR LONG
                  </FirstSectionTitle>
                  <FirstSectionDescription>
                    We vinden het heel vervelend dat jouw fiets is gestolen. Wij
                    doen er daarom alles aan om terug te halen wat van jou is.
                    <div>
                      Na een diefstalmelding gaat ons{" "}
                      <span style={{ color: theme.palette.primary.main }}>
                        recherchebureau ABS-Tracer
                      </span>{" "}
                      in nauwe samenwerking met de politie jouw gestolen fiets
                      opsporen. Dit doen ze al ruim 30 jaar met een{" "}
                      <span style={{ color: theme.palette.primary.main }}>
                        terugvindpercentage van 97%.
                      </span>{" "}
                    </div>
                  </FirstSectionDescription>
                </FirstSection>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <AllsetraShieldSection>
                  <SignedPDF />
                  <ShieldSectionTitle>Diefstal melden</ShieldSectionTitle>
                  <ShieldSectionDescription>
                    Meld jouw diefstal en wij nemen zo spoedig mogelijk contact
                    op voor verdere opvolging.
                  </ShieldSectionDescription>
                  <Button
                    theme={theme}
                    onClick={() => navigate(pathnames.reportTheftFormPathname)}
                    size="large"
                  >
                    Diefstal melden
                  </Button>
                </AllsetraShieldSection>
              </Grid>
            </MainGridWrapper>
          </InnerWrapper>
        </FietsgeMainWrapper>
      </FietsgestolenContainer>
    </React.Fragment>
  );
};

export default StartReportTheft;
