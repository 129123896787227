import { FC } from "react";
import { useTheme } from "@mui/material";

const ActiveObjectsIcon: FC = (props) => {
  const theme = useTheme();

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5905 16.1605H2.64246M14.5905 16.1605L15.3044 13.3042C15.4396 12.765 15.2633 12.1945 14.8481 11.8256L13.8438 10.9332M14.5905 16.1605H12.3502V17.2806C12.3502 17.4867 12.5175 17.654 12.7236 17.654H14.2171C14.4232 17.654 14.5905 17.4867 14.5905 17.2806V16.1605ZM2.64246 16.1605L1.92856 13.3042C1.7934 12.765 1.96963 12.1945 2.38483 11.8256L3.38921 10.9332M2.64246 16.1605H4.88272V17.2806C4.88272 17.4867 4.71544 17.654 4.50934 17.654H3.01583C2.80973 17.654 2.64246 17.4867 2.64246 17.2806V16.1605ZM3.38921 10.9332L4.13596 7.94622C4.13596 7.94622 6.37622 7.19947 8.61648 7.19947C10.8567 7.19947 13.097 7.94622 13.097 7.94622L13.8438 10.9332M3.38921 10.9332H13.8438M17.8578 11.307L18.5717 8.45072C18.7069 7.91156 18.5306 7.34104 18.1154 6.97214L17.1111 6.07977M17.8578 11.307H15.6176V12.4272C15.6176 12.6333 15.7848 12.8006 15.9909 12.8006H17.4844C17.6905 12.8006 17.8578 12.6333 17.8578 12.4272V11.307ZM17.8578 11.307L14.2171 11.265M5.65213 6.97214L6.65652 6.07977M6.65652 6.07977L7.40327 3.09276C7.40327 3.09276 9.64353 2.34601 11.8838 2.34601C14.124 2.34601 16.3643 3.09276 16.3643 3.09276L17.1111 6.07977M6.65652 6.07977H17.1111"
        stroke={theme.palette.primary.main}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ActiveObjectsIcon;
