import { Box, Button, styled } from "@mui/material";

export const LogoWrapper = styled(Box)({
  marginTop: 16,
  marginLeft: 40,
  marginRight: 24,
});

export const TrianglesWrapper = styled(Box)({
  textAlign: "right",
});

export const VectorSVGs = styled(Box)({
  position: "relative",
});

export const CardImage = styled("img")(({ theme }) => ({
  position: "absolute",
  left: "404px",
  top: "477px",
  [theme.breakpoints.down("sm")]: {
    right: "5%",
    bottom: "32%",
    width: "300px",
  },
}));

export const FietsgestolenArrow = styled("img")(({ theme }) => ({
  position: "absolute",
  left: "650px",
  top: "230px",
  [theme.breakpoints.down("sm")]: {
    right: "8%",
    bottom: "51%",
    width: "101px",
  },
}));

export const PersonalInfoFormContainer = styled(Box)(({ theme }) => ({
  width: "600px",
  minHeight: "500px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const ExtensionWrapper = styled(Box)({
  display: "flex",
  gap: "8px",
  "div:nth-child(1)": {
    width: "70%",
    div: {
      width: "100%",
    },
  },
  "div:nth-child(2)": {
    width: "30%",
    div: {
      width: "100%",
    },
  },
});

export const ExternalLinkBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  fontSize: "14px",
  marginTop: "10px !important",
  "& span": {
    color: "#845AFC",
    fontWeight: 500,
    lineHeight: "15.68px",
    marginRight: "5px",
  },
});

export const InsuranceButtonBox = styled(Box)({});

export const ButtonContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  gap: "8px",
  marginTop: "8px",
});

export const CustomButton = styled(Button)<{ isSelected?: boolean }>(
  ({ isSelected }) => ({
    borderColor: !isSelected ? "#EFF4FF" : "#00B2A3",
    textTransform: "capitalize",
    color: "#000",
    borderRadius: "6px",
    background: !isSelected ? "default" : "#F5FFFE",
    "& :hover": {
      borderColor: "#00B2A3",
    },
  })
);

export const InnerTitle = styled(Box)({
  fontSize: "16px",
  lineHeight: "16.8px",
  fontWeight: 500,
  letterSpacing: "-1%",
});
