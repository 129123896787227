import { FC } from "react";
import { Box } from "@mui/material";
import {
  ContentSectionLayout,
  FormikTimeFrameField,
} from "@vilocnv/allsetra-core";
import { useTranslation } from "react-i18next";

const WorkingHoursForm: FC = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <ContentSectionLayout
        title={t("titles.userWorkingHours")}
        subTitle={t("descriptions.userWorkingHours")}
      >
        <FormikTimeFrameField
          label={t("defaultWorkingHours.label", {
            ns: "formFieldsTranslation",
          })}
          typeName="workingHoursType"
          name="workingHours.workingHoursSchedule"
          translator={t}
        />
      </ContentSectionLayout>
    </Box>
  );
};

export default WorkingHoursForm;
