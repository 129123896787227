import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  listObjectsThunk,
  registerFietsThunk,
  registerTheftThunk,
  verifyBarcodeThunk,
  verifyCodeThunk,
  verifyEmailThunk,
} from "./fietsgestolenActions";

export interface IFietsgestolenState {
  loading: boolean;
  objectList: Array<any>;

  registerTheftLoading: boolean;

  emailLoading: boolean;
  userEmail: string | null;

  verificationCodeFietsgestolen: string | null;
  verificationCodeLoadingFietsgestolen: boolean;

  barcode: string | null;
  barcodeLoading: boolean;

  registerFietsLoading: boolean;
}

const initialState: IFietsgestolenState = {
  loading: false,
  objectList: [],

  registerTheftLoading: false,

  emailLoading: false,
  userEmail: null,

  verificationCodeLoadingFietsgestolen: false,
  verificationCodeFietsgestolen: null,

  barcode: null,
  barcodeLoading: false,

  registerFietsLoading: false,
};

const fietsgestolenSlice = createSlice({
  name: "fietsgestolen",
  initialState,
  reducers: {
    setUserEmail: (state, action: PayloadAction<string | null>) => {
      state.userEmail = action.payload;
    },
    setVerificationCodeFietsgestolen: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.verificationCodeFietsgestolen = action.payload;
    },
    setBarCode: (state, action: PayloadAction<string | null>) => {
      state.barcode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listObjectsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(listObjectsThunk.fulfilled, (state, action) => {
      state.objectList = action.payload;
      state.loading = false;
    });

    builder.addCase(listObjectsThunk.rejected, (state) => {
      state.loading = false;
    });

    //Email Loading States
    builder.addCase(verifyEmailThunk.pending, (state) => {
      state.emailLoading = true;
    });

    builder.addCase(verifyEmailThunk.fulfilled, (state) => {
      state.emailLoading = false;
    });

    builder.addCase(verifyEmailThunk.rejected, (state) => {
      state.emailLoading = false;
    });

    //VerificationCode Loading States
    builder.addCase(verifyCodeThunk.pending, (state) => {
      state.verificationCodeLoadingFietsgestolen = true;
    });

    builder.addCase(verifyCodeThunk.fulfilled, (state) => {
      state.verificationCodeLoadingFietsgestolen = false;
    });

    builder.addCase(verifyCodeThunk.rejected, (state) => {
      state.verificationCodeLoadingFietsgestolen = false;
    });

    //Register Theft Loading States
    builder.addCase(registerTheftThunk.pending, (state) => {
      state.registerTheftLoading = true;
    });

    builder.addCase(registerTheftThunk.fulfilled, (state) => {
      state.registerTheftLoading = false;
    });

    builder.addCase(registerTheftThunk.rejected, (state) => {
      state.registerTheftLoading = false;
    });

    //Barcode Loading States
    builder.addCase(verifyBarcodeThunk.pending, (state) => {
      state.barcodeLoading = true;
    });

    builder.addCase(verifyBarcodeThunk.fulfilled, (state) => {
      state.barcodeLoading = false;
    });

    builder.addCase(verifyBarcodeThunk.rejected, (state) => {
      state.barcodeLoading = false;
    });

    //Register Fiets Loading States
    builder.addCase(registerFietsThunk.pending, (state) => {
      state.registerFietsLoading = true;
    });

    builder.addCase(registerFietsThunk.fulfilled, (state) => {
      state.registerFietsLoading = false;
    });

    builder.addCase(registerFietsThunk.rejected, (state) => {
      state.registerFietsLoading = false;
    });
  },
});

export * from "./fietsgestolenActions";
export const { setUserEmail, setVerificationCodeFietsgestolen, setBarCode } =
  fietsgestolenSlice.actions;
export default fietsgestolenSlice.reducer;
