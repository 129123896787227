import * as Yup from "yup";
import { helpers } from "@vilocnv/allsetra-core";
import { isEmpty, omit } from "lodash";
import { WORKING_HOURS } from "../constants/settingsConstants";

const getUserRole = (uniqueId: string, userRoles: any[]) => {
  const userRole = userRoles.find((item: any) => item?.id === uniqueId);
  return userRole?.name || "Super Admin";
};

export const userSettingsFormatterForForm = (
  settings: any,
  account: any,
  safetyQuestions: any,
  userRoles: any[]
) => {
  if (isEmpty(settings)) return {};

  const foundQuestion = safetyQuestions?.find(
    (question: any) => question.uniqueId === settings.securityQuestionId
  );

  const removedUnwantedKeys = omit(settings, [
    "accountUsers",
    "created",
    "createdBy",
    "deleted",
    "deletedBy",
    "isDeleted",
    "lastUpdated",
    "updatedBy",
    "notifications",
  ]);

  const formattedObject = {
    ...removedUnwantedKeys,
    uniqueId: settings?.uniqueId,
    role: getUserRole(account?.role, userRoles),
    firstName: settings?.firstName,
    lastName: settings?.lastName,
    phone: settings?.phone,
    languageId: settings?.languageId,
    isEmailNotificationEnabled: settings?.isEmailNotificationEnabled ?? false,
    securityQuestionId: settings?.securityQuestionId ?? "",
    securityQuestionResponse:
      settings?.securityQuestionResponse ?? "Select Answer",
    securityQuestion: foundQuestion?.question ?? "Select Question",
    workingHoursType: settings?.workingHoursType ?? "FULLWEEK",
    workingHours: {
      workingHoursSchedule: isEmpty(settings?.workingHours)
        ? WORKING_HOURS
        : settings?.workingHours,
    },
  };

  return formattedObject;
};

export const userSettingsValidationSchema = Yup.object({
  firstName: Yup.string().trim().required().label("First name"),
  lastName: Yup.string().trim().required().label("Last name"),
  phone: Yup.string().required().label("Phone number"),
  languageId: Yup.number().required().label("Language"),
  isEmailNotificationEnabled: Yup.boolean().label("Email Notification"),
  workingHoursType: Yup.string().nullable().label("Working Hours Type"),
  workingHours: Yup.object({
    workingHoursSchedule: helpers.workingHoursValidationSchema,
  }),
});
