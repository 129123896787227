import * as Yup from "yup";
import { IResetPassword, INewPassword } from "app/data/types";

export const resetPasswordInitialValues: IResetPassword = { email: "" };

export const resetPasswordValidationSchema: Yup.Schema<IResetPassword> =
  Yup.object().shape({
    email: Yup.string().email().required().label("Email"),
  });

export const newPasswordInitialValues: INewPassword = {
  newPassword: "",
  confirmPassword: "",
};

export const newPasswordValidationSchema: Yup.Schema<INewPassword> =
  Yup.object().shape({
    newPassword: Yup.string().trim().required().label("New Password"),
    confirmPassword: Yup.string()
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.newPassword === value;
      })
      .label("Confirm Password")
      .required(),
  });
