import { createSlice } from "@reduxjs/toolkit";
import { types } from "@vilocnv/allsetra-core";
import { getObjectTypesByAccountThunk } from "./objectTypesActions";

export interface IObjectTypesState {
  loading: boolean;
  objectTypes: Array<types.IObjectType>;
}

const initialState: IObjectTypesState = {
  loading: false,
  objectTypes: [],
};

const objectTypesSlice = createSlice({
  name: "objectTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get All Object Types With Account ID
    builder.addCase(getObjectTypesByAccountThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getObjectTypesByAccountThunk.fulfilled, (state, action) => {
      state.objectTypes = action.payload;
      state.loading = false;
    });

    builder.addCase(getObjectTypesByAccountThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export * from "./objectTypesActions";
export const {} = objectTypesSlice.actions;

export default objectTypesSlice.reducer;
