import {
  HubConnectionBuilder,
  HubConnection,
  HubConnectionState,
} from "@microsoft/signalr";
import { store } from "app/store";
import { AzureMsal } from "./index";
import { types } from "@vilocnv/allsetra-core";
import { Subject } from "rxjs";

export const signalRConnectionEvent = new Subject();

class SignalRService {
  static hubConnection: HubConnection | null = null;
  static hubConnectionState: HubConnectionState | null = null;

  static createHubConnection = async () => {
    if (this.hubConnection?.state === "Connected") return;

    const accessTokenResponse = await AzureMsal.acquireToken();

    if (!accessTokenResponse) {
      console.error("Access token is required for connection with SignalR.");
      return;
    }

    const userEmail =
      store.getState().rootReducer.dashboardReducer.dashboardUserEmail;

    const newConnection = new HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_API_BASE_LIVE_UPDATE_URL}/?source=adminportal&user=${userEmail}`,
        {
          accessTokenFactory: () => accessTokenResponse.idToken ?? "",
          headers: {
            "X-Subscription": `${process.env.REACT_APP_API_HEADER_SUBSCRIPTION}`,
          },
        }
      )
      .withAutomaticReconnect()
      .build();

    newConnection
      .start()
      .then(() => {
        console.log("Connected Signalr Successfully!");

        this.hubConnection = newConnection;

        signalRConnectionEvent.next("Connected");
      })
      .catch((e: any) => {
        console.log("Connection failed: ", e);

        signalRConnectionEvent.next("Disconnected");
      });
  };

  static registerOnServerEvent = (
    eventName: types.BackendEventsEnum,
    callback: (event: any) => void
  ) => {
    this.hubConnection?.on("EventRaised", (event) => {
      if (event.eventName === eventName) {
        callback(event);
      }
    });
  };
}

export default SignalRService;