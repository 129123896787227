import { FC } from "react";
import { Box } from "@mui/material";
import {
  ContentSectionLayout,
  AddressFormSection,
} from "@vilocnv/allsetra-core";
import { useTranslation } from "react-i18next";

const LogisticsForm: FC = () => {
  const { t } = useTranslation();

  return (
    <ContentSectionLayout
    title={t("titles.logistics")}
    subTitle={t("descriptions.logistics")}
    >
      <Box
        sx={{
          marginTop: {
            xs: "32px",
            md: "0px",
          },
        }}
      >
        <AddressFormSection
          title={t("titles.shippingAddress")}
          parentKey="shippingAddress"
          hideDivider
          countries={[]}
          countriesLoading={false}
          translator={t}
        />
      </Box>
    </ContentSectionLayout>
  );
};

export default LogisticsForm;
