// @ts-nocheck
import { FC } from "react";
import { useStepDrawerController } from "hooks";
import StepperDrawerLayout, {
  IStepItem,
} from "components/layouts/StepperDrawerLayout/StepperDrawerLayout";

// STEPS
import ActivateVerificationForm from "components/forms/FietsgestolenForms/ActivateVerificationForm";
import BarcodeVerificationForm from "components/forms/FietsgestolenForms/BarcodeVerificationForm";
import PersonalInfoForm from "components/forms/FietsgestolenForms/PersonalInfoForm";
import ActivateTheftSuccessSection from "components/sections/FietsgestolenSections/ActivateTheftSuccessSection";
import ActivateCodeVerificationForm from "components/forms/FietsgestolenForms/ActivateCodeVerificationForm";

const INITIAL_STEPS: Array<IStepItem> = [
  { index: 0, name: "E-mail verificatie", isActive: true, isCompleted: false },
  { index: 1, name: "E-mail code", isActive: false, isCompleted: false },
  {
    index: 2,
    name: "Barcode verificatie",
    isActive: false,
    isCompleted: false,
  },
  {
    index: 3,
    name: "Persoonlijke gegevens",
    isActive: false,
    isCompleted: false,
  },
  { index: 4, name: "Succes", isActive: false, isCompleted: false },
];

const SecureBikeForm: FC = () => {
  const { steps, activeStepIndex, onCompletedStepClick, gotoNextStep } =
    useStepDrawerController(INITIAL_STEPS);

  return (
    <StepperDrawerLayout
      size={"extraLarge"}
      appLogoSize={"extraLarge"}
      isMobileDrawerOpen={false}
      appLogoType={"jouwfiets"}
      steps={steps}
      onCompletedStepClick={onCompletedStepClick}
    >
      {activeStepIndex === 0 && (
        <ActivateVerificationForm gotoNextStep={gotoNextStep} />
      )}
      {activeStepIndex === 1 && (
        <ActivateCodeVerificationForm gotoNextStep={gotoNextStep} />
      )}
      {activeStepIndex === 2 && (
        <BarcodeVerificationForm gotoNextStep={gotoNextStep} />
      )}
      {activeStepIndex === 3 && (
        <PersonalInfoForm gotoNextStep={gotoNextStep} />
      )}
      {activeStepIndex === 4 && (
        <ActivateTheftSuccessSection gotoNextStep={gotoNextStep} />
      )}
    </StepperDrawerLayout>
  );
};

export default SecureBikeForm;
