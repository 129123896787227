import * as Yup from "yup";
import {
  IAccountInformation,
  IActivationInformation,
  IActivationPortal,
} from "../types";
import {
  alphanumericRegex,
  cityRegex,
  emailRegExp,
  nameRegex,
  numberRegex,
  passowrdRegex,
  phoneRegExp,
  safetyQsAnsRegex,
  streetRegex,
  vatRegex,
} from "./commonHelpers";
import {
  SUBSCRIPTION_TYPES,
  currencySigns,
} from "../constants/activationPortalConstants";
import { isEmpty, sortBy } from "lodash";
import { ObjectFieldsEnum } from "./objectsDynamicFieldsHelpers";

const TAX_RATE = 0.21;

export const activationObjectDataFormatter = (data: Array<any>) => {
  let objectBrand;
  let objectModel;
  let licensePlate;

  data.map((data: any) => {
    data.field === "Brand"
      ? (objectBrand = data.value)
      : data.field === "Model"
      ? (objectModel = data.value)
      : (licensePlate = data.value);
  });

  return { objectBrand, objectModel, licensePlate };
};

export const getSecurityQuestion = (
  securityQuestions: Array<any>,
  safeQuestionId: string
) => {
  const securityQuestion =
    securityQuestions &&
    securityQuestions?.find((question) => question?.uniqueId === safeQuestionId)
      ?.question;
  return { securityQuestion };
};

//installation
export const installationInitialValues = {
  installationId: "",
  objectBrand: "",
  objectModel: "",
  licensePlate: "",
};

export const installationsValidationSchema = Yup.object({
  installationId: Yup.string().required().label("Installation ID"),
  objectBrand: Yup.string().trim().label("Object Brand"),
  objectModel: Yup.string().trim().label("Object Model"),
  licensePlate: Yup.string().trim().label("License plate"),
});

export const installationDataFormatter = (installation: any) => {
  const { licensePlate, objectBrand, objectModel } =
    activationObjectDataFormatter(installation.metadata);

  const payload = {
    installationId: installation.installationId,
    objectBrand,
    objectModel,
    licensePlate,
  };

  return payload;
};

//information
export const informationInitialValues: IActivationInformation = {
  companyInfo: {
    name: " ",
    street: "",
    number: "",
    postCode: "",
    city: "",
    country: "Netherlands (the)",
    vatNumber: "",
  },
  userInfo: {
    prefix: "",
    firstName: "",
    lastName: "",
    phoneNumber1: "",
    phoneNumber2: "",
    email: "",
    safeQuestionId: "",
    safeQuestionAnswer: "",
    password: "",
    confirmPassword: "",
  },

  alarmPersons: [],
};

//
export const accountFormInitialValues: IAccountInformation = {
  accountId: "",
};

export const informationValidationSchema = Yup.object({
  companyInfo: Yup.object({
    name: Yup.string().required().label("Company name"),
    street: Yup.string()
      .matches(streetRegex, "Invalid format")
      .trim()
      .required()
      .label("Street"),
    number: Yup.string()
      .trim()
      .matches(numberRegex, "Invalid format")
      .required()
      .label("House number"),
    postCode: Yup.string()
      .matches(alphanumericRegex, "Invalid format")
      .required()
      .label("Postcode"),
    city: Yup.string()
      .matches(cityRegex, "Invalid format")
      .required()
      .label("City"),
    extension: Yup.string()
      .matches(alphanumericRegex, "Invalid format")
      .trim()
      .label("Country"),
    country: Yup.string().required().trim().label("Country"),
    vatNumber: Yup.string()
      .matches(vatRegex, "Invalid format")
      .trim()
      .label("Vat number"),
  }),

  userInfo: Yup.object({
    prefix: Yup.string().trim().label("Prefix"),
    firstName: Yup.string()
      .matches(nameRegex, "First name is not valid")
      .required()
      .label("Fist Name"),
    lastName: Yup.string()
      .matches(nameRegex, "Last name is not valid")
      .trim()
      .required()
      .label("Last Name"),
    phoneNumber1: Yup.string()
      .trim()
      .required()
      .label("Phone number 1")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone number is too short")
      .max(14, "Phone number is too long"),
    phoneNumber2: Yup.string()
      .trim()
      .label("Phone number 2")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone number is too short")
      .max(14, "Phone number is too long"),
    email: Yup.string()
      .required()
      .label("Email")
      .matches(emailRegExp, "Invalid Email Format"),
    password: Yup.string()
      .matches(
        passowrdRegex,
        "Minimum 8 characters, must include uppercase, lowercase, number & special character"
      )
      .trim()
      .required()
      .label("Password"),
    confirmPassword: Yup.string()
      .trim()
      .required()
      .matches(
        passowrdRegex,
        "Minimum 8 characters, must include uppercase, lowercase, number & special character"
      )
      .label("Confirm Password")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
    safeQuestionId: Yup.string().trim().required().label("Safety Question"),
    safeQuestionAnswer: Yup.string()
      .trim()
      .matches(safetyQsAnsRegex, "Invalid format")
      .required()
      .label("Safety question answer"),
  }),

  alarmPersons: Yup.array().of(
    Yup.object({
      firstName: Yup.string().required().label("Fist Name"),
      lastName: Yup.string().trim().required().label("Last Name"),
      phoneNumber1: Yup.string()
        .trim()
        .required()
        .label("Phone number 1")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Phone number is too short")
        .max(14, "Phone number is too long"),
      phoneNumber2: Yup.string()
        .trim()
        .label("Phone number 2")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "Phone number is too short")
        .max(14, "Phone number is too long"),
      safeQuestionId: Yup.string().trim().required().label("Safety Question"),
      safeQuestionAnswer: Yup.string()
        .trim()
        .required()
        .label("Safety question answer"),
      email: Yup.string()
        .trim()
        .required()
        .label("Email")
        .matches(emailRegExp, "Invalid Email Format"),
      prefix: Yup.string().trim().label("Prefix"),
    })
  ),
});

//
export const accountFormSchema = Yup.object({
  accountId: Yup.string().trim().required().label("accountId"),
});

//Subscription

export const subscriptionDataFormatter = (installation: any) => {
  const { licensePlate, objectBrand, objectModel } =
    activationObjectDataFormatter(installation.metadata);

  const payload = {
    objectBrand,
    objectModel,
    licensePlate,
    deviceId: installation?.deviceInfo?.serialNumber || "",
    deviceType: installation?.deviceInfo?.deviceTypeName || "",
    serviceTypeName: installation?.serviceInfo?.name || "",
    serviceTypeDetails: installation?.serviceInfo?.description || "",
  };

  return payload;
};

export const getPricePerYear = (valuePerMonth: number, currency: string) => {
  let valuePerMonthStr = valuePerMonth.toFixed(2);
  valuePerMonthStr = valuePerMonthStr.replace(".", ",");

  return `${currencySigns[currency]}${valuePerMonthStr}`;
};

export const getTaxInclusivePrice = (
  valuePerMonth: number,
  currency: string
) => {
  const taxPrice = valuePerMonth * (1 + TAX_RATE);

  let taxPriceStr = taxPrice.toFixed(2);
  taxPriceStr = taxPriceStr.replace(".", ",");

  return `${currencySigns[currency]}${taxPriceStr}`;
};

export const getPricePerMonthText = (
  months: number,
  subscriptionType: string
) => {
  if (SUBSCRIPTION_TYPES[subscriptionType]) {
    return `Contract duration is ${months} ${
      months === 1 ? "month" : "months"
    }, billed ${SUBSCRIPTION_TYPES[subscriptionType]}.`;
  } else {
    return `Invalid subscription type: ${subscriptionType}`;
  }
};

export const specificSubscriptionPlanInformation = ({
  subscriptions,
  subscriptionId,
}: {
  subscriptions: Array<any>;
  subscriptionId: string;
}) => {
  const subscriptionPlan = subscriptions.find(
    (subscription: any) => subscription.uniqueId === subscriptionId
  );

  const pricePerYear = getPricePerYear(
    subscriptionPlan.valuePerMonth,
    subscriptionPlan.currency
  );

  return { pricePerYear, subscriptionPlan };
};

//Payment
export const paymentInitialValues = {
  accountHolderName: "",
  bankAccountNumber: "",
  authorizeAllsetra: false,
  termsAndConditions: false,
  privacyPolicy: false,
  directDebit: false,
};

/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
const isValidIBANNumber = (input: string) => {
  const CODE_LENGTHS: any = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
  };
  let iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ""), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits;
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return String(letter.charCodeAt(0) - 55);
  });
  // final check
  return mod97(digits) === 1;
};

const mod97 = (value: any) => {
  var checksum = value.slice(0, 2),
    fragment;
  for (var offset = 2; offset < value.length; offset += 7) {
    fragment = String(checksum) + value.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
};

Yup.addMethod<Yup.StringSchema<string>>(
  Yup.string,
  "iban",
  function (errorMessage) {
    return this.test(`test-iban`, errorMessage, isValidIBANNumber);
  }
);

export const paymentValidationSchema = Yup.object({
  accountHolderName: Yup.string()
    .trim()
    .required()
    .label("Account holder name"),
  bankAccountNumber: Yup.string()
    .trim()
    .required()
    //@ts-ignore
    .iban("Invalid IBAN")
    .label("Bank Account Number (IBAN)"),
  authorizeAllsetra: Yup.bool()
    .label("Checkbox")
    .oneOf([true], "Please accept the conditions"),
  termsAndConditions: Yup.bool()
    .label("Checkbox")
    .oneOf([true], "Please accept the conditions"),
  privacyPolicy: Yup.bool()
    .label("Checkbox")
    .oneOf([true], "Please accept Privacy Policy"),
  directDebit: Yup.bool()
    .label("Checkbox")
    .oneOf([true], "Please accept Payment policy"),
});

//Overview
export const overviewInitialValues = {
  conditions: false,
};

export const overviewValidationSchema = Yup.object({
  conditions: Yup.bool()
    .label("Conditions")
    .oneOf([true], "Please accept the conditions"),
});

export const overviewInformationStepData = (information: IActivationPortal) => {
  const address = `${information?.companyInfo?.street} ${information?.companyInfo?.number}, 
  ${information?.companyInfo?.postCode} ${information?.companyInfo?.city}, ${information?.companyInfo?.country}`;

  const contactName = `${information.userInfo?.prefix || ""} ${
    information.userInfo?.firstName
  } ${information.userInfo?.lastName}`;

  return { address, contactName };
};

export const overviewInformationAlarmPersonData = (
  prefix: string,
  firstName: string,
  lastName: string
) => {
  const alarmPersonName = `${prefix || ""} ${firstName} ${lastName}`;

  return { alarmPersonName };
};

export const transformObjectForActivationInfoTable = (
  object: any | null
): any => {
  if (!object) return {};

  const data: any = {};

  const metadata = sanitizeActivationPortalMetadataForDynamicFields(object);

  metadata.length
    ? metadata.map((meta: any) => (data[meta.field] = meta.value))
    : (data["data"] = "No metadata available");

  return data;
};

export const sanitizeActivationPortalMetadataForDynamicFields = (
  object: any
) => {
  const metadata = object.metadata;

  if (isEmpty(metadata)) return [];

  return sortBy(
    metadata.filter(
      (item: any) =>
        item.fieldInfo.customerPortal === ObjectFieldsEnum.isDynamicField ||
        item.fieldInfo.customerPortal === ObjectFieldsEnum.isBoth
    ),
    [(o) => o.field.label]
  );
};
