import { FC, useEffect, useState, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import {
  Table,
  useDispatchOnParams,
  ActivateKeyForm,
  types,
  DeleteConfirmationModal,
  Topbar,
  toast,
  utils,
} from "@vilocnv/allsetra-core";
import { FormikHelpers } from "formik";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getAccountKeysByQueryThunk,
  activateKeyThunk,
  deactivateKeyThunk,
  getAvailableKeysByAccountThunk,
} from "app/features";
import {
  selectAccountKeysState,
  selectDrawerSelectedAccountState,
  selectAccountAvailableKeysState,
} from "app/data/selectors";
import { ACCOUNT_KEYS_TABLE_COLUMNS } from "app/data/constants";
import { useTranslation } from "react-i18next";
import { SignalRService } from "app/data/services";

const KeysManager: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global State
  const { drawerSelectedAccountId: accountId } = useAppSelector(
    selectDrawerSelectedAccountState
  );
  const { accountKeys, totalRecords, loading } = useAppSelector(
    selectAccountKeysState
  );
  const { accountAvailableKeys } = useAppSelector(
    selectAccountAvailableKeysState
  );

  const { t } = useTranslation([
    "translation",
    "tableHeadingsTranslation",
    "formFieldsTranslation",
  ]);

  const tableColumns = useMemo(() => ACCOUNT_KEYS_TABLE_COLUMNS(t), [t]);

  useDispatchOnParams(getAccountKeysByQueryThunk, {
    args: { accountId: accountId || "" },
    searchByField: "keyId",
  });

  useEffect(() => {
    if (accountId) {
      dispatch(getAvailableKeysByAccountThunk(accountId || ""));
    }
  }, [accountId]);

  // Local State
  const [selectedKeyId, setSelectedKeyId] = useState<string | null>(null);
  const [activateKeyModal, setActivateKeyModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isSubmittingRequest, setIsSubmittingRequest] =
    useState<boolean>(false);

  const toggleActivateKeyModal = () => setActivateKeyModal(!activateKeyModal);

  const onActivateKeyHandler = async (
    values: types.IActivateKey,
    formikHelpers: FormikHelpers<types.IActivateKey>
  ) => {
    formikHelpers.setSubmitting(true);
    setIsSubmittingRequest(true);

    const { type } = await dispatch(
      activateKeyThunk({
        accountId: accountId,
        keyId: values.keyId,
        label: values.label,
      })
    );

    if (type === "accounts/activateKeyThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.AccountKeyActivatedEvent
        ) {
          setIsSubmittingRequest(false);
          toggleActivateKeyModal();
          toast.success("Key has been activated");
          dispatch(
            getAccountKeysByQueryThunk({
              accountId: accountId || "",
              params: utils.getCommonParamsForApi(),
            })
          );
        }
      });
    } else {
      formikHelpers.setSubmitting(false);
      setIsSubmittingRequest(false);
    }
  };

  const onDeactivateKeyHandler = async () => {
    setIsSubmittingRequest(true);

    const { type } = await dispatch(
      deactivateKeyThunk({
        accountId: accountId,
        keyId: selectedKeyId,
      })
    );

    if (type === "accounts/deactivateKeyThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.AccountKeyDeactivatedEvent
        ) {
          setIsSubmittingRequest(false);
          setOpenDeleteModal(false);
          toast.success("Key has been deactivated");
          dispatch(
            getAccountKeysByQueryThunk({
              accountId: accountId || "",
              params: utils.getCommonParamsForApi(),
            })
          );
        }
      });
    } else {
      setIsSubmittingRequest(false);
    }
  };

  return (
    <main>
      <Topbar theme={theme} title={t("drawerMenuLinks.keysManager")} />
      <Box mx={4}>
        <Table
          columns={tableColumns}
          data={accountKeys ?? []}
          progressPending={loading}
          paginationTotalRows={totalRecords}
          searchPlaceholder={t("common.search")}
          cellActions={[
            {
              name: `${t("buttonsText.deactivate")} ${t("common.key")}`,
              onClick: (key: types.IKey) => {
                setIsSubmittingRequest(false);
                setSelectedKeyId(key.uniqueId);
                setOpenDeleteModal(true);
              },
              when: (key: types.IKey) => key.isActive,
            },
          ]}
          primaryButton={{
            id: "keys-activate-form",
            text: t("buttonsText.activateNewKeys"),
            variant: "outlined",
            onClick: toggleActivateKeyModal,
          }}
        />
        <ActivateKeyForm
          open={activateKeyModal}
          onClose={toggleActivateKeyModal}
          onSubmit={onActivateKeyHandler}
          keys={accountAvailableKeys ?? []}
          submitting={isSubmittingRequest}
          theme={theme}
          translator={t}
        />
        <DeleteConfirmationModal
          open={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          title="You are about to deactivate a Key from account"
          subTitle="Do you really want to remove this key from account? This process cannot be undone."
          primaryBtnProps={{
            id: "keys-deactivate",
            onClick: onDeactivateKeyHandler,
            loading: isSubmittingRequest,
          }}
          theme={theme}
        />
      </Box>
    </main>
  );
};

export default KeysManager;
