import * as Yup from "yup";
import { isEmpty, omit } from "lodash";
import { helpers } from "@vilocnv/allsetra-core";

export const transformAccountsForDrawer = (allAccounts: any) => {
  return (
    allAccounts?.length &&
    allAccounts?.map((account: any) => {
      return {
        id: account.accountId,
        name: account.accountName,
        ...(account.children?.length
          ? { children: transformAccountsForDrawer(account?.children) }
          : null),
      };
    })
  );
};

//
// Account Settings Helpers
//
export const accountSettingsFormatterForForm = (account: any) => {
  if (isEmpty(account)) return {};

  const removedUnwantedKeys = omit(account, [
    "created",
    "createdBy",
    "deleted",
    "deletedBy",
    "isDeleted",
    "lastUpdated",
    "status",
    "updatedBy",
    "owner",
    "devices",
    "usersCount",
    "objectsCount",
    "linkedObjects",
    "countries",
    "accountOwner",
    "alarmsConfiguration",
  ]);

  const formattedObject = {
    ...removedUnwantedKeys,
    accountIndustry: account.accountIndustry?.id,
    accountType: account.accountType?.id,
    accountOwnerUniqueId: account.accountOwner,
    workingHours: {
      workingHoursSchedule: account.workingHours,
    },
    billingAddress: helpers.transformAddressObjectForForm(
      account.billingAddress
    ),
    shippingAddress: helpers.transformAddressObjectForForm(
      account.shippingAddress
    ),
    visitingAddress: helpers.transformAddressObjectForForm(
      account.visitingAddress
    ),
  };

  return formattedObject;
};

export const accountSettingsValidationSchema = Yup.object().shape({
  uniqueId: Yup.string(),
  name: Yup.string().required().label("Name"),
  debtorNumber: Yup.string().nullable().label("Debtor number"),
  resellerReference: Yup.string().nullable().label("Reseller Reference"),
  customerType: Yup.string().nullable().label("Customer Type"),
  website: Yup.string().nullable().label("Website"),
  phoneNumber: Yup.string().nullable().label("Phone number"),
  kvkcocNumber: Yup.string().nullable().label("KVK number"),
  description: Yup.string().nullable().label("Description"),
  workingHoursType: Yup.string().label("Working Hours Type"),
  accountNumber: Yup.string().nullable().label("Account number"),
  accountType: Yup.string().nullable(),
  accountIndustry: Yup.string().nullable(),
  boboid: Yup.string().nullable().label("BOBO ID"),
  notes: Yup.string().nullable().label("Notes"),
  accountOwner: Yup.string().nullable().label("Account owner"),
  multiViewerId: Yup.string().nullable().label("Multi-viewer ID"),
  afasDebitNumber: Yup.string().nullable().label("AFAS Debit number"),
  billingAddress: helpers.addressValidationSchema,
  bankAccount: Yup.string().nullable().label("AFAS Debit number"),
  vatNumber: Yup.string().nullable().label("VAT number"),
  vatShifted: Yup.string().nullable().label("VAT shifted"),
  paymentTermInDays: Yup.string().nullable().label("Payment term"),
  paymentMethod: Yup.string().nullable().label("Payment method"),
  visitingAddress: helpers.addressValidationSchema,
  shippingAddress: helpers.addressValidationSchema,
  workingHours: Yup.object({
    workingHoursSchedule: helpers.workingHoursValidationSchema,
  }),
});
