import { useState, useEffect } from "react";
import { GroupBy } from "app/data/helpers";
import { useAppDispatch, useAppSelector, useSelectSearch } from "hooks";
import {
  selectAccountGroups,
  selectDrawerSelectedAccountId,
  selectObjectsState,
  selectObjectTypesState,
  selectQueriedObjectsState,
  selectReportsState,
} from "app/data/selectors";
import { getObjectsByQueryThunk } from "app/features";

const useReportCommonStates = () => {
  const dispatch = useAppDispatch();
  // Global State
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);

  const { objectTypes, loading: objectTypesLoading } = useAppSelector(
    selectObjectTypesState
  );

  const { objects, loading: objectsLoading } = useAppSelector(
    selectQueriedObjectsState
  );

  const { basicObjects } = useAppSelector(selectObjectsState);

  const { accountGroups } = useAppSelector(selectAccountGroups);

  const { report, loading: reportsLoading } =
    useAppSelector(selectReportsState);

  // Local State
  const [filterOpen, setFilterOpen] = useState(false);

  const [dateFormat, setDateFormat] = useState<GroupBy>("day");

  const [dateValues, setDateValues] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [localReport, setLocalReport] = useState<any>([]);

  const [selectedLocalIds, setSelectedLocalIds]: Array<any> = useState([]);

  const selectedLocalObjects = useSelectSearch(selectedLocalIds, objects);

  const handleOnChangeReportFilterForm = (val: any) => {
    setSelectedLocalIds(val);
  };

  const handleObjectsSearch = (value: string) => {
    dispatch(
      getObjectsByQueryThunk({
        accountId: drawerSelectedAccountId || "",
        params: {
          itemsPerPage: 50,
          page: 1,
          where: [{ field: "name", value, type: 0 }],
        },
      })
    );
  };

  return {
    drawerSelectedAccountId,
    objectTypes,
    objectTypesLoading,
    objects,
    objectsLoading,
    accountGroups,
    report,
    reportsLoading,
    filterOpen,
    setFilterOpen,
    dateFormat,
    setDateFormat,
    dateValues,
    setDateValues,
    localReport,
    setLocalReport,
    handleObjectsSearch,
    selectedLocalObjects,
    selectedLocalIds,
    handleOnChangeReportFilterForm,
    basicObjects,
  };
};

export default useReportCommonStates;
