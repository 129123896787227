import { styled, Box } from "@mui/material";
import { IgnitionIcon } from "assets/icons";

export const ObjectMapContainer = styled(Box)({
  width: "100%",
  height: "75vh",
});

export const PageLoaderCentered = styled(Box)<{
  isDrawerCollapsed: boolean;
}>(({ theme, isDrawerCollapsed }) => ({
  ".css-1tlbauj": {
    width: `calc(100% - ${isDrawerCollapsed ? "65px" : "185px"})`,
  },

  [theme.breakpoints.down("sm")]: {
    ".css-1tlbauj": {
      width: "100%",
    },
  },
}));

export const IgnitionIconStyled = styled(IgnitionIcon)<{ color?: boolean }>(
  ({ color }) => ({
    "& path": {
      fill: color,
    },
  })
);

export const ChildFormBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginTop: "32px",
  },
}));
