import { styled } from "@mui/material";
import { Field } from "formik";

export const Label = styled("label")({
  display: "inline-block",
  fontSize: "16px",
  margin: "4px 0px",
});

export const Checkbox = styled(Field)({
  width: "16px",
  height: "16px",
  marginRight: "6px",
});
