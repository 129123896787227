import { FC } from "react";
import { Stack, useTheme, useMediaQuery } from "@mui/material";
import { FormikInputField } from "@vilocnv/allsetra-core";

const InnerForm: FC = () => {
  const theme = useTheme();
  const isDownSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack spacing={4}>
      <FormikInputField label="E-mail" name="email" fullWidth={isDownSmall} />
    </Stack>
  );
};

export default InnerForm;
