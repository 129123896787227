import { styled, Box } from "@mui/material";

export const LogoWrapper = styled(Box)({
  marginTop: 16,
  marginLeft: 40,
  marginRight: 24,
});

export const TrianglesWrapper = styled(Box)({
  textAlign: "right",
});

export const VectorSVGs = styled(Box)({
  position: "relative",
});

export const SuccessMessageContainer = styled(Box)(({ theme }) => ({
  padding: "16px 60px 48px 112px",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

export const Title = styled("p")(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: "40px",
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "115%",
  letterSpacing: "0.4px",
  marginBottom: 8,
}));

export const Subtitle = styled("p")(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: "18px",
  fontFamily: "Gilroy",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "136%",
  width: 448,
  marginBottom: 56,
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const SuccessList = styled(Box)({
  marginBottom: "40px",
  display: "flex",
  gap: "20px",
  alignItems: "baseline",
});

export const ListText = styled(Box)({
  color: "#323946",
  fontSize: "18px",
  fontFamily: "Gilroy",
  fontWeight: "400",
  lineHeight: "24.48px",
  width: 448,
});
