import { FC, Fragment } from "react";
import { isEmpty } from "lodash";
import {
  OverlayView,
  InfoWindowF,
  MarkerF,
  OverlayViewF,
} from "@react-google-maps/api";
import { MapObjectCard, ObjectMetadataTags } from "@vilocnv/allsetra-core";
import { MarkerBlip, MarkerLabel } from "../Map/Map.styled";
import { DateTime } from "luxon";

interface MarkerProps {
  objects: any;
  selectedMarker: number | null;
  handleMarkerClick: (markerIndex: any) => void;
}

const Markers: FC<MarkerProps> = ({
  objects,
  selectedMarker,
  handleMarkerClick,
}) => {
  return (
    <Fragment>
      {objects.map((object: any, index: number) => {
        if (isEmpty(object?.location)) return null;

        const position = {
          lat: object.location.latitude,
          lng: object.location.longitude,
        };

        const hasAlarmType = object.hasOwnProperty("alarmType");

        const iconUrl = !hasAlarmType ? object?.objectType?.icon?.url : "";

        const originalDate = object.location.date;
        const formattedDate = originalDate
          ? DateTime.fromISO(originalDate).toFormat("MMM dd, HH:mm a")
          : "N/A";

        const getPixelPositionOffset = () => ({
          x: 20,
          y: -29,
        });

        return (
          <Fragment key={index}>
            <MarkerF
              position={position}
              icon={
                iconUrl
                  ? {
                      url: iconUrl,
                      scaledSize: new window.google.maps.Size(28, 28),
                    }
                  : undefined
              }
              onClick={() => handleMarkerClick(index)}
            >
              {selectedMarker === index && (
                <InfoWindowF onCloseClick={() => handleMarkerClick(null)}>
                  <MapObjectCard
                    // objectIcon={iconUrl}
                    label={object.name || "N/A"}
                    id={object.aNumber || "N/A"}
                    address={object.location.resolvedAddress ?? "N/A"}
                    accuracy={object.location.accuracy ?? "N/A"}
                    time={formattedDate}
                  >
                    <ObjectMetadataTags object={object} />
                  </MapObjectCard>
                </InfoWindowF>
              )}
            </MarkerF>
            <OverlayViewF
              position={position}
              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={getPixelPositionOffset}
            >
              <MarkerLabel>{object.name || "N/A"}</MarkerLabel>
              <MarkerBlip></MarkerBlip>
            </OverlayViewF>
          </Fragment>
        );
      })}
    </Fragment>
  );
};

export default Markers;
