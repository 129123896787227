import { createAsyncThunk } from "@reduxjs/toolkit";
import { ObjectTypesService } from "app/data/services";

export const getObjectTypesByAccountThunk = createAsyncThunk(
  "objectTypes/getObjectTypesByAccountThunk",
  async (accountId: string) => {
    try {
      const response = await ObjectTypesService.getObjectTypesByAccount(
        accountId
      );

      if (response.status === 200) {
        return response.data;
      }
      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
