import { createAsyncThunk } from "@reduxjs/toolkit";
import { GeozonesService, UtilityService } from "app/data/services";
import { toast } from "@vilocnv/allsetra-core";
import { isEmpty } from "lodash";

export const getAllGeozonesThunk = createAsyncThunk(
  "geozones/getAllGeozonesThunk",
  async (accountId: string) => {
    if (isEmpty(accountId)) {
      toast.error("Kindly first select an account from the drawer.");
      return;
    }

    try {
      const response = await GeozonesService.getAllGeozones(accountId);

      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Server side error occured.");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getSpecificGeozoneThunk = createAsyncThunk(
  "geozones/getSpecificGeozoneThunk",
  async ({
    accountId,
    accountGeofenceId,
  }: {
    accountId: string;
    accountGeofenceId: string;
  }) => {
    if (isEmpty(accountId)) {
      toast.error("Kindly first select an account from the drawer.");
      return;
    }

    try {
      const response = await GeozonesService.getSpecificGeozone(
        accountId,
        accountGeofenceId
      );

      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Server side error occured.");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const createOrEditGeozoneThunk = createAsyncThunk(
  "geozones/createOrEditGeozoneThunk",
  async ({ accountId, data }: any) => {
    if (isEmpty(accountId)) {
      toast.error("Kindly first select an account from the drawer.");
      return;
    }

    try {
      const response = data.uniqueId
        ? await GeozonesService.updateGeozone(accountId, data)
        : await GeozonesService.createGeozone(accountId, data);

      if (response.status !== 202) {
        toast.error("Server side error occured.");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error("Server side error occured.");
      throw new Error(e);
    }
  }
);

export const updateGeozoneThunk = createAsyncThunk(
  "geozones/updateGeozoneThunk",
  async ({ accountId, data }: any) => {
    if (isEmpty(accountId)) {
      toast.error("Kindly first select an account from the drawer.");
      return;
    }

    try {
      const response = await GeozonesService.updateGeozone(accountId, data);

      if (response.status === 202) {
        toast.success(
          "Geozone update request is being processed by the backend."
        );
      } else {
        toast.error("Server side error occured.");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const deleteGeozoneThunk = createAsyncThunk(
  "geozones/deleteGeozoneThunk",
  async ({
    accountId,
    accountGeofenceId,
  }: {
    accountId: string;
    accountGeofenceId: string;
  }) => {
    if (isEmpty(accountId)) {
      toast.error("Kindly first select an account from the drawer.");
      return;
    }

    try {
      const response = await GeozonesService.deleteGeozone(
        accountId,
        accountGeofenceId
      );

      if (response.status !== 202) {
        toast.error("Server side error occured.");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getGeozoneObjectsThunk = createAsyncThunk(
  "geozones/getGeozoneObjectsThunk",
  async ({
    accountId,
    geozoneId,
  }: {
    accountId: string;
    geozoneId: string;
  }) => {
    if (isEmpty(accountId)) {
      toast.error("Kindly first select an account from the drawer.");
      return;
    }

    try {
      const response = await GeozonesService.getGeozoneObjects(
        accountId,
        geozoneId
      );

      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Server side error occured.");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getGeozoneAssignedObjectsThunk = createAsyncThunk(
  "geozones/getGeozoneAssignedObjectsThunk",
  async ({
    accountId,
    geozoneId,
  }: {
    accountId: string;
    geozoneId: string;
  }) => {
    if (isEmpty(accountId)) {
      toast.error("Kindly first select an account from the drawer.");
      return;
    }

    try {
      const response = await GeozonesService.getGeozoneAssignedObjects(
        accountId,
        geozoneId
      );

      if (response.status === 200) {
        return response.data;
      } else {
        toast.error("Server side error occured.");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAccountGeozones = createAsyncThunk(
  "geozones/getAccountGeozones",
  async (accountId: string) => {
    try {
      const response = await GeozonesService.getAccountGeozones(accountId);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAddressThunk = createAsyncThunk(
  "geozones/getAddressThunk",
  async ({ latitude, longitude }: { latitude: number; longitude: number }) => {
    try {
      const response = await UtilityService.getAddressFromLatLng(
        latitude,
        longitude
      );
      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
