import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectDashboardReducerLoading = (state: RootState) =>
  state.rootReducer.dashboardReducer.loading;

export const selectIsDrawerCollapsed = (state: RootState) =>
  state.rootReducer.dashboardReducer.isDrawerCollapsed;

export const selectDrawerSelectedAccountId = (state: RootState) =>
  state.rootReducer.dashboardReducer.drawerSelectedAccountId;

export const selectSavedFilters = (state: RootState) =>
  state.rootReducer.dashboardReducer.mapFilters;

export const selectMapFilterState = createSelector(
  selectSavedFilters,
  (mapFilters) => ({ mapFilters })
);

export const selectDrawerSelectedAccountName = (state: RootState) =>
  state.rootReducer.dashboardReducer.drawerSelectedAccountName;

export const selectDashboardUserEmail = (state: RootState) =>
  state.rootReducer.dashboardReducer.dashboardUserEmail;

export const selectActiveUserDetails = createSelector(
  selectDashboardUserEmail,
  (dashboardUserEmail) => ({ dashboardUserEmail })
);

export const selectAllRoles = (state: RootState) =>
  state.rootReducer.dashboardReducer.roles;

export const selectAllCurrencies = (state: RootState) =>
  state.rootReducer.dashboardReducer.currencies;

export const selectAllIcons = (state: RootState) =>
  state.rootReducer.dashboardReducer.icons;

export const selectIconsByCategory = (state: RootState) =>
  state.rootReducer.dashboardReducer.iconsByCategory;

export const selectAllCountries = (state: RootState) =>
  state.rootReducer.dashboardReducer.countries;

export const selectAllPaymentMethods = (state: RootState) =>
  state.rootReducer.dashboardReducer.paymentMethods;

export const selectDashboardIconsState = createSelector(
  selectDashboardReducerLoading,
  selectAllIcons,
  selectIconsByCategory,
  (loading, icons, iconsByCategory) => ({
    loading,
    icons,
    iconsByCategory,
  })
);

export const selectDashboardCountriesState = createSelector(
  selectDashboardReducerLoading,
  selectAllCountries,
  (loading, countries) => ({
    loading,
    countries,
  })
);

export const selectDashboardPaymentMethodsState = createSelector(
  selectDashboardReducerLoading,
  selectAllPaymentMethods,
  (loading, paymentMethods) => ({
    loading,
    paymentMethods,
  })
);

export const selectDashboardGooglePlacesState = createSelector(
  (state: RootState) =>
    state.rootReducer.dashboardReducer.googlePredictatedPlacesLoading,
  (state: RootState) =>
    state.rootReducer.dashboardReducer.googlePredictatedPlaces,
  (googlePredictatedPlacesLoading, googlePredictatedPlaces) => ({
    googlePredictatedPlacesLoading,
    googlePredictatedPlaces,
  })
);

export const selectDrawerSelectedAccountState = createSelector(
  selectDrawerSelectedAccountId,
  selectDrawerSelectedAccountName,
  (drawerSelectedAccountId, drawerSelectedAccountName) => ({
    drawerSelectedAccountId,
    drawerSelectedAccountName,
  })
);
