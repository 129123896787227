import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast, types } from "@vilocnv/allsetra-core";
import ObjectRideDetailsDrawer from "components/common/objects/ObjectRideDetailsDrawer/ObjectRideDetailsDrawer";
import ObjectRideGoogleMap from "components/maps/ObjectRideGoogleMap/ObjectRideGoogleMap";

// Data
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getAddressThunk,
  getObjectRideLocationsThunk,
  getObjectSpecificRideInfoThunk,
} from "app/features";
import {
  selectDrawerSelectedAccountId,
  selectObjectRidesState,
} from "app/data/selectors";
import { PageLoader } from "@vilocnv/allsetra-core";
import { Grid } from "@mui/material";

const ObjectRideMap: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [selectedLocation, setSelectedLocation] =
    useState<types.IObjectRideLocation | null>(null);

  // Global State
  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";

  const {
    ridesLoading,
    specificRide,
    objectRideLocationsLoading,
    objectRideLocations,
  } = useAppSelector(selectObjectRidesState);

  const fetchObjectRideData = useCallback(async () => {
    const data = {
      accountId: drawerSelectedAccountId,
      objectId: params.objectId || "",
      rideId: params.rideId || "",
    };

    dispatch(getObjectRideLocationsThunk(data));

    const { type } = await dispatch(getObjectSpecificRideInfoThunk(data));

    if (type === "objects/getObjectSpecificRideInfoThunk/rejected") {
      toast.error("Object ride was not found.");
      navigate(-1);
    }
  }, [params, drawerSelectedAccountId]);

  useEffect(() => {
    if (
      isEmpty(drawerSelectedAccountId) ||
      isEmpty(params.objectId) ||
      isEmpty(params.rideId)
    ) {
      toast.error("Object ride was not found.");
      navigate(-1);
    } else {
      fetchObjectRideData();
    }
  }, [params, drawerSelectedAccountId]);

  const onLocationItemClick = (selectedData: types.IObjectRideLocation) => {
    if (selectedData?.uniqueId !== selectedLocation?.uniqueId) {
      setSelectedLocation({ ...selectedData });
      const { latitude, longitude } = selectedData;
      dispatch(getAddressThunk({ latitude, longitude }));
    }
  };

  return (
    <Grid container>
      {ridesLoading || objectRideLocationsLoading ? (
        <PageLoader />
      ) : (
        <Fragment>
          <Grid item xs={12} md={3}>
            <ObjectRideDetailsDrawer
              objectRide={specificRide}
              objectRideLocations={objectRideLocations}
              onLocationItemClick={onLocationItemClick}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            <ObjectRideGoogleMap
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              objectRide={specificRide}
              objectRideLocations={objectRideLocations}
              onLocationItemClick={onLocationItemClick}
            />
          </Grid>
        </Fragment>
      )}
    </Grid>
  );
};

export default ObjectRideMap;
