import { FC, useMemo } from "react";
import { Formik, FormikHelpers } from "formik";
import { toast } from "@vilocnv/allsetra-core";
import InnerForm from "./children/InnerForm";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import {
  createOrUpdatePointsOfInterestThunk,
  getAllPointsOfInterestThunk,
} from "app/features";
import {
  poiInitialValues,
  poiValidationSchema,
  transformPointOfInterestForForm,
} from "app/data/helpers";
import { selectDrawerSelectedAccountId } from "app/data/selectors";
import { IPointOfInterest } from "app/data/types";
import { isEmpty } from "lodash";

interface Props {
  open: boolean;
  onClose: () => void;
  editFormData: IPointOfInterest | null;
}

const AddPointOfInterestForm: FC<Props> = ({ open, onClose, editFormData }) => {
  const dispatch = useAppDispatch();

  const isInEdit = !!editFormData?.uniqueId;

  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);

  const initialValues = useMemo(() => {
    return isEmpty(editFormData)
      ? poiInitialValues
      : transformPointOfInterestForForm(editFormData);
  }, [editFormData]);

  const onSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);

    const { type } = await dispatch(
      createOrUpdatePointsOfInterestThunk({
        accountId: drawerSelectedAccountId ?? "",
        data: values,
      })
    );

    if (
      type === "pointsOfInterest/createOrUpdatePointsOfInterestThunk/fulfilled"
    ) {
      toast.success(
        values.uniqueId
          ? "Work address has been edited"
          : "Work address has been created"
      );

      onClose();
      formikHelpers.resetForm();

      dispatch(getAllPointsOfInterestThunk(drawerSelectedAccountId || ""));
    } else {
      toast.error("Server side error occured");
    }

    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={poiValidationSchema}
      onSubmit={onSubmitHandler}
      validateOnMount
      enableReinitialize
    >
      <InnerForm
        open={open}
        onClose={onClose}
        isInEdit={isInEdit}
        editFormData={editFormData}
      />
    </Formik>
  );
};

export default AddPointOfInterestForm;
