import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectActivationPortalReducerLoading = (state: RootState) =>
  state.rootReducer.activationPortalReducer.loading;

//  Activation Portal State Selectors

export const selectAllInstallations = (state: RootState) =>
  state.rootReducer.activationPortalReducer.installations;

export const selectAllSubscriptions = (state: RootState) =>
  state.rootReducer.activationPortalReducer.subscriptions;

export const selectAllInformationSecurityQuestions = (state: RootState) =>
  state.rootReducer.activationPortalReducer.securityQuestions;

export const selectAllSubscribeFormData = (state: RootState) =>
  state.rootReducer.activationPortalReducer.subscribeFormData;

export const selectVerificationCode = (state: RootState) =>
  state.rootReducer.activationPortalReducer.verificationCode;

export const selectMyAccounts = (state: RootState) =>
  state.rootReducer.activationPortalReducer.myAccounts;

  export const selectFromDashboard = (state: RootState) =>
  state.rootReducer.activationPortalReducer.fromDashboard;

export const selectActivationPortalState = createSelector(
  selectActivationPortalReducerLoading,
  selectAllInstallations,
  selectAllSubscriptions,
  selectAllInformationSecurityQuestions,
  selectAllSubscribeFormData,
  selectVerificationCode,
  selectMyAccounts,
  selectFromDashboard,
  (
    loading,
    installations,
    subscriptions,
    securityQuestions,
    subscribeFormData,
    verificationCode,
    myAccounts,
    fromDashboard
  ) => ({
    loading,
    installations,
    subscriptions,
    securityQuestions,
    subscribeFormData,
    verificationCode,
    myAccounts,
    fromDashboard,
  })
);
