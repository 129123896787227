import { FC, useMemo } from "react";
import { Form, Formik } from "formik";
import {
  paymentInitialValues,
  paymentValidationSchema,
} from "app/data/helpers/activationPortalHelpers";
import StepScreenLayout from "components/layouts/StepScreenLayout/StepScreenLayout";
import InnerForm from "./children/InnerForm";

//Data
import { useAppDispatch, useAppSelector } from "hooks";
import { setSubscribeFormData } from "app/features";
import { IActivationPayment } from "app/data/types";
import { selectActivationPortalState } from "app/data/selectors/activationPortalSelectors";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

interface Props {
  gotoNextStep: () => void;
}

const ActivationPaymentForm: FC<Props> = ({ gotoNextStep }) => {
  const dispatch = useAppDispatch();

  const { subscribeFormData } = useAppSelector(selectActivationPortalState);

  const { t } = useTranslation([
    "translation",
    "activationPortal",
    "formFieldsTranslation",
  ]);

  const onSubmitHandler = (values: IActivationPayment) => {
    dispatch(setSubscribeFormData(values));
    gotoNextStep();
  };

  const formValues = useMemo(
    () =>
      !isEmpty(subscribeFormData) && subscribeFormData?.accountHolderName
        ? subscribeFormData
        : paymentInitialValues,
    [subscribeFormData]
  );

  return (
    <Formik
      //@ts-ignore
      initialValues={formValues}
      onSubmit={onSubmitHandler}
      validationSchema={paymentValidationSchema}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isValid }) => (
        <Form>
          <StepScreenLayout
            title={t("titles.payment", {
              ns: "activationPortal",
            })}
            subtitle={t("descriptions.payment", {
              ns: "activationPortal",
            })}
            disabled={!isValid}
            onButtonSubmit={handleSubmit}
          >
            <InnerForm translator={t} />
          </StepScreenLayout>
        </Form>
      )}
    </Formik>
  );
};

export default ActivationPaymentForm;
