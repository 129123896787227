import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { KeyValueTable, types } from "@vilocnv/allsetra-core";
import ObjectAssignedUsersCard from "components/cards/ObjectAssignedUsersCard/ObjectAssignedUsersCard";
import { useTranslation } from "react-i18next";

interface Props {
  specificObject: types.IObject | null;
}

const AssignedUsersTable: FC<Props> = ({ specificObject }) => {
  const { t } = useTranslation([
    "translation",
    "tableHeadingsTranslation",
    "formFieldsTranslation",
  ]);

  return (
    <Stack direction={"column"} spacing={2}>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "500",
          color: "#1D1F2B",
        }}
      >
        {t("titles.assignedUser")}
      </Typography>

      {specificObject?.users?.length ? (
        specificObject?.users.map((record) => (
          <ObjectAssignedUsersCard user={record.user} />
        ))
      ) : (
        <KeyValueTable records={{ Users: "None" }} />
      )}
    </Stack>
  );
};

export default AssignedUsersTable;
