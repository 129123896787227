import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectReportsReducerLoading = (state: RootState) =>
  state.rootReducer.reportsReducer.loading;

export const selectAllReports = (state: RootState) =>
  state.rootReducer.reportsReducer.report;

export const selectReportsState = createSelector(
  selectReportsReducerLoading,
  selectAllReports,
  (loading, report) => {
    return {
      loading,
      report,
    };
  }
);
