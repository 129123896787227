import { FC, useMemo, useState } from "react";
import { types, ModalProps, toast } from "@vilocnv/allsetra-core";
import { Form, Formik, FormikHelpers } from "formik";
import InnerForm from "./children/InnerForm";

// Data
import { isEmpty, pick } from "lodash";
import {
  signalRGenerateSuccessToastMessage,
  transformObjectRideDataForForm,
  updateObjectRideFormValidationSchema,
} from "app/data/helpers";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  getObjectSpecificRideInfoThunk,
  updateObjectRideThunk,
} from "app/features";
import { selectDrawerSelectedAccountId } from "app/data/selectors";
import { SignalRService } from "app/data/services";

type Props = Omit<ModalProps, "title" | "children"> & {
  objectRide: types.IObjectRide | null;
};

const UpdateObjectRideForm: FC<Props> = ({ open, onClose, objectRide }) => {
  const dispatch = useAppDispatch();

  const accountId = useAppSelector(selectDrawerSelectedAccountId);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const initialValues = useMemo(
    () =>
      !isEmpty(objectRide) ? transformObjectRideDataForForm(objectRide) : {},
    [objectRide]
  );

  const saveChangesHandler = async (
    values: types.IUpdateObjectRide,
    formikHelpers: FormikHelpers<types.IUpdateObjectRide>
  ) => {
    try {
      formikHelpers.setSubmitting(true);
      setIsSubmitting(true);

      const params: { accountId: string; objectId: string; rideId: string } = {
        accountId: accountId ?? "",
        objectId: objectRide?.object?.uniqueId ?? "",
        rideId: objectRide?.uniqueId ?? "",
      };

      const {
        startAddressPointOfInterest,
        correctedStartAddress,
        correctedStartLatitude,
        correctedStartLongitude,
        endAddressPointOfInterest,
        correctedEndAddress,
        correctedEndLatitude,
        correctedEndLongitude,
      } = values;
      const pickedValues = ["uniqueId", "comments"];
      if (values.keyId) {
        pickedValues.push("keyId");
      }
      const data = {
        rideMode: Number(values.rideMode),
        ...pick(values, pickedValues),
        ...(!isEmpty(startAddressPointOfInterest)
          ? { startAddressPointOfInterest }
          : {
              correctedStartAddress,
              correctedStartLatitude,
              correctedStartLongitude,
            }),
        ...(!isEmpty(endAddressPointOfInterest)
          ? { endAddressPointOfInterest }
          : {
              correctedEndAddress,
              correctedEndLatitude,
              correctedEndLongitude,
            }),
      };

      const { type } = await dispatch(
        updateObjectRideThunk({ ...params, data })
      );

      if (type === "objects/updateObjectRideThunk/fulfilled") {
        SignalRService.hubConnection?.on("EventRaised", (event: any) => {
          if (event.eventName === types.BackendEventsEnum.RideUpdatedEvent) {
            formikHelpers.setSubmitting(false);
            setIsSubmitting(false);
            toast.success(
              signalRGenerateSuccessToastMessage(
                event.eventName,
                "Object Ride",
                "updated"
              )
            );
            dispatch(getObjectSpecificRideInfoThunk(params));
          }
        });
      } else {
        formikHelpers.setSubmitting(false);
        setIsSubmitting(false);
      }
    } catch (e: any) {
      setIsSubmitting(false);
      console.log("updateObjectRide", e.message);
    }
  };

  return (
    <Formik
      // @ts-ignore
      initialValues={initialValues}
      validationSchema={updateObjectRideFormValidationSchema}
      onSubmit={saveChangesHandler}
      enableReinitialize
      validateOnMount
    >
      <Form>
        <InnerForm
          open={open}
          onClose={onClose}
          customIsSubmitting={isSubmitting}
        />
      </Form>
    </Formik>
  );
};

export default UpdateObjectRideForm;
