import { styled, Box, Typography, Stack } from "@mui/material";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

export const CardContainer = styled(Box)({
  padding: "16px",
  borderRadius: "8px",
  border: "1px solid #EEF1FF",
  background: "#fff",
});

export const SmallText = styled(Typography)<{
  color?: string;
}>(({ color }) => ({
  color: color || "#646D7B",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "132%",
}));

export const InfoIcon = styled(InfoRoundedIcon)({
  width: "16px",
  height: "16px",
  color: "#959EB2",
});

export const ObjectDetailsLeftContainer = styled(Stack)(({ theme }) => ({
  maxHeight: "75vh",
  overflowY: "scroll",
  scrollBehavior: "smooth",

  ["::-webkit-scrollbar"]: {
    width: 0 /* Remove scrollbar space */,
    background: "transparent" /* Optional: just make scrollbar invisible */,
  },

  [theme.breakpoints.down("md")]: {
    maxHeight: "none",
    overflowY: "hidden",
    marginBottom: "20px",
  },
}));
