import React, { Fragment, useMemo, memo } from "react";
import {
  MarkerClustererF,
  OverlayViewF,
  OverlayView,
} from "@react-google-maps/api";
import { MarkerBlip, MarkerLabel } from "../Map/Map.styled";
import ObjectMarker from "./ObjectMarker";

const options = {
  imagePath:
    "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
};

const getPixelPositionOffset = () => ({
  x: 20,
  y: -29,
});

interface CustomMarkerClustererProps {
  map: google.maps.Map | undefined;
  markers: Array<any>;
  selectedMarkerLoading: boolean;
  showObjectNames: boolean;
}

const MarkerClusterer: React.FC<CustomMarkerClustererProps> = ({
  map,
  markers,
  selectedMarkerLoading,
  showObjectNames,
}) => {
  const visibleMarkers = useMemo(() => {
    //@ts-ignore
    if (!map || map.zoom <= 13) return markers;

    const mapBounds = map.getBounds();

    return markers.filter((marker) =>
      mapBounds?.contains(new window.google.maps.LatLng(marker.lat, marker.lng))
    );
  }, [map, markers]);

  return visibleMarkers.length === 1 ? (
    <Fragment>
      <ObjectMarker
        marker={visibleMarkers[0]}
        selectedMarkerLoading={selectedMarkerLoading}
        clusterer={undefined}
      />
    </Fragment>
  ) : (
    <MarkerClustererF options={options} minimumClusterSize={5}>
      {(clusterer) => (
        <Fragment>
          {visibleMarkers &&
            visibleMarkers.map((marker: any, i: number) => (
              <Fragment key={`${i}`}>
                <ObjectMarker
                  marker={marker}
                  selectedMarkerLoading={selectedMarkerLoading}
                  clusterer={clusterer}
                />
                {/* @ts-ignore */}
                {map?.zoom > 10 && (
                  <OverlayViewF
                    position={{ lat: marker.lat, lng: marker.lng }}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                    getPixelPositionOffset={getPixelPositionOffset}
                  >
                    {showObjectNames && (
                      <Fragment>
                        <MarkerLabel>{marker?.name || "N/A"}</MarkerLabel>
                        <MarkerBlip></MarkerBlip>
                      </Fragment>
                    )}
                  </OverlayViewF>
                )}
              </Fragment>
            ))}
        </Fragment>
      )}
    </MarkerClustererF>
  );
};

export default memo(MarkerClusterer);
