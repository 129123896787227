import { FC } from "react";
import { Stack } from "@mui/material";
import { FormikSelectField } from "@vilocnv/allsetra-core";

interface Props {
  data: Array<any>;
  translator: any;
}
const AssociatedAccountForm: FC<Props> = ({ data, translator }) => {
  return (
    <Stack spacing={4} width={{ xs: "50vw", sm: "30vw" }}>
      <FormikSelectField
        label={translator("selectAssociatedAccount.label", {
          ns: "formFieldsTranslation",
        })}
        name={"accountId"}
        options={data}
        optionLabelKey="accountName"
        optionValueKey="accountId"
        fullWidth
      />
    </Stack>
  );
};

export default AssociatedAccountForm;
