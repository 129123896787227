import axios from "axios";
import * as Sentry from "@sentry/react";

const instance = axios.create({
  baseURL: process.env.REACT_APP_FIETSGESTOLEN_BASE_URL,
  responseType: "json",
});

instance.interceptors.request.use(
  async function (config) {
    config.headers["X-Subscription"] =
      process.env.REACT_APP_FIETSGESTOLEN_HEADER_SUBSCRIPTION;

    return config;
  },
  function (error) {
    // Log error to Sentry
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Log error to Sentry
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export default instance;
