import { FC, Fragment } from "react";
import { Stack, Box } from "@mui/material";
import { ContentSectionLayout } from "@vilocnv/allsetra-core";
import { InfoDetailContainer } from "../../activationPortalSections.styled";
import {
  IActivationPortalInitialValues,
  IAlaramContactPersons,
} from "app/data/types";
import {
  getSecurityQuestion,
  overviewInformationAlarmPersonData,
  overviewInformationStepData,
} from "app/data/helpers";

interface Props extends IActivationPortalInitialValues {
  securityQuestions: Array<any>;
  order: number;
  translator: any;
}

const PersonalInfoOverview: FC<Props> = ({
  initialValues,
  securityQuestions,
  order = 1,
  translator,
}) => {
  const { address, contactName } = overviewInformationStepData(initialValues);

  const { securityQuestion } = getSecurityQuestion(
    securityQuestions,
    initialValues?.userInfo?.safeQuestionId as string
  );

  return (
    <Stack spacing={3}>
      <h5>
        {order}.{" "}
        {translator("titles.information", {
          ns: "activationPortal",
        })}
      </h5>
      <Box>
        {initialValues.tabSelectedIndex === 1 && (
          <ContentSectionLayout
            title={translator("titles.companyInformation", {
              ns: "activationPortal",
            })}
            subTitle=""
          >
            <InfoDetailContainer>
              <h6>
                {translator("companyName.label", {
                  ns: "formFieldsTranslation",
                })}
              </h6>
              <p>{initialValues?.companyInfo?.name || "-"}</p>
            </InfoDetailContainer>

            <InfoDetailContainer>
              <h6>
                {translator("address.label", {
                  ns: "formFieldsTranslation",
                })}
              </h6>
              <p>{/[a-zA-Z]/g.test(address) ? address : "-"}</p>
            </InfoDetailContainer>

            <InfoDetailContainer hideDivider>
              <h6>
                {translator("vatNumber.label", {
                  ns: "formFieldsTranslation",
                })}
              </h6>
              <p>{initialValues?.companyInfo?.vatNumber || "-"}</p>
            </InfoDetailContainer>
          </ContentSectionLayout>
        )}

        <ContentSectionLayout
          title={translator("titles.userInformation", {
            ns: "activationPortal",
          })}
          subTitle=""
        >
          <InfoDetailContainer>
            <h6>
              {translator("fullName.label", {
                ns: "formFieldsTranslation",
              })}
            </h6>
            <p>{contactName || ""}</p>
          </InfoDetailContainer>

          <InfoDetailContainer>
            <h6>
              {translator("email.label", {
                ns: "formFieldsTranslation",
              })}
            </h6>
            <p>{initialValues?.userInfo?.email || ""}</p>
          </InfoDetailContainer>

          <InfoDetailContainer>
            <h6>
              {`${translator("phoneNumber.label", {
                ns: "formFieldsTranslation",
              })} 1`}
            </h6>
            <p>{initialValues?.userInfo?.phoneNumber1 || ""}</p>
          </InfoDetailContainer>

          {initialValues?.userInfo?.phoneNumber2 && (
            <InfoDetailContainer>
              <h6>
                {`${translator("phoneNumber.label", {
                  ns: "formFieldsTranslation",
                })} 2`}
              </h6>
              <p>{initialValues?.userInfo?.phoneNumber2 || ""}</p>
            </InfoDetailContainer>
          )}

          <InfoDetailContainer hideDivider>
            <h6>
              {translator("securityQuestion.label", {
                ns: "formFieldsTranslation",
              })}
            </h6>
            <p>{securityQuestion || ""}</p>
          </InfoDetailContainer>

          <InfoDetailContainer>
            <h6>
              {translator("securityQuestionResponse.label", {
                ns: "formFieldsTranslation",
              })}
            </h6>
            <p>{initialValues?.userInfo?.safeQuestionAnswer || ""}</p>
          </InfoDetailContainer>
        </ContentSectionLayout>

        {initialValues?.alarmPersons &&
          initialValues?.alarmPersons?.length !== 0 && (
            <ContentSectionLayout title="Alarm contact persons" subTitle="">
              {initialValues?.alarmPersons.map(
                (alarmPerson: IAlaramContactPersons) => {
                  const { alarmPersonName } =
                    overviewInformationAlarmPersonData(
                      alarmPerson?.prefix,
                      alarmPerson.firstName,
                      alarmPerson.lastName
                    );

                  const { securityQuestion } = getSecurityQuestion(
                    securityQuestions,
                    alarmPerson.safeQuestionId
                  );
                  return (
                    <Fragment>
                      <InfoDetailContainer hideDivider>
                        <h6>
                          {translator("fullName.label", {
                            ns: "formFieldsTranslation",
                          })}
                        </h6>
                        <p>{alarmPersonName || ""}</p>
                      </InfoDetailContainer>

                      <InfoDetailContainer hideDivider>
                        <h6>
                          {translator("email.label", {
                            ns: "formFieldsTranslation",
                          })}
                        </h6>
                        <p>{alarmPerson.email || ""}</p>
                      </InfoDetailContainer>

                      <InfoDetailContainer hideDivider>
                        <h6>
                          {`${translator("phoneNumber.label", {
                            ns: "formFieldsTranslation",
                          })} 1`}
                        </h6>
                        <p>{alarmPerson.phoneNumber1 || ""}</p>
                      </InfoDetailContainer>

                      {alarmPerson.phoneNumber2 && (
                        <InfoDetailContainer hideDivider>
                          <h6>
                            {`${translator("phoneNumber.label", {
                              ns: "formFieldsTranslation",
                            })} 2`}
                          </h6>
                          <p>{alarmPerson.phoneNumber2 || ""}</p>
                        </InfoDetailContainer>
                      )}

                      <InfoDetailContainer hideDivider>
                        <h6>
                          {translator("securityQuestion.label", {
                            ns: "formFieldsTranslation",
                          })}
                        </h6>
                        <p>{securityQuestion || ""}</p>
                      </InfoDetailContainer>

                      <InfoDetailContainer>
                        <h6>
                          {translator("securityQuestionResponse.label", {
                            ns: "formFieldsTranslation",
                          })}
                        </h6>
                        <p>{alarmPerson.safeQuestionAnswer || ""}</p>
                      </InfoDetailContainer>
                    </Fragment>
                  );
                }
              )}
            </ContentSectionLayout>
          )}
      </Box>
    </Stack>
  );
};

export default PersonalInfoOverview;
