import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { types } from "@vilocnv/allsetra-core";
import { IFeatures } from "app/data/types";
import {
  extractObjectsDataAsMarkersForMap,
  featuresInitialData,
} from "app/data/helpers";
import {
  getAllObjectsThunk,
  getObjectsByQueryThunk,
  getSpecificObjectByIdThunk,
  getObjectsLocationsThunk,
  getAllBasicInfoObjectsThunk,
  getObjectRidesByQueryThunk,
  getObjectSpecificRideInfoThunk,
  getObjectRideLocationsThunk,
  getObjectFeaturesThunk,
} from "./objectsActions";

export interface IObjectState {
  loading: boolean;
  totalRecords: number | null;
  specificObjectLoading: boolean;
  objectActiveTabIndex: number;
  specificObject: types.IObject | null;
  allObjects: Array<types.IObject>;
  objects: Array<types.IObject>;
  basicObjects: Array<any>;
  alarmsConfigLoading: boolean;
  alarmsConfig: {
    effectiveConfiguration: Array<any>;
    groupHasCustomAlarmConfiguration: boolean | null;
    objectHasCustomAlarmConfiguration: boolean | null;
  };
  activeObjectId: string | null;

  // Object Rides
  ridesLoading: boolean;
  objectRides: Array<types.IObjectRide>;
  objectRidesRowCount: number;
  specificRide: types.IObjectRide | null;
  objectRideLocationsLoading: boolean;
  objectRideLocations: Array<any>;

  // Object Features
  objectFeaturesLoading: boolean;
  objectFeaturesList: Array<any>;
  objectFeaturesConfig: IFeatures;

  // Object Map
  markers: Array<any>;
  shouldRenderMarkers: boolean;
}

export const objectAlarmConfigInitialData = {
  effectiveConfiguration: [],
  groupHasCustomAlarmConfiguration: null,
  objectHasCustomAlarmConfiguration: null,
};

const initialState: IObjectState = {
  loading: false,
  totalRecords: null,
  specificObjectLoading: false,
  objectActiveTabIndex: 0,
  specificObject: null,
  allObjects: [],
  objects: [],
  basicObjects: [],
  alarmsConfigLoading: false,
  alarmsConfig: objectAlarmConfigInitialData,
  activeObjectId: null,

  // Object Rides
  ridesLoading: false,
  objectRides: [],
  objectRidesRowCount: 0,
  specificRide: null,
  objectRideLocationsLoading: false,
  objectRideLocations: [],

  // Object Features
  objectFeaturesLoading: false,
  objectFeaturesList: [],
  objectFeaturesConfig: featuresInitialData,

  // Object Map
  markers: [],
  shouldRenderMarkers: false,
};

const objectsSlice = createSlice({
  name: "objects",
  initialState,
  reducers: {
    setSpecificObject: (state, action) => {
      state.specificObject = action.payload;
    },
    updateObjectLocation: (state, action) => {
      const object = state.allObjects.find(
        (obj) => obj.uniqueId === action.payload.uniqueId
      );

      if (object) {
        object.location.latitude = action.payload.latitude;
        object.location.longitude = action.payload.longitude;
        object.location.accuracy = action.payload.accuracy;
        object.location.altitude = action.payload.altitude;
        object.location.resolvedAddress = action.payload.resolvedAddress;
        object.location.date = action.payload.locationDate;
      }

      if (
        state.specificObject &&
        state.specificObject.uniqueId === action.payload.uniqueId
      ) {
        state.specificObject.location.latitude = action.payload.latitude;
        state.specificObject.location.longitude = action.payload.longitude;
        state.specificObject.location.accuracy = action.payload.accuracy;
        state.specificObject.location.altitude = action.payload.altitude;
        state.specificObject.location.resolvedAddress =
          action.payload.resolvedAddress;
        state.specificObject.location.date = action.payload.locationDate;
        state.specificObject.metadata = action.payload.metadata;
      }

      if (state.allObjects && state.allObjects.length > 0) {
        state.shouldRenderMarkers = true;
        state.markers = extractObjectsDataAsMarkersForMap(state.allObjects);
      }
    },
    updateObjectIcon: (state, action) => {
      const object = state.allObjects.find(
        (obj) => obj.uniqueId === action.payload.uniqueId
      );

      if (object) {
        object.objectType.icon.url = action.payload.iconUrl;
      }

      if (
        state.specificObject &&
        state.specificObject.uniqueId === action.payload.uniqueId
      ) {
        state.specificObject.objectType.icon.url = action.payload.iconUrl;
      }
    },
    resetAllObjects: (state) => {
      state.allObjects = [];
    },
    setObjectActiveTabIndex: (state, action) => {
      state.objectActiveTabIndex = action.payload;
    },
    setObjectSpecificRide: (
      state,
      action: PayloadAction<types.IObjectRide | null>
    ) => {
      state.specificRide = action.payload;
    },
    setActiveObjectId: (state, action: PayloadAction<string | null>) => {
      state.activeObjectId = action.payload;
    },
    setActiveObjectAsMarker: (state, action: PayloadAction<types.IObject>) => {
      state.activeObjectId = action.payload.uniqueId;
      state.markers = extractObjectsDataAsMarkersForMap([action.payload]);
      state.shouldRenderMarkers = true;
    },
    resetQueriedObjects: (state) => {
      state.objects = [];
    },
  },
  extraReducers: (builder) => {
    // Get All Objects Action Cases
    builder.addCase(getAllObjectsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllObjectsThunk.fulfilled, (state, action) => {
      state.allObjects = action.payload || [];
      state.loading = false;
    });

    builder.addCase(getAllObjectsThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get All Basic Objects Action Cases
    builder.addCase(getAllBasicInfoObjectsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllBasicInfoObjectsThunk.fulfilled, (state, action) => {
      state.basicObjects = action.payload || [];
      state.loading = false;
    });

    builder.addCase(getAllBasicInfoObjectsThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Objects By Query Thunk
    builder.addCase(getObjectsByQueryThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getObjectsByQueryThunk.fulfilled, (state, action) => {
      state.objects = action.payload?.results || [];
      state.totalRecords = action.payload?.rowCount || 0;
      state.loading = false;
    });

    builder.addCase(getObjectsByQueryThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Specifc Object By ID Thunk
    builder.addCase(getSpecificObjectByIdThunk.pending, (state) => {
      state.specificObjectLoading = true;
    });

    builder.addCase(getSpecificObjectByIdThunk.fulfilled, (state, action) => {
      state.specificObject = action.payload.specificObject;
      state.alarmsConfig = action.payload.objectAlarmConfig;
      state.specificObjectLoading = false;
    });

    builder.addCase(getSpecificObjectByIdThunk.rejected, (state) => {
      state.specificObjectLoading = false;
    });

    // Get Object locations By ID Thunk
    builder.addCase(getObjectsLocationsThunk.pending, (state) => {
      state.loading = true;
      state.markers = [];
      state.shouldRenderMarkers = false;
    });

    builder.addCase(getObjectsLocationsThunk.fulfilled, (state, action) => {
      state.allObjects = action.payload;

      if (action.payload && action.payload.length > 0) {
        state.shouldRenderMarkers = true;
        state.markers = extractObjectsDataAsMarkersForMap(action.payload);
      }

      state.loading = false;
    });

    builder.addCase(getObjectsLocationsThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Object Rides by Query
    builder.addCase(getObjectRidesByQueryThunk.pending, (state) => {
      state.ridesLoading = true;
    });

    builder.addCase(getObjectRidesByQueryThunk.fulfilled, (state, action) => {
      state.objectRides = action.payload?.results || [];
      state.objectRidesRowCount = action.payload?.rowCount || 0;
      state.ridesLoading = false;
    });

    builder.addCase(getObjectRidesByQueryThunk.rejected, (state) => {
      state.ridesLoading = false;
    });

    // Get Specifc Ride Information
    builder.addCase(getObjectSpecificRideInfoThunk.pending, (state) => {
      state.ridesLoading = true;
    });

    builder.addCase(
      getObjectSpecificRideInfoThunk.fulfilled,
      (state, action) => {
        state.specificRide = action.payload;
        state.ridesLoading = false;
      }
    );

    builder.addCase(getObjectSpecificRideInfoThunk.rejected, (state) => {
      state.ridesLoading = false;
    });

    // Get Object Ride Locations
    builder.addCase(getObjectRideLocationsThunk.pending, (state) => {
      state.objectRideLocationsLoading = true;
    });

    builder.addCase(getObjectRideLocationsThunk.fulfilled, (state, action) => {
      state.objectRideLocations = action.payload;
      state.objectRideLocationsLoading = false;
    });

    builder.addCase(getObjectRideLocationsThunk.rejected, (state) => {
      state.objectRideLocationsLoading = false;
    });

    // Get Object Features Action Cases
    builder.addCase(getObjectFeaturesThunk.pending, (state) => {
      state.objectFeaturesLoading = true;
      state.objectFeaturesList = [];
      state.objectFeaturesConfig = featuresInitialData;
    });

    builder.addCase(getObjectFeaturesThunk.fulfilled, (state, action) => {
      state.objectFeaturesList = action.payload;

      action.payload.forEach((feature: any) => {
        switch (feature.uniqueId) {
          case "d5547222-a5b4-417a-8344-93e9084ec8e5": // Ride registration
            state.objectFeaturesConfig.isRidesEnabled = true;
            break;
        }
      });

      state.objectFeaturesLoading = false;
    });

    builder.addCase(getObjectFeaturesThunk.rejected, (state) => {
      state.objectFeaturesList = [];
      state.objectFeaturesConfig = featuresInitialData;
      state.objectFeaturesLoading = false;
    });
  },
});

export * from "./objectsActions";
export const {
  setSpecificObject,
  updateObjectLocation,
  updateObjectIcon,
  resetAllObjects,
  setObjectActiveTabIndex,
  setObjectSpecificRide,
  resetQueriedObjects,
  setActiveObjectId,
  setActiveObjectAsMarker,
} = objectsSlice.actions;

export default objectsSlice.reducer;
