import { FC, useEffect, useMemo, useState } from "react";
import { Box, useTheme } from "@mui/material";
import {
  Topbar,
  Table,
  useDispatchOnParams,
  toast,
} from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector, useReportCommonStates } from "hooks";
import { SCHEDULE_REPORTS_COLUMNS } from "app/data/constants";
import {
  createOrEditReportScheduleThunk,
  deleteReportScheduleThunk,
  getReportExportTypesThunk,
  getSpecificReportScheduleThunk,
  resetSpecificScheduleReport,
  searchReportScheduleThunk,
} from "app/features";
import {
  selectAccountKeysState,
  selectAccountsState,
  selectDrawerSelectedAccountId,
  selectScheduleReportsState,
  selectUsersState,
} from "app/data/selectors";
import { useTranslation } from "react-i18next";
import ScheduleReportsForm from "components/forms/Reports/ScheduleReportsForm";
import { FormikHelpers } from "formik";
import { convertTimeToISO, convertToUTC } from "app/data/helpers";
import { useNavigate } from "react-router-dom";
import { when } from "ramda";

const ScheduleReports: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const {
    allSchedules,
    loading,
    totalRecords,
    reportFrequency,
    reportExportTypes,
  } = useAppSelector(selectScheduleReportsState);

  const {
    objects,
    objectsLoading,
    objectTypes,
    handleObjectsSearch,
    basicObjects,
  } = useReportCommonStates();
  const { allUsers, loading: usersLoading } = useAppSelector(selectUsersState);
  const { accountKeys } = useAppSelector(selectAccountKeysState);

  const { usersBasicInfo } = useAppSelector(selectAccountsState);

  const drawerSelectedAccountId =
    useAppSelector(selectDrawerSelectedAccountId) || "";

  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const tableColumns = useMemo(() => SCHEDULE_REPORTS_COLUMNS(t), [t]);

  const handleUpdateSchedule = async (schedule: { uniqueId: string }) => {
    await dispatch(
      getSpecificReportScheduleThunk({
        accountId: drawerSelectedAccountId,
        uniqueId: schedule.uniqueId,
      })
    );

    setOpenModal(true);
  };

  const handlePauseSchedule = async (schedule: {
    uniqueId: string;
    status: number;
  }) => {
    let payload = {};
    if (schedule.status === 0) {
      payload = { ...schedule, status: 1 };
    } else {
      payload = { ...schedule, status: 0 };
    }
    const { type } = await dispatch(
      createOrEditReportScheduleThunk({
        accountId: drawerSelectedAccountId,
        data: payload,
      })
    );

    if (type === "scheduleReports/createOrEditReportScheduleThunk/fulfilled") {
      dispatch(
        searchReportScheduleThunk({
          accountId: drawerSelectedAccountId || "",
          params: {
            itemsPerPage: 10,
            orderBy: [{ field: "created", ascending: true }],
            page: 1,
          },
        })
      );
    } else {
      toast.error("Server Error");
    }
  };

  const handleDeleteSchedule = async (schedule: { uniqueId: string }) => {
    const { type } = await dispatch(
      deleteReportScheduleThunk({
        accountId: drawerSelectedAccountId,
        uniqueId: schedule.uniqueId,
      })
    );

    if (type === "scheduleReports/deleteReportScheduleThunk/fulfilled") {
      dispatch(
        searchReportScheduleThunk({
          accountId: drawerSelectedAccountId || "",
          params: {
            itemsPerPage: 10,
            orderBy: [{ field: "created", ascending: true }],
            page: 1,
          },
        })
      );
    } else {
      toast.error("Server Error");
    }
  };

  const scheduleReportsSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);
    const {
      object,
      objectType,
      reportType,
      key,
      daysOfWeek,
      startTime,
      endTime,
      deliveryTime,
      ...rest
    } = values;

    let objectsData = object;
    if (values.assignedToAllObjects) {
      objectsData = [];
      // objectsData = basicObjects.map((item) => item.uniqueId);
    }

    let daysOfWeekValue = daysOfWeek;
    if (values.deliveryFrequencyType === 0) {
      daysOfWeekValue = null;
    }

    const payload = {
      reportDefinition: {
        object: objectsData,
        objectType,
        reportType,
        key,
        daysOfWeek: daysOfWeekValue,
        startTime: convertTimeToISO(startTime),
        endTime: convertTimeToISO(endTime),
      },
      deliveryTime: convertToUTC(deliveryTime),
      ...rest,
    };

    const { type } = await dispatch(
      createOrEditReportScheduleThunk({
        accountId: drawerSelectedAccountId,
        data: payload,
      })
    );

    if (type === "scheduleReports/createOrEditReportScheduleThunk/fulfilled") {
      formikHelpers.setSubmitting(false);
      formikHelpers.resetForm();
      toast.success("Schedule Updated");
      setOpenModal(false);
      dispatch(
        searchReportScheduleThunk({
          accountId: drawerSelectedAccountId || "",
          params: {
            itemsPerPage: 10,
            orderBy: [{ field: "created", ascending: true }],
            page: 1,
          },
        })
      );
    } else {
      toast.error("Server Error");
    }
  };

  useDispatchOnParams(searchReportScheduleThunk, {
    args: {
      accountId: drawerSelectedAccountId,
    },
    orderBy: [{ field: "created", ascending: true }],
    searchByField: "body",
  });

  useEffect(() => {
    if (drawerSelectedAccountId !== null) {
      dispatch(getReportExportTypesThunk());
    }
  }, [drawerSelectedAccountId]);

  return (
    <Box>
      <Topbar
        breadcrumbTitle={"Reports"}
        breadcrumbRedirectTo={() => navigate("/dashboard/reports")}
        theme={theme}
        title={"Scheduled Reports"}
      />
      <Box mx={4}>
        <Table
          columns={tableColumns}
          data={allSchedules}
          progressPending={loading}
          paginationTotalRows={totalRecords}
          searchPlaceholder={t("common.search")}
          sortServer
          noTableHead={true}
          cellActions={[
            {
              name: `Edit`,
              onClick: handleUpdateSchedule,
            },
            {
              // name: "Pause",
              onClick: handlePauseSchedule,
              renderValue: (row: any) =>
                row.status === 1 ? "Pause" : "Resume",
            },
            {
              name: `Delete`,
              onClick: handleDeleteSchedule,
            },
          ]}
        />

        <ScheduleReportsForm
          open={openModal}
          onClose={() => {
            setOpenModal(false);
            dispatch(resetSpecificScheduleReport());
          }}
          objects={[...basicObjects, ...objects]}
          dataLoading={objectsLoading}
          objectTypes={objectTypes}
          fetchOnDebounce={handleObjectsSearch}
          users={[...usersBasicInfo]}
          usersLoading={usersLoading}
          onSubmit={scheduleReportsSubmitHandler}
          reportFrequency={reportFrequency}
          reportExportTypes={reportExportTypes}
          keys={accountKeys}
        />
      </Box>
    </Box>
  );
};

export default ScheduleReports;
