import { FC } from "react";
import { IPointOfInterest } from "app/data/types";
import { Stack } from "@mui/material";
import PointOfInterestCard, {
  PointOfInterestCardProps,
} from "./PointOfInterestCard";

type Props = Omit<PointOfInterestCardProps, "data"> & {
  records: IPointOfInterest[];
};

const PointOfInterestCardListing: FC<Props> = ({
  records,
  onEditHandler,
  onDeleteHandler,
}) => {
  return (
    <Stack gap={2}>
      {records &&
        records.map((item) => (
          <PointOfInterestCard
            data={item}
            onEditHandler={onEditHandler}
            onDeleteHandler={onDeleteHandler}
          />
        ))}
    </Stack>
  );
};

export default PointOfInterestCardListing;
