import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Button,
  styled,
} from "@mui/material";
import { CSVLink as CSVLinkBox } from "react-csv";

export const CSVLink = styled(CSVLinkBox)({
  display: "flex",
  fontWeight: 500,
  alignItems: "center",
  svg: {
    marginRight: "8px",
  },
});

export const PDFBox = styled(Box)({
  display: "flex",
  fontWeight: 500,
  alignItems: "center",
  svg: {
    marginRight: "8px",
  },
});

export const MuiButton = styled(Button)({
  display: "flex",
  color: "#845AFC",
  fontWeight: 500,
  alignItems: "center",
  svg: {
    marginRight: "8px",
  },
});

export const Accordion = styled(MuiAccordion)({
  expanded: {
    marginTop: "0px",
  },
});

export const AccordionSummary = styled(MuiAccordionSummary)({
  backgroundColor: "rgba(249, 251, 255, 1)",
  borderTop: "none",
  borderBottom: "none",
  flexDirection: "row-reverse",
});

export const AccordionDetailsBox = styled(Box)({
  width: "100%",
  maxWidth: "100%",
});

export const SummaryContentBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  paddingLeft: "8px",
});

export const RidesTextBox = styled(Box)({
  color: "rgba(0, 178, 163, 1)",
  fontWeight: 500,
});

export const CenterTextBox = styled(Box)({
  textAlign: "center",
});
