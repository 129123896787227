import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const selectObjectsReducerLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.loading;

export const selectAllTheObjects = (state: RootState) =>
  state.rootReducer.objectsReducer.allObjects;

export const selectAllBasicObjects = (state: RootState) =>
  state.rootReducer.objectsReducer.basicObjects;

export const selectObjectsQueried = (state: RootState) =>
  state.rootReducer.objectsReducer.objects;

export const selectObjectsTotalRecords = (state: RootState) =>
  state.rootReducer.objectsReducer.totalRecords;

export const selectSpecificObjectLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.specificObjectLoading;

export const selectSpecificObject = (state: RootState) =>
  state.rootReducer.objectsReducer.specificObject;

export const selectObjectActiveTabIndex = (state: RootState) =>
  state.rootReducer.objectsReducer.objectActiveTabIndex;

export const selectObjectRidesLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.ridesLoading;

export const selectObjectRidesQueried = (state: RootState) =>
  state.rootReducer.objectsReducer.objectRides;

export const selectObjectRidesRowCount = (state: RootState) =>
  state.rootReducer.objectsReducer.objectRidesRowCount;

export const selectObjectSpecificRide = (state: RootState) =>
  state.rootReducer.objectsReducer.specificRide;

export const selectObjectRideLocationsLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.objectRideLocationsLoading;

export const selectObjectRideLocations = (state: RootState) =>
  state.rootReducer.objectsReducer.objectRideLocations;

export const selectObjectAlarmsConfigLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.alarmsConfigLoading;

export const selectObjectAlarmsConfig = (state: RootState) =>
  state.rootReducer.objectsReducer.alarmsConfig;

export const selectActiveObjectId = (state: RootState) =>
  state.rootReducer.objectsReducer.activeObjectId;

export const selectObjectFeaturesLoading = (state: RootState) =>
  state.rootReducer.objectsReducer.objectFeaturesLoading;

export const selectObjectFeaturesList = (state: RootState) =>
  state.rootReducer.objectsReducer.objectFeaturesList;

export const selectObjectFeaturesConfig = (state: RootState) =>
  state.rootReducer.objectsReducer.objectFeaturesConfig;

export const selectActiveObjectAlarmsConfig = createSelector(
  selectObjectAlarmsConfigLoading,
  selectObjectAlarmsConfig,
  (alarmsConfigLoading, alarmsConfig) => ({
    alarmsConfigLoading,
    alarmsConfig,
  })
);

export const selectQueriedObjectsState = createSelector(
  selectObjectsReducerLoading,
  selectObjectsQueried,
  selectObjectsTotalRecords,
  selectSpecificObject,
  (loading, objects, totalRecords, specificObject) => ({
    loading,
    objects,
    totalRecords,
    specificObject,
  })
);

export const selectObjectsState = createSelector(
  selectAllTheObjects,
  selectObjectsReducerLoading,
  selectAllBasicObjects,
  (allObjects, loading, basicObjects) => ({
    allObjects,
    loading,
    basicObjects,
  })
);

export const selectSpecificObjectState = createSelector(
  selectSpecificObjectLoading,
  selectSpecificObject,
  selectActiveObjectId,
  (specificObjectLoading, specificObject, activeObjectId) => ({
    specificObjectLoading,
    specificObject,
    activeObjectId,
  })
);

export const selectObjectRidesState = createSelector(
  selectObjectRidesLoading,
  selectObjectRidesQueried,
  selectObjectRidesRowCount,
  selectObjectSpecificRide,
  selectObjectRideLocationsLoading,
  selectObjectRideLocations,
  (
    ridesLoading,
    objectRides,
    objectRidesRowCount,
    specificRide,
    objectRideLocationsLoading,
    objectRideLocations
  ) => ({
    ridesLoading,
    objectRides,
    objectRidesRowCount,
    specificRide,
    objectRideLocationsLoading,
    objectRideLocations,
  })
);

export const selectObjectFeaturesState = createSelector(
  selectObjectFeaturesLoading,
  selectObjectFeaturesList,
  selectObjectFeaturesConfig,
  (objectFeaturesLoading, objectFeaturesList, objectFeaturesConfig) => ({
    objectFeaturesLoading,
    objectFeaturesList,
    objectFeaturesConfig,
  })
);

// Object Map Selectors

export const selectObjectMapMarkers = (state: RootState) =>
  state.rootReducer.objectsReducer.markers;

export const selectObjectMapShouldRenderMarkers = (state: RootState) =>
  state.rootReducer.objectsReducer.shouldRenderMarkers;

export const selectObjectMapState = createSelector(
  selectObjectMapMarkers,
  selectObjectMapShouldRenderMarkers,
  (markers, shouldRenderMarkers) => ({ markers, shouldRenderMarkers })
);
