import { FC, Dispatch, SetStateAction } from "react";
import { Box, useTheme } from "@mui/material";
import { DateRangePicker, Button } from "@vilocnv/allsetra-core";
import { GroupBy } from "app/data/helpers";
import ReportDateFormats from "../ReportDateFormats/ReportDateFormats";
import ExportButtonMenu from "../../ExportButtonMenu/ExportButtonMenu";
import ScheduleReports from "../ScheduleReports/ScheduleReports";
import { useAppSelector } from "hooks";
import { selectAccountFeaturesState } from "app/data/selectors";

interface Props {
  setFilterOpen: (value: boolean) => void;
  dateFormat: string;
  setDateFormat: Dispatch<SetStateAction<GroupBy>>;
  handleDateChange: (value: any) => void;
  dateValues: any;
  handleDateRangeSubmit: () => void;
  dateRangeLoading: boolean;
  exportHeaders: Array<any>;
  generateCSVData?: () => any[];
  formattedData: any;
  generatePDF: () => void;
  hideExportButton?: boolean;
  hideScheduleButton?: boolean;
  disableExportButton?: boolean;
  exportButtonLoading?: boolean;
  reportType?: number;
  reportExportTypeValue?: number;
}

const ReportsFilterBar: FC<Props> = ({
  setFilterOpen,
  dateFormat,
  setDateFormat,
  handleDateChange,
  dateValues,
  handleDateRangeSubmit,
  dateRangeLoading,
  exportHeaders,
  generateCSVData,
  formattedData,
  generatePDF,
  hideExportButton = false,
  disableExportButton = false,
  exportButtonLoading = false,
  hideScheduleButton = false,
  reportType,
  reportExportTypeValue,
}) => {
  const theme = useTheme();

  const { features } = useAppSelector(selectAccountFeaturesState);

  return (
    <Box my={2} display={"flex"} justifyContent={"space-between"}>
      <Box display={"flex"}>
        <Button
          id="filter-button"
          sx={{ mr: 2 }}
          text="Filter"
          size="small"
          theme={theme}
          onClick={() => setFilterOpen(true)}
        />
        <ReportDateFormats
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
        />
        <DateRangePicker
          name="datepicker"
          onChange={handleDateChange}
          value={dateValues}
          onSubmit={handleDateRangeSubmit}
          disabled={dateRangeLoading ? true : false}
        />
      </Box>
      <Box sx={{ display: "flex", gap: "30px" }}>
        {!hideScheduleButton && features.isAutomaticReportsEnabled ? (
          <ScheduleReports
            reportType={reportType}
            reportExportTypeValue={reportExportTypeValue}
          />
        ) : (
          <></>
        )}
        {!hideExportButton ? (
          <ExportButtonMenu
            tableHeaders={exportHeaders}
            generateCSVData={generateCSVData ? generateCSVData() : undefined}
            formattedData={formattedData}
            generatePDF={generatePDF}
            disabled={disableExportButton}
            exportButtonLoading={exportButtonLoading}
          />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};
export default ReportsFilterBar;
