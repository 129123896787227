import { styled, Box, Button } from "@mui/material";

export const TopbarContainer = styled(Box)({
  width: "100%",
  height: "80px",
  background: "#FFF",
  boxShadow: "0px 4px 8px rgba(0,0,0,.25)",
  position: "absolute",
  zIndex: 999,
});

export const IconsWrapper = styled(Box)({
  flex: 2,
  paddingLeft: 2,
  alignItems: "center",
  display: "flex",
});

export const ToggleIconsBox = styled(Box)({
  padding: 0.25,
  border: "1px solid #DFE5F4",
  backgroundColor: "#EFF4FF",
  display: "inline-flex",
  borderRadius: "4px",
});

export const ToggleButton = styled(Button)({
  color: "black",
  padding: "6px 12px",
});
