import { FC, useState, useCallback, useMemo } from "react";
import { useDebouncedCallback } from "use-debounce";
import { SelectInputField } from "@vilocnv/allsetra-core";
import { selectDashboardGooglePlacesState } from "app/data/selectors";

// Data
import { isEmpty } from "lodash";
import { useAppDispatch, useAppSelector } from "hooks";
import { getGooglePlacesThunk } from "app/features";

export interface IPlaceLocation {
  resolvedAddress: string;
  lat: number;
  long: number;
}

export interface PlacesSearchFieldProps {
  onLocationChange: (value: IPlaceLocation) => void;
  label?: string;
  initialSelectedPlace?: string;
  fullWidth?: boolean;
}

const PlacesSearchField: FC<PlacesSearchFieldProps> = ({
  onLocationChange,
  fullWidth = false,
  initialSelectedPlace,
  ...rest
}) => {
  const dispatch = useAppDispatch();

  const [selectedPlace, setSelectedPlace] = useState<string>(
    initialSelectedPlace ?? ""
  );
  const [searchText, setSearchText] = useState<string>("");

  const { googlePredictatedPlacesLoading, googlePredictatedPlaces } =
    useAppSelector(selectDashboardGooglePlacesState);

  const debouncedHandleOnChange = useDebouncedCallback((event) => {
    if (isEmpty(searchText)) return;
    dispatch(getGooglePlacesThunk(searchText));
  }, 300);

  const handleChange = useCallback(
    (value: string) => {
      setSearchText(value);
      debouncedHandleOnChange(value);
    },
    [debouncedHandleOnChange]
  );

  const options = useMemo(() => {
    return !isEmpty(googlePredictatedPlaces) &&
      Array.isArray(googlePredictatedPlaces)
      ? googlePredictatedPlaces.map((place: any) => ({
          label: place.resolvedAddress,
          location: JSON.stringify(place.resolvedLocation),
        }))
      : [];
  }, [googlePredictatedPlaces]);

  const onChange = (value: any) => {
    setSelectedPlace(value);
    const resolvedAddress =
      options.find((opt) => opt.location === value)?.label || "";
    const data = { ...JSON.parse(value), resolvedAddress };
    if (onLocationChange) onLocationChange(data);
  };

  return (
    <SelectInputField
      name="name"
      value={selectedPlace}
      placeholder="Search locations"
      options={options}
      optionLabelKey={"label"}
      optionValueKey={"location"}
      onSearchTextChange={handleChange}
      onChange={onChange}
      loading={googlePredictatedPlacesLoading}
      searchable
      fullWidth={fullWidth}
      {...rest}
    />
  );
};

export default PlacesSearchField;
