import { createSlice } from "@reduxjs/toolkit";
import {
  createOrEditReportScheduleThunk,
  deleteReportScheduleThunk,
  getAllReportScheduleThunk,
  getReportExportTypesThunk,
  getReportFrequencyThunk,
  getSpecificReportScheduleThunk,
  searchReportScheduleThunk,
} from "./scheduleReportsActions";

export interface IScheduleReportsState {
  loading: boolean;
  allSchedules: Array<any>;
  specificSchedule: any;
  reportFrequency: Array<any>;
  totalRecords: number;
  reportExportTypes: Array<any>;
}

const initialState: IScheduleReportsState = {
  loading: false,
  allSchedules: [],
  specificSchedule: null,
  reportFrequency: [],
  totalRecords: 0,
  reportExportTypes: [],
};

const scheduleReportsSlice = createSlice({
  name: "scheduleReports",
  initialState,
  reducers: {
    resetScheduleReports: (state) => {
      state.allSchedules = [];
      state.loading = false;
    },
    resetSpecificScheduleReport: (state) => {
      state.specificSchedule = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrEditReportScheduleThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createOrEditReportScheduleThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createOrEditReportScheduleThunk.rejected, (state) => {
      state.loading = false;
    });
    // Get Reports Action Cases
    builder.addCase(getAllReportScheduleThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllReportScheduleThunk.fulfilled,
      (state, action: any) => {
        state.allSchedules = action.payload || [];
        state.loading = false;
      }
    );
    builder.addCase(getAllReportScheduleThunk.rejected, (state) => {
      state.loading = false;
    });

    // get Specific Schedule report
    builder.addCase(getSpecificReportScheduleThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getSpecificReportScheduleThunk.fulfilled,
      (state, action: any) => {
        state.specificSchedule = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(getSpecificReportScheduleThunk.rejected, (state) => {
      state.loading = false;
    });

    // get Specific Schedule report
    builder.addCase(getReportFrequencyThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getReportFrequencyThunk.fulfilled, (state, action: any) => {
      state.reportFrequency = action.payload;
      state.loading = false;
    });
    builder.addCase(getReportFrequencyThunk.rejected, (state) => {
      state.loading = false;
    });

    // search reports schedule
    builder.addCase(searchReportScheduleThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      searchReportScheduleThunk.fulfilled,
      (state, action: any) => {
        state.allSchedules = action.payload.results?.filter(
          (item: any) => !item?.isDeleted
        );
        state.totalRecords = action.payload.rowCount || 0;
        state.loading = false;
      }
    );
    builder.addCase(searchReportScheduleThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteReportScheduleThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteReportScheduleThunk.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteReportScheduleThunk.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getReportExportTypesThunk.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getReportExportTypesThunk.fulfilled,
      (state, action: any) => {
        state.reportExportTypes = action.payload;
        state.loading = false;
      }
    );
    builder.addCase(getReportExportTypesThunk.rejected, (state) => {
      state.loading = false;
    });
  },
});

export * from "./scheduleReportsActions";
export const { resetScheduleReports, resetSpecificScheduleReport } =
  scheduleReportsSlice.actions;
export default scheduleReportsSlice.reducer;
