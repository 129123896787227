import { createAsyncThunk } from "@reduxjs/toolkit";
import { ScheduleReportsService } from "app/data/services";
import { toast, types } from "@vilocnv/allsetra-core";

export const createOrEditReportScheduleThunk = createAsyncThunk(
  "scheduleReports/createOrEditReportScheduleThunk",
  async ({ accountId, data }: { accountId: string; data: any }) => {
    try {
      const response = data.uniqueId
        ? await ScheduleReportsService.editReportSchedule(
            accountId,
            data.uniqueId,
            data
          )
        : await ScheduleReportsService.createReportSchedule(accountId, data);

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAllReportScheduleThunk = createAsyncThunk(
  "scheduleReports/getAllReportScheduleThunk",
  async (accountId: string) => {
    try {
      const response = await ScheduleReportsService.getAllReportSchedule(
        accountId
      );

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getSpecificReportScheduleThunk = createAsyncThunk(
  "scheduleReports/getSpecificReportScheduleThunk",
  async ({ accountId, uniqueId }: { accountId: string; uniqueId: string }) => {
    try {
      const response = await ScheduleReportsService.getSpecificReportSchedule(
        accountId,
        uniqueId
      );

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getReportFrequencyThunk = createAsyncThunk(
  "scheduleReports/getReportFrequencyThunk",
  async () => {
    try {
      const response = await ScheduleReportsService.getReportFrequency();

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getReportExportTypesThunk = createAsyncThunk(
  "scheduleReports/getReportExportTypesThunk",
  async () => {
    try {
      const response = await ScheduleReportsService.getReportExportTypes();

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const searchReportScheduleThunk = createAsyncThunk(
  "scheduleReports/searchReportScheduleThunk",
  async ({
    accountId,
    params,
  }: {
    accountId: string;
    params?: types.IRecordsAggregationBody;
  }) => {
    try {
      const response = await ScheduleReportsService.searchReportSchedule(
        accountId,
        params
      );

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const deleteReportScheduleThunk = createAsyncThunk(
  "scheduleReports/deleteReportScheduleThunk",
  async ({ accountId, uniqueId }: { accountId: string; uniqueId: string }) => {
    try {
      const response = await ScheduleReportsService.deleteReportSchedule(
        accountId,
        uniqueId
      );

      if (response.status !== 202) {
        toast.error("Server side error occured.");
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
