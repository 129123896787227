import { FC, useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import {
  ChangePasswordForm,
  ContentSectionLayout,
  FormikInputField,
  FormikToggleField,
  SafetyQuestionForm,
  toast,
  TwoColsLayout,
  types,
} from "@vilocnv/allsetra-core";
import { LockedIcon, MarkerIcon } from "assets/icons";
import { ChangePasswordContatiner } from "../UpdateSettingsForm.styled";
import { useAppDispatch, useAppSelector } from "hooks";
import { FormikHelpers, useFormikContext } from "formik";
import { getAllSafetyQuestionsThunk, resetPasswordThunk } from "app/features";
import { selectSettingsState } from "app/data/selectors";
import { useTranslation } from "react-i18next";
import { SignalRService } from "app/data/services";

const PersonalInfoForm: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const formik = useFormikContext();

  //Local State
  const [open, setOpen] = useState(false); // Boolean state for ChangePasswordForm Modal
  const [isSubmittingPassword, setIsSubmittingPassword] = useState(false);
  const [openSafetyForm, setOpenSafetyForm] = useState(false);
  const { safetyQuestions } = useAppSelector(selectSettingsState);

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  useEffect(() => {
    dispatch(getAllSafetyQuestionsThunk());
  }, []);

  const handleChangePassword = async (
    values: types.IChangePassword,
    formikHelpers: FormikHelpers<types.IChangePassword>
  ) => {
    setIsSubmittingPassword(true);
    formikHelpers.setSubmitting(true);

    const { type } = await dispatch(
      resetPasswordThunk({ password: values.password })
    );

    if (type === "settings/resetPasswordThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.UserPasswordResetEvent
        ) {
          setOpen(false);
          setIsSubmittingPassword(false);
          formikHelpers.setSubmitting(false);
          toast.success("Your password has been updated successfully.");
        }
      });
    } else {
      setIsSubmittingPassword(false);
      formikHelpers.setSubmitting(false);
    }
  };

  const handleChangeSafetyQuestion = async (
    values: types.ISafetyQuestion,
    formikHelpers: FormikHelpers<types.ISafetyQuestion>
  ) => {
    formikHelpers.setSubmitting(true);

    const { securityQuestionId, securityQuestionResponse } = values;

    const foundQuestion = safetyQuestions?.find(
      (question) => question.uniqueId === securityQuestionId
    );

    formik.setFieldValue("securityQuestionId", securityQuestionId);
    formik.setFieldValue("securityQuestionResponse", securityQuestionResponse);
    formik.setFieldValue("securityQuestion", foundQuestion.question);

    setOpenSafetyForm(false);

    formikHelpers.setSubmitting(false);
  };

  return (
    <Box>
      <ContentSectionLayout
        title={t("titles.personalInfo")}
        subTitle={t("descriptions.personalInfo")}
      >
        <Box>
          <TwoColsLayout>
            <FormikInputField
              label={t("userNumber.label", { ns: "formFieldsTranslation" })}
              name="uniqueId"
              fullWidth
              disabled
              endIcon={<LockedIcon />}
            />
            <FormikInputField
              label={t("currentRole.label", { ns: "formFieldsTranslation" })}
              name="role"
              fullWidth
              disabled
              endIcon={<LockedIcon />}
            />
          </TwoColsLayout>

          <TwoColsLayout>
            <FormikInputField
              label={t("firstName.label", { ns: "formFieldsTranslation" })}
              name="firstName"
              fullWidth
            />
            <FormikInputField
              label={t("lastName.label", { ns: "formFieldsTranslation" })}
              name="lastName"
              fullWidth
            />
          </TwoColsLayout>

          <TwoColsLayout hideDivider>
            <FormikInputField
              label={t("email.label", { ns: "formFieldsTranslation" })}
              name="email"
              fullWidth
              disabled
              endIcon={<LockedIcon />}
            />
            <FormikInputField
              label={t("phoneNumber.label", { ns: "formFieldsTranslation" })}
              name="phone"
              fullWidth
            />
          </TwoColsLayout>
          <Box mt={2}>
            <FormikToggleField
              label={t("emailNotifications.label", {
                ns: "formFieldsTranslation",
              })}
              name="isEmailNotificationEnabled"
            />
          </Box>

          <TwoColsLayout>
            <ChangePasswordContatiner onClick={() => setOpen(true)}>
              <MarkerIcon />
              <p>
                {t("changePassword.label", {
                  ns: "formFieldsTranslation",
                })}
              </p>
            </ChangePasswordContatiner>
          </TwoColsLayout>

          <TwoColsLayout hideDivider>
            <FormikInputField
              label={t("securityQuestion.label", {
                ns: "formFieldsTranslation",
              })}
              name="securityQuestion"
              fullWidth
              disabled
            />
            <FormikInputField
              label={t("securityQuestionResponse.label", {
                ns: "formFieldsTranslation",
              })}
              name="securityQuestionResponse"
              fullWidth
              disabled
            />
          </TwoColsLayout>
          <TwoColsLayout hideDivider>
            <ChangePasswordContatiner onClick={() => setOpenSafetyForm(true)}>
              <MarkerIcon />
              <Box>
                {t("changeSafetyQuestion.label", {
                  ns: "formFieldsTranslation",
                })}
              </Box>
            </ChangePasswordContatiner>
          </TwoColsLayout>
        </Box>
      </ContentSectionLayout>
      <ChangePasswordForm
        open={open}
        onClose={() => setOpen(false)}
        theme={theme}
        onSubmit={handleChangePassword}
        submitting={isSubmittingPassword}
        translator={t}
      />
      <SafetyQuestionForm
        open={openSafetyForm}
        onClose={() => setOpenSafetyForm(false)}
        theme={theme}
        onSubmit={handleChangeSafetyQuestion}
        safetyQuestions={safetyQuestions}
        translator={t}
      />
    </Box>
  );
};

export default PersonalInfoForm;
