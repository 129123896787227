import { FC, useEffect, useMemo } from "react";
import { Stack } from "@mui/material";
import {
  ContentSectionLayout,
  TwoColsLayout,
  types,
  Button,
  FormikSelectField,
} from "@vilocnv/allsetra-core";
import { ChildFormBox } from "../ObjectSettingsForm.styled";

import {
  selectDrawerSelectedAccountId,
  selectUsersState,
} from "app/data/selectors";
import { useAppDispatch, useAppSelector } from "hooks";
import { getAllUsersThunk } from "app/features";
import { useTranslation } from "react-i18next";

interface Props {
  activeObject: any | null;
}

const AssignUserSetting: FC<Props> = ({ activeObject }) => {
  const dispatch = useAppDispatch();

  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);
  const { allUsers, loading: usersLoading } = useAppSelector(selectUsersState);

  const { t } = useTranslation(["translation", "formFieldsTranslation"]);

  const activeObjectUsers = useMemo(() => {
    return activeObject?.users?.length
      ? activeObject.users.map((item: any) => item.user)
      : [];
  }, [activeObject?.users]);

  useEffect(() => {
    dispatch(getAllUsersThunk(drawerSelectedAccountId || ""));
  }, []);

  return (
    <ContentSectionLayout
      title={t("titles.assignUser")}
      subTitle={t("descriptions.assignUser")}
    >
      <ChildFormBox>
        <TwoColsLayout hideDivider>
          <FormikSelectField
            label={t("assignedUserEmail.label", {
              ns: "formFieldsTranslation",
            })}
            name="users"
            options={[...activeObjectUsers, ...allUsers] ?? []}
            optionLabelKey={"email"}
            optionValueKey={"uniqueId"}
            loading={usersLoading}
            searchable
            multiple
            fullWidth
          />
        </TwoColsLayout>
        {/* <Stack mt={4} direction={"row"} spacing={2}>
          <Button variant={"contained"} size={"small"}>
            Assign User
          </Button>
          <Button variant={"text"} size={"small"}>
            Cancel
          </Button>
        </Stack> */}
      </ChildFormBox>
    </ContentSectionLayout>
  );
};

export default AssignUserSetting;
