import { Box, styled } from "@mui/material";

export const CardContainer = styled(Box)({
  width: "100%",
  height: "80px",
  padding: "12px 12px 12px 16px",
  borderRadius: "6px",
  border: "1px solid #DFE5F4",
  background: "#FFF",
});

export const TitleTypo = styled("h2")({
  fontFamily: "Gilroy",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "136%",
});
