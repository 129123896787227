import { TableColumn, StatusBadge, types } from "@vilocnv/allsetra-core";
import moment from "moment";

export const ACCOUNT_KEYS_TABLE_COLUMNS = (
  translator: any
): TableColumn<types.IKey>[] => [
  {
    name: translator("tableHeading.label", {
      ns: "tableHeadingsTranslation",
    }),
    selector: (row: types.IKey) => row.label || "",
    sortable: true,
  },
  {
    name: translator("tableHeading.keyID", {
      ns: "tableHeadingsTranslation",
    }),
    selector: (row: types.IKey) => row.keyId || "",
    sortable: true,
  },
  {
    name: translator("tableHeading.status", {
      ns: "tableHeadingsTranslation",
    }),
    cell: (row: types.IKey) => <StatusBadge isDeactivated={!row.isActive} />,
    sortable: true,
  },
  {
    name: translator("tableHeading.activationTimeStamp", {
      ns: "tableHeadingsTranslation",
    }),
    selector: (row: types.IKey) =>
      row.activationDate
        ? moment(row.activationDate).format("YYYY-MM-DD HH:mm:ss")
        : "N/A",
    sortable: true,
  },
  {
    name: translator("tableHeading.deactivationTimeStamp", {
      ns: "tableHeadingsTranslation",
    }),
    selector: (row: types.IKey) =>
      row.deactivationDate
        ? moment(row.deactivationDate).format("YYYY-MM-DD HH:mm:ss")
        : "N/A",
    sortable: true,
  },
];
