import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { types } from "@vilocnv/allsetra-core";
import {
  getAllGeozonesThunk,
  getSpecificGeozoneThunk,
  getGeozoneObjectsThunk,
  getGeozoneAssignedObjectsThunk,
  getAccountGeozones,
  getAddressThunk,
} from "./geozonesActions";

export interface IGeozonesState {
  loading: boolean;
  allGeozones: Array<types.IGeozone>;
  activeGeozoneId: string | null;
  activeGeozone: types.IGeozone | null;
  activeGeozoneObjects: Array<any>;
  accountGeozoneObjects: Array<any>;
  accountGeozoneObjectsLoading: boolean;

  geozoneAssignedObjects: Array<any>;
  geozoneAssignedObjectsLoading: boolean;
  addressFromLatLng: string;
}

const initialState: IGeozonesState = {
  loading: false,
  allGeozones: [],
  activeGeozoneId: null,
  activeGeozone: null,
  activeGeozoneObjects: [],
  geozoneAssignedObjects: [],
  geozoneAssignedObjectsLoading: false,

  accountGeozoneObjects: [],
  accountGeozoneObjectsLoading: false,
  addressFromLatLng: "",
};

const geozonesSlice = createSlice({
  name: "geozones",
  initialState,
  reducers: {
    setActiveGeozoneId: (state, action: PayloadAction<string | null>) => {
      state.activeGeozoneId = action.payload;
    },
    setActiveGeozone: (state, action: PayloadAction<types.IGeozone | null>) => {
      state.activeGeozone = action.payload;
    },
    addGeozoneReducer: (state, action: PayloadAction<any>) => {
      state.allGeozones.push(action.payload);
      state.activeGeozoneId = action.payload.uniqueId;
    },
    removeGeozoneReducer: (state, action: PayloadAction<string>) => {
      state.allGeozones.filter(
        (geozone) => geozone.uniqueId === action.payload
      );
      state.activeGeozoneId = null;
    },
    removeTemporaryGeozones: (state) => {
      const filteredItems = state.allGeozones.filter(
        (geozone) => geozone.name !== ""
      );
      state.allGeozones = filteredItems;
    },
    updateGeozoneGeoJsonById: (
      state,
      action: PayloadAction<{ id: string; geoJson: string }>
    ) => {
      const geozone = state.allGeozones.find(
        (geo) => geo.uniqueId === action.payload.id
      );

      if (geozone) {
        geozone.geoJson = action.payload.geoJson;
      }
    },
  },
  extraReducers: (builder) => {
    // Get All Geozones Action Cases
    builder.addCase(getAllGeozonesThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getAllGeozonesThunk.fulfilled, (state, action) => {
      state.allGeozones = action.payload;
      state.loading = false;
    });

    builder.addCase(getAllGeozonesThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Specfic Gezone Action Cases
    builder.addCase(getSpecificGeozoneThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSpecificGeozoneThunk.fulfilled, (state, action) => {
      state.activeGeozone = action.payload;
      state.loading = false;
    });

    builder.addCase(getSpecificGeozoneThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Specfic Gezone Action Cases
    builder.addCase(getGeozoneObjectsThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getGeozoneObjectsThunk.fulfilled, (state, action) => {
      state.activeGeozoneObjects = action.payload;
      state.loading = false;
    });

    builder.addCase(getGeozoneObjectsThunk.rejected, (state) => {
      state.loading = false;
    });

    // Get Geozne Assigned Objects Action Cases
    builder.addCase(getGeozoneAssignedObjectsThunk.pending, (state) => {
      state.geozoneAssignedObjectsLoading = true;
    });

    builder.addCase(
      getGeozoneAssignedObjectsThunk.fulfilled,
      (state, action) => {
        state.geozoneAssignedObjects = action.payload;
        state.geozoneAssignedObjectsLoading = false;
      }
    );

    builder.addCase(getGeozoneAssignedObjectsThunk.rejected, (state) => {
      state.geozoneAssignedObjectsLoading = false;
    });

    // Get account geozones
    builder.addCase(getAccountGeozones.pending, (state) => {
      state.accountGeozoneObjectsLoading = true;
      state.accountGeozoneObjects = [];
    });

    builder.addCase(getAccountGeozones.fulfilled, (state, action) => {
      state.accountGeozoneObjectsLoading = false;
      state.accountGeozoneObjects = action.payload || [];
    });

    builder.addCase(getAccountGeozones.rejected, (state) => {
      state.accountGeozoneObjectsLoading = false;
      state.accountGeozoneObjects = [];
    });

    //Address state
    builder.addCase(getAddressThunk.pending, (state) => {
      state.addressFromLatLng = "";
    });

    builder.addCase(getAddressThunk.fulfilled, (state, action) => {
      state.addressFromLatLng = action?.payload?.data?.resolvedAddress || "";
    });

    builder.addCase(getAddressThunk.rejected, (state) => {
      state.addressFromLatLng = "";
    });
  },
});

export * from "./geozonesActions";
export const {
  setActiveGeozoneId,
  setActiveGeozone,
  addGeozoneReducer,
  removeGeozoneReducer,
  updateGeozoneGeoJsonById,
  removeTemporaryGeozones,
} = geozonesSlice.actions;

export default geozonesSlice.reducer;
