import { createAsyncThunk } from "@reduxjs/toolkit";
import { AccountsService } from "app/data/services";
import { toast } from "@vilocnv/allsetra-core";
import { isEmpty } from "lodash";

export const getAllAccountsThunk = createAsyncThunk(
  "accounts/getAllAccountsThunk",
  async () => {
    try {
      const response = await AccountsService.getAllAccounts();

      if (response.status === 200) {
        return {
          data: response.data,
          tenantIdExists: !!response.config.headers["X-TenantId"],
        };
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const createAccountThunk = createAsyncThunk(
  "accounts/createAccountThunk",
  async ({ accountId, data }: any) => {
    try {
      if (isEmpty(accountId)) {
        toast.error("Kindly first select an account from the drawer.");
        return;
      }

      const response = await AccountsService.createAccount(accountId, data);

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const updateAccountThunk = createAsyncThunk(
  "accounts/updateAccountThunk",
  async ({ accountId, data }: any) => {
    try {
      const response = await AccountsService.updateAccount(accountId, data);

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const deactivateAccountThunk = createAsyncThunk(
  "accounts/deactivateAccountThunk",
  async (accountId: string) => {
    try {
      const response = await AccountsService.deactivateAccount(accountId);

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const activateAccountThunk = createAsyncThunk(
  "accounts/activateAccountThunk",
  async (accountId: string) => {
    try {
      const response = await AccountsService.reactivateAccount(accountId);

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const getSpecificAccountThunk = createAsyncThunk(
  "accounts/getSpecificAccountThunk",
  async (accountId: string) => {
    try {
      const response = await AccountsService.getSpecificAccount(accountId);

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAccountsIndustriesThunk = createAsyncThunk(
  "accounts/getAccountsIndustriesThunk",
  async () => {
    try {
      const response = await AccountsService.getAccountsIndustries();

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAccountsTypesThunk = createAsyncThunk(
  "accounts/getAccountsTypesThunk",
  async () => {
    try {
      const response = await AccountsService.getAccountsTypes();

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAllAccountGroupsThunk = createAsyncThunk(
  "accounts/getAllAccountGroupsThunk",
  async (accountId: string) => {
    try {
      const response = await AccountsService.getAllAccountGroups(accountId);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const createOrUpdateAccountGroupsThunk = createAsyncThunk(
  "accounts/createOrUpdateAccountGroupsThunk",
  async ({ accountId, values, groupId }: any) => {
    try {
      const response = values.uniqueId
        ? await AccountsService.updateAccountGroups(accountId, groupId, values)
        : await AccountsService.createAccountGroups(accountId, values);

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const removeGroupFromAccountThunk = createAsyncThunk(
  "accounts/removeGroupFromAccountThunk",
  async ({ accountId, groupId }: any) => {
    try {
      const response = await AccountsService.removeGroupFromAccount(
        accountId,
        groupId
      );

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const getSpecificAccountGroupThunk = createAsyncThunk(
  "accounts/getSpecificAccountGroupThunk",
  async ({ accountId, groupId }: any) => {
    try {
      const response = await AccountsService.getSpecificAccountGroup(
        accountId,
        groupId
      );

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const getAllAccountObjectsThunk = createAsyncThunk(
  "accounts/getAllAccountObjectsThunk",
  async (accountId: string) => {
    try {
      const response = await AccountsService.getAllAccountObjects(accountId);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getSpecificAccountUserThunk = createAsyncThunk(
  "accounts/getSpecificAccountUserThunk",
  async ({ accountId, userId }: any) => {
    try {
      const response = await AccountsService.getSpecificAccountUser(
        accountId,
        userId
      );

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAccountPointsOfInterestsThunk = createAsyncThunk(
  "accounts/getAccountPointsOfInterestsThunk",
  async (accountId: string) => {
    try {
      const response = await AccountsService.getAccountPointsOfInterests(
        accountId
      );

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAccountFeaturesThunk = createAsyncThunk(
  "accounts/getAccountFeaturesThunk",
  async (accountId: string) => {
    try {
      const response = await AccountsService.getAccountFeatures(accountId);

      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getUsersBasicInfoThunk = createAsyncThunk(
  "accounts/getUsersBasicInfoThunk",
  async (accountId: string) => {
    try {
      const response = await AccountsService.getUsersBasicInfo(accountId);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
