import { FC, useEffect, useState, useRef } from "react";
import { Box, CircularProgress, useTheme } from "@mui/material";
import { FormikHelpers } from "formik";
import jsPDF from "jspdf";
import AllsetraPDFImage from "../../../assets/images/AllsetraPDFImage.jpg";
import ReportsFilterBar from "components/common/reports/ReportFiltersBar/ReportsFilterBar";
import ReportsTopbar from "components/common/reports/ReportsTopbar/ReportsTopbar";
import WorkingHoursFilterForm from "components/forms/Reports/WorkingHoursFilterForm";

import { GraphicalWorkingHoursReport } from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector, useReportCommonStates } from "hooks";
import {
  selectAccountKeysState,
  selectWhiteLabelsState,
} from "app/data/selectors";
import {
  generateReportThunk,
  getAllKeysByAccountThunk,
  resetReports,
} from "app/features";
import {
  convertToNormalTime,
  formattedWorkingHours,
  reportTransformTimeForAPI,
} from "app/data/helpers";
import { WORKING_HOURS_HEADERS } from "app/data/constants";
import { useTranslation } from "react-i18next";
import { CenterTextBox } from "./Reports.styled";
import {
  generateFormattedGraphicalWorkingHoursPdf,
  restructureWorkingHoursData,
} from "app/data/helpers/ReportHelpers/graphicalReportHelpers";
import ChartsAccordion from "components/reports/ChartsAccordion/ChartsAccordion";
import { isEmpty } from "lodash";

const DEFAULT_FILTER_VALUES = {
  key: [],
  group: [],
  object: [],
  objectType: [],
};

const GraphicalWorkingHours: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    drawerSelectedAccountId,
    objectTypes,
    objectTypesLoading,
    objectsLoading,
    accountGroups,
    report,
    reportsLoading,
    filterOpen,
    setFilterOpen,
    dateFormat,
    setDateFormat,
    dateValues,
    setDateValues,
    localReport,
    setLocalReport,
    handleObjectsSearch,
    selectedLocalObjects,
    handleOnChangeReportFilterForm,
  } = useReportCommonStates();

  const [filterValues, setFilterValues] = useState<any>(DEFAULT_FILTER_VALUES);

  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);
  const [expandedFirstLevel, setExpandedFirstLevel] = useState<string[]>([]);
  const [expandedSecondLevel, setExpandedSecondLevel] = useState<string[]>([]);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);

  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );

  const { whiteLabel } = useAppSelector(selectWhiteLabelsState);

  useEffect(() => {
    dispatch(resetReports());
  }, []);

  useEffect(() => {
    setLocalReport(report);
  }, [report]);

  const formattedData = formattedWorkingHours(localReport, dateFormat);

  useEffect(() => {
    if (drawerSelectedAccountId !== null) {
      dispatch(getAllKeysByAccountThunk(drawerSelectedAccountId));
    }
  }, [drawerSelectedAccountId]);

  const workingHoursFiltersSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);

    const startDate = new Date(dateValues.startDate);
    const endDate = new Date(dateValues.endDate);

    const sameDate = startDate.toDateString() === endDate.toDateString();

    if (sameDate) {
      startDate.setHours(0, 0, 0);
      endDate.setHours(23, 59, 59);
    }

    const formattedStartDate = convertToNormalTime(startDate);
    const formattedEndDate = convertToNormalTime(endDate, true);
    setFilterValues(values);

    const payload = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      ...values,
      reportType: 3,
      daysOfWeek: values.daysOfWeek.map(Number),
      startTime: reportTransformTimeForAPI(values.startTime + ":00", startDate),
      endTime: reportTransformTimeForAPI(values.endTime + ":00", endDate),
    };

    await dispatch(generateReportThunk(payload));

    formikHelpers.setSubmitting(false);
    setFilterOpen(false);
  };

  const generateGraphicalRidesPDF = async () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.text("Graphical Working Hours Report", 100, 10);

    if (isEmpty(whiteLabel)) {
      doc.addImage(
        AllsetraPDFImage,
        "JPEG",
        doc.internal.pageSize.width - 50,
        10,
        40,
        30
      );
    }

    await generateFormattedGraphicalWorkingHoursPdf(doc, restructuredData);

    doc.save("Graphical Working Hours Report.pdf");
    setPdfLoading(false);
  };

  const handleDateChange = (value: any) => {
    setDateValues(value);
  };

  const handleDateRangeSubmit = () => {
    const startDate = convertToNormalTime(dateValues.startDate);
    const endDate = convertToNormalTime(dateValues.endDate, true);

    let updatedFilterValues = { ...filterValues };
    if (filterValues.daysOfWeek) {
      updatedFilterValues = {
        ...filterValues,
        daysOfWeek: filterValues.daysOfWeek.map(Number),
        startTime: reportTransformTimeForAPI(
          filterValues.startTime + ":00",
          dateValues.startDate
        ),
        endTime: reportTransformTimeForAPI(
          filterValues.endTime + ":00",
          dateValues.endDate
        ),
      };
    }

    const payload = {
      startDate,
      endDate,
      ...updatedFilterValues,
      reportType: 3,
    };

    dispatch(generateReportThunk(payload));
  };

  let formObjects = [...selectedLocalObjects];

  //@ts-ignore
  const restructuredData = restructureWorkingHoursData(formattedData);

  useEffect(() => {
    const updateParentWidth = () => {
      if (parentRef.current) {
        //@ts-ignore
        const width = parentRef.current.clientWidth;
        setParentWidth(width);
      }
    };

    updateParentWidth();

    window.addEventListener("resize", updateParentWidth);

    return () => {
      window.removeEventListener("resize", updateParentWidth);
    };
  }, [report, formattedData]);

  const handleFirstLevelAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedFirstLevel((prev) =>
        isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
      );
    };

  const handleSecondLevelAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedSecondLevel((prev) =>
        isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
      );
    };

  // Function to expand all accordions
  const expandAllAccordions = () => {
    const allFirstLevelPanels = restructuredData.map(
      (item, index) => `panel${index}`
    );
    const allSecondLevelPanels = restructuredData.flatMap((item, index) =>
      item.dataByDate.map(
        (chartItem, chartIndex) => `subpanel${index}-${chartIndex}`
      )
    );
    setExpandedFirstLevel(allFirstLevelPanels);
    setExpandedSecondLevel(allSecondLevelPanels);
  };

  return (
    <Box>
      <ReportsTopbar
        dropdownTitle={t("reports.graphicalWorkingHours")}
        disabled={reportsLoading ? true : false}
      />
      <Box mx={4}>
        <ReportsFilterBar
          setFilterOpen={setFilterOpen}
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
          handleDateChange={handleDateChange}
          dateValues={dateValues}
          handleDateRangeSubmit={handleDateRangeSubmit}
          dateRangeLoading={reportsLoading}
          exportHeaders={WORKING_HOURS_HEADERS}
          // generateCSVData={generateWorkingHoursCSV}
          formattedData={formattedData}
          generatePDF={() => {
            setPdfLoading(true);
            expandAllAccordions();
            generateGraphicalRidesPDF();
          }}
          hideExportButton={false}
          disableExportButton={restructuredData?.length > 0 ? false : true}
          exportButtonLoading={pdfLoading}
          reportType={3}
          reportExportTypeValue={3}
        />
        <WorkingHoursFilterForm
          fetchOnDebounce={handleObjectsSearch}
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          onSubmit={workingHoursFiltersSubmitHandler}
          groups={accountGroups}
          objectTypes={objectTypes}
          objects={formObjects}
          keys={accountKeys}
          dataLoading={
            objectsLoading || objectTypesLoading || accountKeysLoading
          }
          theme={theme}
          //@ts-ignore
          onChange={handleOnChangeReportFilterForm}
        />
        {reportsLoading && (
          <CenterTextBox>
            <CircularProgress />
          </CenterTextBox>
        )}
        {!reportsLoading && restructuredData?.length ? (
          <ChartsAccordion
            expandedFirstLevel={expandedFirstLevel}
            expandedSecondLevel={expandedSecondLevel}
            chartID="g-wh-report"
            data={restructuredData}
            handleFirstLevelAccordionChange={handleFirstLevelAccordionChange}
            handleSecondLevelAccordionChange={handleSecondLevelAccordionChange}
            parentRef={parentRef}
            parentWidth={parentWidth}
            renderGraphComponent={(chartItem) => (
              <GraphicalWorkingHoursReport
                width={parentWidth}
                height={240}
                data={chartItem as any}
                barSize={10}
                xAxisLabel={t("reports.hours")}
                yAxisLabel={t("reports.minutes")}
                dataBars={[
                  {
                    barKey: "ignitionHours",
                    barColor: "rgba(218, 133, 204, 1)",
                  },
                  {
                    barKey: "workingHours",
                    barColor: "rgba(95, 159, 255, 1)",
                  },
                  {
                    barKey: "ptoHours",
                    barColor: "rgba(132, 90, 252, 1)",
                  },
                ]}
              />
            )}
          />
        ) : (
          <CenterTextBox>There are no records to display</CenterTextBox>
        )}
      </Box>
    </Box>
  );
};

export default GraphicalWorkingHours;
