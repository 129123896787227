import { createAsyncThunk } from "@reduxjs/toolkit";
import { UsersService } from "app/data/services";
import { toast, types } from "@vilocnv/allsetra-core";

export const getAllUsersThunk = createAsyncThunk(
  "users/getAllUsersThunk",
  async (accountId: string) => {
    try {
      const response = await UsersService.getAllUsers(accountId);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getUsersByQueryThunk = createAsyncThunk(
  "users/getUsersByQueryThunk",
  async ({
    params,
    accountId,
  }: {
    params: types.IRecordsAggregationBody;
    accountId: string;
  }) => {
    try {
      const response = await UsersService.getUsersByQuery(params, accountId);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getSpecificUserThunk = createAsyncThunk(
  "users/getSpecificUserThunk",
  async ({ accountId, userId }: { accountId: string; userId: string }) => {
    try {
      const response = await UsersService.getSpecificUser(accountId, userId);

      if (response.status === 200) {
        return response.data;
      }
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const createOrUpdateUserThunk = createAsyncThunk(
  "users/createOrUpdateUserThunk",
  async ({ accountId, data }: { accountId: string; data: any }) => {
    try {
      const response = data.uniqueId
        ? await UsersService.updateUser(accountId, data)
        : await UsersService.createUser(accountId, data);

      return response;
    } catch (e: any) {
      console.error(e);

      if (e.response.status === 409) {
        toast.error("User with this email already exists");
      } else {
        toast.error(e.message);
      }

      throw new Error(e);
    }
  }
);

export const deactivateUserThunk = createAsyncThunk(
  "users/deactivateUserThunk",
  async ({ userId, accountId }: { userId: string; accountId: string }) => {
    try {
      const response = await UsersService.deactivateUser(userId, accountId);

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);

export const activateUserThunk = createAsyncThunk(
  "users/activateUserThunk",
  async (userId: string) => {
    try {
      const response = await UsersService.activateUser(userId);

      return response;
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
      throw new Error(e);
    }
  }
);
export const getUsersWithRoleDriverThunk = createAsyncThunk(
  "users/getUsersWithRoleDriverThunk",
  async (accountId: string) => {
    try {
      const response = await UsersService.getUsersWithRoleDriver(accountId);

      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);

export const getAllUserRolesThunk = createAsyncThunk(
  "users/getAllUserRolesThunk",
  async () => {
    try {
      const response = await UsersService.getAllUserRoles();
      if (response.status === 200) {
        return response.data;
      }

      return response;
    } catch (e: any) {
      console.error(e);
      throw new Error(e);
    }
  }
);
