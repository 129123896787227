import { Badge, TableColumn } from "@vilocnv/allsetra-core";
import { formatTimeWithDay } from "app/data/helpers";
import moment from "moment";

/**
 * MILAGE REGISTRATION
 */

const RIDE_MODE_IDENTIFICATION = [
  { label: "None", key: 0 },
  { label: "Business ride", key: 1 },
  { label: "Private ride", key: 2 },
  { label: "Commuting ride", key: 3 },
];

const getColorForRideMode = (rideMode: number) => {
  const mode = RIDE_MODE_IDENTIFICATION.find((item) => item.key === rideMode);
  switch (mode?.key) {
    case 1:
      return "purple"; // Business ride
    case 2:
      return "info"; // Private ride
    case 3:
      return "success"; // Commuting ride
    case 0:
      return "grey";
    default:
      return "grey"; // Default color for unknown ride mode
  }
};

export const MILAGE_REGISTRATION_DATE_RENDERING: TableColumn<any>[] = [
  { selector: (row: any) => row.label },
];

export const MILAGE_REGISTRATION_TABLE_COLUMNS = (
  translator: any
): TableColumn<any>[] => [
  {
    name: translator("tableHeading.ride", { ns: "tableHeadingsTranslation" }),
    selector: (row: any) => row?.name || "N/A",
    wrap: true,
  },
  {
    name: translator("tableHeading.startTime", {
      ns: "tableHeadingsTranslation",
    }),
  },
  {
    name: translator("tableHeading.endTime", {
      ns: "tableHeadingsTranslation",
    }),
  },
  {
    name: translator("tableHeading.startMileage", {
      ns: "tableHeadingsTranslation",
    }),
  },
  {
    name: translator("tableHeading.endMileage", {
      ns: "tableHeadingsTranslation",
    }),
  },
  {
    name: translator("tableHeading.startAddress", {
      ns: "tableHeadingsTranslation",
    }),
  },
  {
    name: translator("tableHeading.endAddress", {
      ns: "tableHeadingsTranslation",
    }),
  },
  {
    name: translator("tableHeading.key", { ns: "tableHeadingsTranslation" }),
  },
  {
    name: translator("tableHeading.rideType", {
      ns: "tableHeadingsTranslation",
    }),
  },
  {
    name: translator("tableHeading.distance", {
      ns: "tableHeadingsTranslation",
    }),
  },
  {
    name: translator("tableHeading.rideDuration", {
      ns: "tableHeadingsTranslation",
    }),
  },
  {
    name: translator("tableHeading.comments", {
      ns: "tableHeadingsTranslation",
    }),
  },
];

export const MILAGE_REG_INNER_TABLE_COLUMNS: TableColumn<any>[] = [
  { selector: (row: any) => row?.rideId || "-" },
  {
    selector: (row: any) =>
      moment(row?.startDate).format("DD/MM/YY HH:mm:ss") || "-",
  },
  {
    selector: (row: any) =>
      row?.endDate !== null
        ? moment(row?.endDate).format("DD/MM/YY HH:mm:ss")
        : "-" || "-",
  },
  {
    cell: (row: any) => (row?.startMileage ? `${row?.startMileage} km` : "N/A"),
  },
  {
    cell: (row: any) => (row?.endMileage ? `${row?.endMileage} km` : "N/A"),
  },
  {
    cell: (row: any) => row?.startAddress || "N/A",
  },
  {
    cell: (row: any) => row?.endAddress || "N/A",
  },
  {
    cell: (row: any) => row?.key?.label || "-",
  },
  {
    cell: (row: any) => {
      const mode = RIDE_MODE_IDENTIFICATION.find(
        (item) => item.key === row?.rideMode
      );

      const color: any = getColorForRideMode(row.rideMode);

      return <Badge colorScheme={color ?? "error"}>{mode?.label || "-"}</Badge>;
    },
  },
  {
    cell: (row: any) => Math.round(row?.kilometersDriven) + " km" || "-",
  },
  {
    cell: (row: any) => {
      const endTime = formatTimeWithDay(row.endDate);
      const startTime = formatTimeWithDay(row.startDate);

      return endTime !== "Invalid DateTime"
        ? calculateMilageTimeDiff(endTime, startTime)
        : "Ongoing";
    },
  },
  {
    cell: (row: any) => row?.comments || "-",
  },
];

export const MILAGE_REG_HEADERS = [
  { label: "Object Name", key: "Object Name" },
  { label: "Ride", key: "Ride" },
  { label: "Start Mileage", key: "Start Mileage" },
  { label: "End Mileage", key: "End Mileage" },
  { label: "Start Time", key: "Start Time" },
  { label: "End Time", key: "End Time" },
  { label: "Start Address", key: "Start Address" },
  { label: "End Address", key: "End Address" },
  { label: "Key", key: "Key" },
  { label: "Ride Mode", key: "Ride Mode" },
  { label: "Distance", key: "Distance" },
  { label: "Ride Duration", key: "Ride Duration" },
  { label: "Comments", key: "Comments" },
];

export const calculateMilageTimeDiff = (endTime: any, startTime: any) => {
  const endTimeObj = new Date(endTime);
  const startTimeObj = new Date(startTime);

  const timeDifference = endTimeObj.getTime() - startTimeObj.getTime();

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  const padWithZero = (value: number) => (value < 10 ? `0${value}` : value);

  // Format the output with leading zeros
  const formattedTime = `${padWithZero(hours)}:${padWithZero(
    minutes
  )}:${padWithZero(seconds)}`;

  return formattedTime;
};
