import {
  MilageRegistrationData,
  MilageRegistrationRecord,
} from "app/data/types";
import { GroupBy, formatTimeWithDay, getDateKey } from "../reportCommonHelpers";
import { DateTime } from "luxon";
import { calculateMilageTimeDiff } from "app/data/constants";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as Yup from "yup";
import moment from "moment";
import { sortBy } from "lodash";

interface Group {
  uniqueId: string;
  objects: any[];
}

interface Values {
  group: string[];
  object: string[];
}

//Milage Registrations
export const reportsMilageRegistrationFormInitialValues = {
  objectType: [],
  group: [],
  object: [],
  key: [],
  daysOfWeek: ["0", "1", "2", "3", "4", "5", "6"],
  startTime: "00:00",
  endTime: "23:59",
};

export const reportsMilageRegistrationFormValidationSchema: Yup.Schema =
  Yup.object({
    objectType: Yup.array().of(Yup.string()).label("Object Types"),
    group: Yup.array().of(Yup.string()).label("Groups"),
    object: Yup.array().of(Yup.string()).label("Objects"),
    key: Yup.array().of(Yup.string()).label("Drivers"),
  });

type FormattedRecord = Omit<MilageRegistrationRecord, "key"> & {
  label: string;
};

type FormattedData = {
  name: string;
  records: {
    label: string;
    data: FormattedRecord[];
  }[];
}[];

export const milageRegistrationDataFormatter = (
  originalData: MilageRegistrationData,
  groupBy: GroupBy
): FormattedData[] => {
  const formattedDataMap: Map<string, FormattedData> = new Map();

  originalData.forEach((item) => {
    let formattedData = formattedDataMap.get(item.name);

    if (!formattedData) {
      formattedData = {
        //@ts-ignore
        name: item.name,
        records: [],
      };
      //@ts-ignore
      formattedDataMap.set(item.name, formattedData);
    }

    item.items.forEach((record) => {
      const dateKey = getDateKey(record.startDate, groupBy);
      //@ts-ignore
      let dateObject = formattedData.records.find(
        (record: any) => record.label === dateKey
      );

      if (!dateObject) {
        dateObject = {
          label: dateKey,
          data: [],
        };
        //@ts-ignore
        formattedData.records.push(dateObject);
      }

      dateObject.data.push({
        ...record,
      });
    });
  });

  return sortBy(Array.from(formattedDataMap.values()), ["name"]);
};

export const calculateTimePeriodDates = (report: any, index: number) => {
  const innerItems = report[index].items;
  const periodStartDate = DateTime.fromISO(report[index].items[0].startDate);
  const periodEndDate = DateTime.fromISO(
    report[index].items[innerItems.length - 1].endDate
  );

  const formattedStartDate = periodStartDate.toFormat("dd/MM/yyyy");
  const formattedEndDate = periodEndDate.toFormat("dd/MM/yyyy");

  return { formattedStartDate, formattedEndDate };
};

export const calculateTotalKilometersByMode = (
  rides: any,
  rideMode: number
) => {
  return rides
    .filter((ride: any) => ride.rideMode === rideMode)
    .reduce((totalKilometers: number, ride: any) => {
      return totalKilometers + ride.kilometersDriven;
    }, 0);
};

const extractRows = (items: any) => {
  return items.map((entry: any) => {
    const endTime = formatTimeWithDay(entry.endDate);
    const startTime = formatTimeWithDay(entry.startDate);

    return [
      entry?.rideId || "-",
      moment(entry?.startDate).format("DD/MM/YY HH:mm:ss") || "-",
      moment(entry?.endDate).format("DD/MM/YY HH:mm:ss") || "-",
      Math.round(entry?.startMileage) + " km" || "-",
      Math.round(entry?.endMileage) + " km" || "-",
      entry?.startAddress || "-",
      entry?.endAddress || "-",
      entry?.key?.label || "-",
      renderRideMode(entry?.rideMode) || "-",
      Math.round(entry?.kilometersDriven) + " km" || "-",
      endTime !== "Invalid DateTime"
        ? calculateMilageTimeDiff(endTime, startTime)
        : "Ongoing" || "-",
      entry.comments || "-",
    ];
  });
};

export const renderRideMode = (rideId: number) => {
  switch (rideId) {
    case 1:
      return "Business Ride";
    case 2:
      return "Private Ride";
    case 3:
      return "Commuting Ride";
    default:
      return "None";
  }
};

export const generateFormattedMilageRefPdf = (
  doc: jsPDF,
  report: any,
  columns: string[]
) => {
  report.forEach((object: any, index: number) => {
    const { formattedStartDate, formattedEndDate } = calculateTimePeriodDates(
      report,
      index
    );
    const itemsLength = object.items;

    const totalKms = object.items.reduce(
      (totalKilometers: number, ride: any) => {
        return totalKilometers + ride.kilometersDriven;
      },
      0
    );

    const totalKilometersMode1 = calculateTotalKilometersByMode(
      object.items,
      1
    );
    const totalKilometersMode2 = calculateTotalKilometersByMode(
      object.items,
      2
    );
    const totalKilometersMode3 = calculateTotalKilometersByMode(
      object.items,
      3
    );

    if (index > 0) {
      doc.addPage();
    }

    doc.setFontSize(10);
    doc.text(`Object Name: ${object.name}`, 10, 20);
    doc.text(`Period: ${formattedStartDate} - ${formattedEndDate}`, 10, 25);
    doc.text(`A-Number: ${object.aNumber}`, 10, 30);
    doc.text(`Number of Trips: ${itemsLength.length}`, 10, 35);
    doc.text(`Total: ${Math.round(totalKms)} km`, 10, 45);
    doc.text(`Business: ${Math.round(totalKilometersMode1)} km`, 10, 50);
    doc.text(`Private: ${Math.round(totalKilometersMode2)} km`, 10, 55);
    doc.text(`Commute: ${Math.round(totalKilometersMode3)} km`, 10, 60);

    autoTable(doc, {
      head: [columns],
      body: extractRows(object?.items),
      startY: 70,
    });
  });
};

export const filterObjectsByGroup = (
  values: Values,
  objects: any[],
  groups: Group[]
): any[] => {
  if (values?.group?.length === 0) return objects;

  const filteredGroups = groups.filter((group) =>
    values.group.includes(group.uniqueId)
  );

  const filteredObjects = filteredGroups.map((group) => group.objects);
  const flattenedObjects = filteredObjects.flat();

  return flattenedObjects;
};
