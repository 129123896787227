import { FC, useEffect, useState, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import { FormikHelpers } from "formik";
import { Table } from "@vilocnv/allsetra-core";
import jsPDF from "jspdf";
import MilageRegistrationForm from "components/forms/Reports/MilageRegistrationFilterForm";
import MilageRegExpendableRowCard from "components/cards/ReportCards/MilageRegExpendableRowCard/MilageRegExpendableRowCard";
import ReportsTopbar from "components/common/reports/ReportsTopbar/ReportsTopbar";
import AllsetraPDFImage from "../../../assets/images/AllsetraPDFImage.jpg";

// Data
import moment from "moment";
import { useAppDispatch, useAppSelector, useReportCommonStates } from "hooks";
import {
  generateFormattedMilageRefPdf,
  milageRegistrationDataFormatter,
  renderRideMode,
} from "app/data/helpers/ReportHelpers/milageRegistrationHelpers";
import {
  MILAGE_REGISTRATION_TABLE_COLUMNS,
  MILAGE_REG_HEADERS,
  calculateMilageTimeDiff,
} from "app/data/constants";
import {
  convertToNormalTime,
  formatTimeWithDay,
  reportTransformTimeForAPI,
} from "app/data/helpers";
import { generateReportThunk } from "app/features/reports/reportsActions";
import { getAllKeysByAccountThunk, resetReports } from "app/features";
import { useTranslation } from "react-i18next";
import ReportsFilterBar from "components/common/reports/ReportFiltersBar/ReportsFilterBar";
import {
  selectAccountKeysState,
  selectWhiteLabelsState,
} from "app/data/selectors";
import { isEmpty } from "lodash";

const DEFAULT_FILTER_VALUES = {
  driver: [],
  group: [],
  object: [],
  objectType: [],
};

const MilageRegistration: FC = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {
    drawerSelectedAccountId,
    objectTypes,
    objectTypesLoading,
    objectsLoading,
    accountGroups,
    report,
    reportsLoading,
    filterOpen,
    setFilterOpen,
    dateFormat,
    setDateFormat,
    dateValues,
    setDateValues,
    localReport,
    setLocalReport,
    handleObjectsSearch,
    selectedLocalObjects,
    handleOnChangeReportFilterForm,
  } = useReportCommonStates();

  const [filterValues, setFilterValues] = useState<any>(DEFAULT_FILTER_VALUES);

  const { t } = useTranslation(["translation", "tableHeadingsTranslation"]);

  const tableColumns = useMemo(() => MILAGE_REGISTRATION_TABLE_COLUMNS(t), [t]);

  const { accountKeys, loading: accountKeysLoading } = useAppSelector(
    selectAccountKeysState
  );

  const { whiteLabel } = useAppSelector(selectWhiteLabelsState);

  useEffect(() => {
    dispatch(resetReports());
  }, []);

  useEffect(() => {
    setLocalReport(report);
  }, [report]);

  useEffect(() => {
    if (drawerSelectedAccountId !== null) {
      dispatch(getAllKeysByAccountThunk(drawerSelectedAccountId));
    }
  }, [drawerSelectedAccountId]);

  const formattedData = milageRegistrationDataFormatter(
    localReport,
    dateFormat
  );

  const milageRegistrationFiltersSubmitHandler = async (
    values: any,
    formikHelpers: FormikHelpers<any>
  ) => {
    formikHelpers.setSubmitting(true);

    const startDate = new Date(dateValues.startDate);
    const endDate = new Date(dateValues.endDate);

    const sameDate = startDate.toDateString() === endDate.toDateString();

    if (sameDate) {
      startDate.setHours(0, 0, 0);
      endDate.setHours(23, 59, 59);
    }

    const formattedStartDate = convertToNormalTime(startDate);
    const formattedEndDate = convertToNormalTime(endDate, true);
    setFilterValues(values);

    const payload = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      ...values,
      reportType: 2,
      daysOfWeek: values.daysOfWeek.map(Number),
      startTime: reportTransformTimeForAPI(values.startTime + ":00", startDate),
      endTime: reportTransformTimeForAPI(values.endTime + ":00", endDate),
    };

    await dispatch(generateReportThunk(payload));

    formikHelpers.setSubmitting(false);
    setFilterOpen(false);
  };

  const milageRegCSVData = () =>
    formattedData.flatMap((object: any) =>
      object.records.flatMap((record: any) => {
        return record.data.map((entry: any) => {
          const endTime = formatTimeWithDay(entry.endDate);
          const startTime = formatTimeWithDay(entry.startDate);
          return {
            "Object Name": object?.name,
            Ride: entry?.rideId || "-",
            "Start Time":
              moment(entry?.startDate).format("DD/MM/YY HH:mm:ss") || "-",
            "End Time":
              moment(entry?.endDate).format("DD/MM/YY HH:mm:ss") || "-",
            "Start Mileage": Math.round(entry?.startMileage) + " km" || "-",
            "End Mileage": Math.round(entry?.endMileage) + " km" || "-",
            "Start Address": entry?.startAddress || "-",
            "End Address": entry?.endAddress || "-",
            Key: entry?.key?.label || "-",
            "Ride Mode": renderRideMode(entry?.rideMode) || "-",
            Distance: Math.round(entry?.kilometersDriven) + " km" || "-",
            "Ride Duration":
              endTime !== "Invalid DateTime"
                ? calculateMilageTimeDiff(endTime, startTime)
                : "Ongoing" || "-",
            Comments: entry?.comments || "-",
          };
        });
      })
    );

  const generateMilageRegPDF = () => {
    const columns = [
      "Ride",
      "Start Time",
      "End Time",
      "Start Mileage",
      "End Mileage",
      "Start Address",
      "End Address",
      "Key",
      "Ride Mode",
      "Distance",
      "Ride Duration",
      "Comments",
    ];

    const doc = new jsPDF({
      orientation: "landscape",
    });
    doc.text("Milage Registration Report", 100, 10);

    if (isEmpty(whiteLabel)) {
      doc.addImage(
        AllsetraPDFImage,
        "JPEG",
        doc.internal.pageSize.width - 50,
        10,
        40,
        30
      );
    }

    generateFormattedMilageRefPdf(doc, report, columns);

    doc.save("Milage Registration Report.pdf");
  };

  const handleDateChange = (value: any) => {
    setDateValues(value);
  };

  const handleDateRangeSubmit = () => {
    const startDate = convertToNormalTime(dateValues.startDate);
    const endDate = convertToNormalTime(dateValues.endDate, true);

    let updatedFilterValues = { ...filterValues };
    if (filterValues.daysOfWeek) {
      updatedFilterValues = {
        ...filterValues,
        daysOfWeek: filterValues.daysOfWeek.map(Number),
        startTime: reportTransformTimeForAPI(
          filterValues.startTime + ":00",
          dateValues.startDate
        ),
        endTime: reportTransformTimeForAPI(
          filterValues.endTime + ":00",
          dateValues.endDate
        ),
      };
    }

    const payload = {
      startDate,
      endDate,
      ...updatedFilterValues,
      reportType: 2,
    };

    dispatch(generateReportThunk(payload));
  };

  let formObjects = [...selectedLocalObjects];

  return (
    <Box>
      <ReportsTopbar
        dropdownTitle={t("reports.milageRegistration")}
        disabled={reportsLoading ? true : false}
      />
      <Box mx={4}>
        <ReportsFilterBar
          setFilterOpen={setFilterOpen}
          dateFormat={dateFormat}
          setDateFormat={setDateFormat}
          handleDateChange={handleDateChange}
          dateValues={dateValues}
          handleDateRangeSubmit={handleDateRangeSubmit}
          dateRangeLoading={reportsLoading}
          exportHeaders={MILAGE_REG_HEADERS}
          generateCSVData={milageRegCSVData}
          formattedData={formattedData}
          generatePDF={generateMilageRegPDF}
          disableExportButton={formattedData.length > 0 ? false : true}
          reportType={2}
          reportExportTypeValue={2}
        />
        <MilageRegistrationForm
          fetchOnDebounce={handleObjectsSearch}
          open={filterOpen}
          onClose={() => setFilterOpen(false)}
          onSubmit={milageRegistrationFiltersSubmitHandler}
          groups={accountGroups}
          objectTypes={objectTypes}
          objects={formObjects}
          keys={accountKeys}
          dataLoading={
            objectsLoading || objectTypesLoading || accountKeysLoading
          }
          theme={theme}
          //@ts-ignore
          onChange={handleOnChangeReportFilterForm}
        />
        <Table
          columns={tableColumns}
          data={formattedData}
          progressPending={reportsLoading}
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={MilageRegExpendableRowCard}
          pagination={false}
          nestedRowPadding={"16px"}
        />
      </Box>
    </Box>
  );
};

export default MilageRegistration;
