import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";

export const selectExportedReportsHistoryLoading = (state: RootState) =>
  state.rootReducer.exportedReportsHistoryReducer.loading;

export const selectAllExportedReportsHistory = (state: RootState) =>
  state.rootReducer.exportedReportsHistoryReducer.allExportedReportsHistory;

export const selectAllExportedReportsHistoryTotalRecords = (state: RootState) =>
  state.rootReducer.exportedReportsHistoryReducer.totalRecords;

export const selectExportedReportsHistoryState = createSelector(
  selectExportedReportsHistoryLoading,
  selectAllExportedReportsHistory,
  selectAllExportedReportsHistoryTotalRecords,
  (loading, allExportedReportsHistory, totalRecords) => {
    return {
      loading,
      allExportedReportsHistory,
      totalRecords,
    };
  }
);
