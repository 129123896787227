import { FC, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { FormikHelpers } from "formik";
import {
  NestedTable,
  Topbar,
  AddAccountForm,
  DeleteConfirmationModal,
  types,
  toast,
} from "@vilocnv/allsetra-core";

// Data
import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectAccountsState,
  selectDrawerSelectedAccountId,
} from "app/data/selectors";
import {
  activateAccountThunk,
  deactivateAccountThunk,
  createAccountThunk,
  getAllAccountsThunk,
} from "app/features";
import { ACCOUNTS_TABLE_COLUMNS } from "app/data/constants";
import { signalRGenerateSuccessToastMessage } from "app/data/helpers";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { SignalRService } from "app/data/services";

const Accounts: FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  // Global State
  const { selectedAccounts, loading } = useAppSelector(selectAccountsState);
  const drawerSelectedAccountId = useAppSelector(selectDrawerSelectedAccountId);

  // Local State
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
    null
  );
  const [open, setOpen] = useState(false); // Boolean state for AddaccountForm Modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false); // Boolean state for DeleteConfirmationModal Modal
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation([
    "translation",
    "tableHeadingsTranslation",
    "formFieldsTranslation",
  ]);

  const tableColumns = useMemo(() => ACCOUNTS_TABLE_COLUMNS(t), [t]);

  useEffect(() => {
    dispatch(getAllAccountsThunk());
  }, [drawerSelectedAccountId]);

  const handleActivateAccount = async (account: any) => {
    account && dispatch(activateAccountThunk(account.accountId));
  };

  const openDeleteConfirmationModal = (account: any) => {
    setSelectedAccountId(account.accountId);
    setOpenDeleteModal(true);
  };

  const handleDeactivateAccount = async () => {
    if (isEmpty(selectedAccountId)) {
      toast.error("Kindly select an account firstly.");
    }

    setIsSubmitting(true);

    const { type } = await dispatch(
      deactivateAccountThunk(selectedAccountId ?? "")
    );

    if (type === "accounts/deactivateAccountThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (
          event.eventName === types.BackendEventsEnum.AccountDeactivatedEvent
        ) {
          setIsSubmitting(false);
          setOpenDeleteModal(false);
          toast.success("Account has been deactivated successfully.");
          dispatch(getAllAccountsThunk());
        }
      });
    } else {
      setIsSubmitting(false);
    }
  };

  const handleAccountSettings = (account: any) => {
    navigate(`/dashboard/accounts/settings/${account.accountId}`);
  };

  const addAccountHandler = async (
    values: types.IAddAccount,
    formikHelpers: FormikHelpers<types.IAddAccount>
  ) => {
    formikHelpers.setSubmitting(true);
    setIsSubmitting(true);

    const { type } = await dispatch(
      createAccountThunk({ accountId: drawerSelectedAccountId, data: values })
    );

    if (type === "accounts/createAccountThunk/fulfilled") {
      SignalRService.hubConnection?.on("EventRaised", (event: any) => {
        if (event.eventName === types.BackendEventsEnum.AccountCreatedEvent) {
          setOpen(false);
          formikHelpers.setSubmitting(false);
          setIsSubmitting(false);
          toast.success(
            signalRGenerateSuccessToastMessage(event.name, "Account", "created")
          );
          dispatch(getAllAccountsThunk());
        }
      });
    } else {
      formikHelpers.setSubmitting(false);
      setIsSubmitting(false);
    }
  };

  return (
    <Box>
      <Topbar
        theme={theme}
        title={t("drawerMenuLinks.accounts")}
        primaryButton={{
          id: "account-add",
          variant: "outlined",
          text: t("titles.addAccount"),
          startIcon: <AddIcon />,
          onClick: () => setOpen(true),
        }}
      />
      <Box mx={4}>
        <NestedTable
          columns={tableColumns}
          data={selectedAccounts}
          progressPending={loading}
          searchPlaceholder={t("common.search")}
          cellActions={[
            {
              name: t("typography.accountSettings"),
              onClick: handleAccountSettings,
            },
            {
              name: t("typography.activateAccount"),
              when: (row: any) => row.isAccountDeactivated === true,
              onClick: handleActivateAccount,
            },
            {
              name: t("typography.deactivateAccount"),
              when: (row: any) => row.isAccountDeactivated === false,
              onClick: openDeleteConfirmationModal,
            },
          ]}
        />
      </Box>
      <AddAccountForm
        open={open}
        onClose={() => setOpen(false)}
        theme={theme}
        onSubmit={addAccountHandler}
        submitting={isSubmitting}
        translator={t}
      />
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title="You are about to deactivate account"
        subTitle="Do you really want to deactivate this account? This process cannot be undone."
        theme={theme}
        primaryBtnProps={{
          onClick: handleDeactivateAccount,
          loading: isSubmitting,
        }}
      />
    </Box>
  );
};

export default Accounts;
