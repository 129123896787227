import { Box, styled } from "@mui/material";

export const TimeFieldsWrapper = styled(Box)({
  display: "Grid",
  gap: "12px",
  gridTemplateColumns: "repeat(2,230px)",
  justifyContent: "space-between",
});

export const TimeField = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "20px",
});
