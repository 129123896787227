import { TableColumn, StatusBadge } from "@vilocnv/allsetra-core";

//
// TABLE HEADERS
//
export const ACCOUNTS_TABLE_COLUMNS = (translator: any): TableColumn<any>[] => [
  {
    name: translator("tableHeading.name", {
      ns: "tableHeadingsTranslation",
    }),
    selector: (row: any) => row.accountName,
    sortable: true,
  },
  {
    name: translator("tableHeading.usersCount", {
      ns: "tableHeadingsTranslation",
    }),
    selector: (row: any) => row.usersCount,
    sortable: true,
  },
  {
    name: translator("tableHeading.linkedObjects", {
      ns: "tableHeadingsTranslation",
    }),
    selector: (row: any) =>
      row.accountLinkedObjects.reduce((accumulator: any, currentValue: any) => {
        return accumulator + (currentValue.total || 0);
      }, 0),
    sortable: true,
  },
  {
    name: translator("tableHeading.status", {
      ns: "tableHeadingsTranslation",
    }),
    cell: (row: any) => (
      <StatusBadge isDeactivated={row.isAccountDeactivated} />
    ),
    sortable: true,
  },
];
