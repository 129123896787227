import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import {
  ConnectionBadge,
  ChargeBadge,
  Badge,
  types,
  helpers,
} from "@vilocnv/allsetra-core";
import { SmallText } from "../ObjectDetailsCard/ObjectDetailsCard.styled";
import {
  AssignedUsersCardContainer,
  CardText,
} from "../ObjectAssignedUsersCard/ObjectAssignedUsersCard.styled";
import { CalendarIcon } from "./ObjectDevicesCard.styled";
import { DeviceIcon } from "assets/icons";
import { objectDevicesCardDateFormmater } from "app/data/helpers";

export interface ObjectDevicesCardProps {
  device: types.IDevice;
}

const ObjectDevicesCard: FC<ObjectDevicesCardProps> = ({ device }) => {
  return (
    <AssignedUsersCardContainer>
      <Stack spacing={2}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            {device?.object?.objectType?.icon?.length ? (
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}/icons/${device.object.objectType.icon.uniqueId}/file?X-Subscription=${process.env.REACT_APP_API_HEADER_SUBSCRIPTION}`}
                alt="Icon"
                width="16px"
                height="16px"
              />
            ) : (
              <DeviceIcon />
            )}
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#1D1F2B",
              }}
            >
              {device.serialNumber ?? "N/A"}
            </Typography>
          </Stack>

          <Badge
            colorScheme={helpers.getDeviceBadgeColorOnStatus(
              device?.status || "N/A"
            )}
          >
            {device?.status || "N/A"}
          </Badge>
        </Stack>

        <CardText color={"#212B33"} fontWeight={"400"} fontSize={"12px"}>
          {device.location?.resolvedAddress ?? "Address not available"}
        </CardText>

        <Stack direction={"row"} alignItems={"center"} spacing={2}>
          <ConnectionBadge
            status={device.isOnLine ? "online" : "offline"}
          ></ConnectionBadge>
          {device.lastBatteryValue !== null && (
            <ChargeBadge
              //@ts-ignore
              percentage={device.lastBatteryValue ?? 0}
            ></ChargeBadge>
          )}
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <CalendarIcon />
            <SmallText>
              Installed on{" "}
              {objectDevicesCardDateFormmater(device.installationDate)}
            </SmallText>
          </Stack>
        </Stack>
      </Stack>
    </AssignedUsersCardContainer>
  );
};

export default ObjectDevicesCard;
