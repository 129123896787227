import { Table } from "@vilocnv/allsetra-core";
import { TAG_POSITION_INNER_TABLE_COLUMNS } from "app/data/constants";
import { FC } from "react";

interface TagPositionExpendableRowCardProps {
  data: any;
}

const TagPositionExpendableRowCard: FC<TagPositionExpendableRowCardProps> = ({
  data,
}) => {
  return (
    <Table
      data={data.records}
      columns={TAG_POSITION_INNER_TABLE_COLUMNS}
      noTableHead={true}
      pagination={false}
    />
  );
};

export default TagPositionExpendableRowCard;
