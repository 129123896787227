import { FC } from "react";
import { useTheme } from "@mui/material";

const ActiveUserIcon: FC = (props) => {
  const theme = useTheme();

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.18833 10.901L5.93583 12.0393C5.87167 12.3243 5.66333 12.556 5.38583 12.6493L4.12333 13.0693C3.62583 13.2352 3.23583 13.626 3.06917 14.1235L2.5 15.8327H12.5L11.93 14.1235C11.7642 13.626 11.3733 13.2352 10.8758 13.0693L9.61417 12.6493C9.33667 12.556 9.1275 12.3243 9.06417 12.0393L8.81083 10.8985M12.2218 14.9998H18.3327L17.7185 13.1548C17.5785 12.7356 17.2777 12.3889 16.8818 12.1906L16.2235 11.8614C15.9793 11.7389 15.8102 11.5048 15.771 11.2339L15.6827 10.6139M13.4832 10.6162L13.3949 11.2345C13.3566 11.5045 13.1874 11.7395 12.9424 11.862L12.2841 12.1912C11.8883 12.3887 11.5883 12.7353 11.4483 13.1545L11.3841 13.3453M8.24967 11.4125C7.80633 11.75 7.193 11.75 6.74967 11.4125C5.64717 10.5725 4.99967 9.265 4.99967 7.87917V7.5C4.99967 6.11917 6.11883 5 7.49967 5C8.8805 5 9.99967 6.11917 9.99967 7.5V7.87917C9.99967 9.265 9.35217 10.5725 8.24967 11.4125ZM15.2081 11.0434C14.8373 11.3192 14.3289 11.3192 13.9581 11.0434C13.0406 10.3617 12.4998 9.28675 12.4998 8.14342V7.91675C12.4998 6.76592 13.4323 5.83342 14.5831 5.83342C15.7339 5.83342 16.6664 6.76592 16.6664 7.91675V8.14342C16.6664 9.28675 16.1256 10.3617 15.2081 11.0434Z"
        stroke={theme.palette.primary.main}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ActiveUserIcon;
