import { RootState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { transformGeozoneDataForForm } from "../helpers";

export const selectGeozonesReducerLoading = (state: RootState) =>
  state.rootReducer.geozonesReducer.loading;

export const selectAllGeozones = (state: RootState) =>
  state.rootReducer.geozonesReducer.allGeozones;

export const selectActiveGeozoneId = (state: RootState) =>
  state.rootReducer.geozonesReducer.activeGeozoneId;

export const selectActiveGeozone = (state: RootState) =>
  state.rootReducer.geozonesReducer.activeGeozone;

export const selectActiveGeozoneObjects = (state: RootState) =>
  state.rootReducer.geozonesReducer.activeGeozoneObjects;

export const selectGeozoneAssignedObjects = (state: RootState) =>
  state.rootReducer.geozonesReducer.geozoneAssignedObjects;

export const selectGeozoneAssignedObjectsLoading = (state: RootState) =>
  state.rootReducer.geozonesReducer.geozoneAssignedObjectsLoading;

export const selectAccountGeozoneObjects = (state: RootState) =>
  state.rootReducer.geozonesReducer.accountGeozoneObjects;

export const selectAccountGeozoneObjectsLoading = (state: RootState) =>
  state.rootReducer.geozonesReducer.accountGeozoneObjectsLoading;

export const selectAddressFromLatLng = (state: RootState) =>
  state.rootReducer.geozonesReducer.addressFromLatLng;

export const selectGeozonesState = createSelector(
  selectGeozonesReducerLoading,
  selectAllGeozones,
  selectActiveGeozoneId,
  selectActiveGeozone,
  selectActiveGeozoneObjects,
  selectGeozoneAssignedObjects,
  selectGeozoneAssignedObjectsLoading,
  selectAccountGeozoneObjectsLoading,
  selectAccountGeozoneObjects,
  selectAddressFromLatLng,
  (
    loading,
    allGeozones,
    activeGeozoneId,
    activeGeozone,
    activeGeozoneObjects,
    geozoneAssignedObjects,
    geozoneAssignedObjectsLoading,
    accountGeozoneObjectsLoading,
    accountGeozoneObjects,
    addressFromLatLng
  ) => ({
    loading,
    allGeozones,
    activeGeozoneId,
    activeGeozone,
    activeGeozoneObjects,
    geozoneAssignedObjects,
    geozoneAssignedObjectsLoading,
    accountGeozoneObjectsLoading,
    accountGeozoneObjects,
    addressFromLatLng,
  })
);

export const getActiveGeozoneInitialValuesForForm = createSelector(
  selectAllGeozones,
  selectActiveGeozoneId,
  selectGeozoneAssignedObjects,
  (allGeozones, activeGeozoneId, geozoneAssignedObjects) => {
    const geozone = allGeozones?.find(
      (geo) => geo.uniqueId === activeGeozoneId
    );
    return !isEmpty(geozone)
      ? transformGeozoneDataForForm(geozone, geozoneAssignedObjects)
      : null;
  }
);
