import * as Yup from "yup";
import { IAddPointOfInterest, IPointOfInterest } from "app/data/types";
import { isEmpty, pick } from "lodash";

export const poiInitialValues: IAddPointOfInterest = {
  name: "",
  address: "",
  lat: 0,
  long: 0,
};

export const poiValidationSchema: Yup.Schema = Yup.object().shape({
  uniqueId: Yup.string().optional(),
  name: Yup.string().trim().required().label("Name"),
  address: Yup.string()
    .trim()
    .required()
    .label("Address")
    .test(
      "address-check",
      "Address is a required field",
      (value) => !isEmpty(value)
    ),
  lat: Yup.mixed().nullable().label("Lat").optional(),
  long: Yup.mixed().nullable().label("Long").optional(),
});

export const transformPointOfInterestForForm = (data: IPointOfInterest) => {
  return pick(data, [...Object.keys(poiInitialValues), "uniqueId"]);
};
