import moment from "moment";
import momentTimezone from "moment-timezone";

export const reportTransformTimeForAPI = (time: string, dateValue: Date) => {
  // Get the current time in the local timezone
  const currentTime = momentTimezone();

  // Get the timezone offset in the format "+02:00"
  const timezoneOffset = currentTime.format("Z");

  // Get the current day
  const date = dateValue
    ? moment(dateValue).format("YYYY-MM-DD")
    : moment().format("YYYY-MM-DD");

  return `${date}T${time}${timezoneOffset}`;
};
